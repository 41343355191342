import { flattenObject } from 'components';
import enUs from './en-US';

export default function getIntlConfig() {
  let locale = navigator.language;
  const supportedLocales = ['en', 'en-US'];

  if (supportedLocales.indexOf(locale) === -1) {
    locale = 'en';
  }

  const library = {
    'en': enUs,
    'en-US': enUs,
  };

  const getMessages = locale => flattenObject(library[locale]);
  const messages = getMessages(locale);
  return { defaultLocale: locale, locale, messages };
};