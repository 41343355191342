import React, { useState } from 'react';
import clsx from 'clsx';

import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AsyncContent from '../AsyncContent';

const useStyles = makeStyles(({ spacing }) => {
  return {
    rowClick: {
      cursor: 'pointer',
    },
  };
});

export default function AsyncRecord({
  AsyncProps,
  data,
  mapDataToHeaders,
  modify,
  reverse,
  TableProps,
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TablePaginationProps,
  TableRowProps={},
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const matches = useMediaQuery('(max-width:415px)');

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  const Record = ({ data=[] }) => {
    const modifiedData = modify ? modify(data) : data;

    const headCells = Object.values(mapDataToHeaders).map((value, i) => {
      return (
        <TableCell key={i} {...TableCellProps}>{value}</TableCell>
      );
    });

    const BodyRow = ({ item, num }) => {
      const handleRowClick = (event) => {
        event.preventDefault();

        if (TableRowProps.onClick) {
          TableRowProps.onClick(event, data.items[num]);
        }
      };

      const cells = Object.entries(mapDataToHeaders).map((entry, j) => {
        return (
          <TableCell key={j} {...TableCellProps}>
            {item[entry[0]]}
          </TableCell>
        );
      });

      return (
        <TableRow
          className={clsx({ [classes.rowClick]: !!TableRowProps.onClick })}
          {...TableRowProps}
          onClick={handleRowClick}
        >
          {cells}
        </TableRow>
      );
    }

    const BodyRows = () => {
      if (!modifiedData || !modifiedData.length) {
        return (
          <TableRow>
            <TableCell colSpan={Object.keys(mapDataToHeaders).length}>
              No available data
            </TableCell>
          </TableRow>
        );
      }

      return modifiedData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, i) => <BodyRow item={item} key={i} num={i} />)
    };

    return (
      <TableContainer>
        <Table size='small' {...TableProps}>
          <TableHead {...TableHeadProps}>
            <TableRow {...TableRowProps}>{headCells}</TableRow>
          </TableHead>
          <TableBody {...TableBodyProps}>
            <BodyRows data={data} />
          </TableBody>
        </Table>
        {modifiedData.length > rowsPerPage ? (
          <TablePagination
            component='div'
            count={modifiedData.length}
            labelDisplayedRows={matches ? () => null : undefined}
            labelRowsPerPage='Per page'
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={matches ? [] : [10, 25, 50, 100]}
            {...TablePaginationProps}
          />
         ) : null}
      </TableContainer>
    );
  };

  return AsyncProps && Object.keys(AsyncProps).length > 0
    ? (
      <AsyncContent {...AsyncProps}>
        <Record />
      </AsyncContent>
    ) : <Record data={data} />;
};