import React from 'react';

import { makeStyles, useTheme } from '@material-ui/styles';
import { NotFound as NF, Page } from 'components';

const useStyles = makeStyles(() => {
  return {
    page: {
      height: '100vh',
    },
  };
});

export function NotFound() {
  const classes = useStyles();
  const { palette } = useTheme();

  const white = palette.common.white;

  return (
    <Page className={classes.page}>
      <NF colors={{
        buttonBg: palette.primary.light,
        buttonBgHover: palette.primary.main,
        iconPrimary: palette.primary.light,
        iconSecondary: palette.grey[400],
        subtitle: white,
        title: white,
      }} />
    </Page>
  );
};

export default NotFound;