import Pool from "./UserPool";
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {PORTAL_USER_PATH} from 'api';
import axios from "axios";
import {deleteAllSessionData} from "../helpers";

export async function authenticate(Username, Password) {
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({Username, Pool});
        const authDetails = new AuthenticationDetails({Username, Password});

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                resolve(data);
            },
            onFailure: err => {
                reject(err);
            },
            newPasswordRequired: data => {
                console.log('NewPasswordRequired is not implemented, contact support');
            }
        });
    });
}

export function logout() {
    const user = Pool.getCurrentUser();

    if (user) {
        user.signOut();
        deleteAllSessionData();

        console.log("User logged out")
    }
}

export async function getToken() {
    return new Promise((resolve, reject) => {

        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    reject();
                } else {
                    const token = session.accessToken;
                    if (token) {
                        resolve(token);
                    } else {
                        reject();
                    }
                }
            });
        } else {
            reject();
        }
    });
}

export async function signUp(firstName, lastName, email, password){
    let attributeList = [];

    attributeList.push(new CognitoUserAttribute({Name: 'given_name', Value: firstName}));
    attributeList.push(new CognitoUserAttribute({Name: 'family_name', Value: lastName}));
    attributeList.push(new CognitoUserAttribute({Name: 'email', Value: email}));

    return new Promise((resolve, reject) => {
        Pool.signUp(email, password, attributeList, null, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        })
    }
);
}

export async function verifyEmail(code, user){
    return new Promise( (resolve, reject) => {
        user.confirmRegistration(code, true,  (err, result)  => {
            if (err) {
                reject(err)
            } else {
                resolve(result)
            }
        })
    })
}

export async function forgotPassword(username){
    const user = getCognitoUser(username);

    return new Promise( (resolve, reject) => {
        user.forgotPassword(  {
          onSuccess: function (data) {
              resolve(data);
          },
          onFailure: err => {
            reject(err);
          }
        })
    })
}


export async function confirmPassword(username, verificationCode, newPassword) {
    const user = getCognitoUser(username);

    return new Promise((resolve, reject) => {
        user.confirmPassword(verificationCode, newPassword, {
            onSuccess: data => {
                resolve(data);
            },
            onFailure: err => {
                reject(err);
            }
        })
    })
}

export function getCognitoUser(Username) {
    return new CognitoUser({Username, Pool});
}

export async function changePassword(Password, NewPassword) {
    return new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        const username = user.getUsername();
        const authDetails = new AuthenticationDetails({username, Password});

        user.authenticateUser(authDetails, {
            onSuccess: function (result) {

                user.changePassword(Password, NewPassword, (err, result) => {
                    if (err) {
                        reject(err);
                    }

                    resolve();
                });
            },

            onFailure: err => {
                reject(err)
            }
        });
    });
}

export async function changeName(FirstName, LastName) {
    return new Promise((resolve, reject) => {

        axios.patch(PORTAL_USER_PATH, {
            first_name: FirstName,
            last_name: LastName
        })
        resolve();
    });
}

export function getName() {
    return axios.get("/api/auth-demo/v1/portalusers/current")
}