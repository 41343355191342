import { LandingLayout } from 'components';
import NotFound from './NotFound';

export const config = {
  entrypoint: NotFound,
  id: 'not-found',
  layout: LandingLayout,
  path: null,
};

export default config;