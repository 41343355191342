import React from 'react';

import { Page } from 'components';
import HomeBlurbs from './HomeBlurbs';
import HomeCommunity from './HomeCommunity';
import HomeIntro from './HomeIntro';

export function Home() {
  return (
    <Page>
      <HomeIntro />
      <HomeBlurbs />
      <HomeCommunity />
    </Page>
  );
};

export default Home;