import React from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Fade, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import background from 'assets/images/wsb-bank.png';
import { Container, Section } from 'components';
import HomeLoginWrapper from './HomeLoginWrapper';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    ad: {
      alignItems: 'center',
      display: 'flex',
      color: palette.grey[700],
      maxWidth: 520,
      padding: spacing(0,10),
      '& button': {
        fontSize: 24,
        minHeight: 48,
        minWidth: 300,
      },
      '& h2': {
        fontWeight: 500,
        marginBottom: spacing(3),
      },
    },
    intro: {
      backgroundClip: 'content-box',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 'auto',
      minHeight: 500,
      width: '100%',
      marginTop: spacing(2),
      [breakpoints.down('md')]: {
        backgroundSize: 'initial',
      },
      [breakpoints.down('sm')]: {
        backgroundClip: 'border-box',
        backgroundSize: 'initial',
        height: '100%',
        justifyContent: 'center',
        marginTop: 0,
      },
    },
    item: {
      '&:last-child div': {
        borderRight: 'none',
      },
      '& div': {
        '&:hover > *': {
          color: palette.primary.main,
        },
        borderRight: `1px solid black`,
        padding: spacing(0, 3),
        width: '100%',
      },
      '& h6': {
        fontSize: 14,
      },
      '& svg': {
        color: palette.primary.main,
        marginBottom: spacing(2),
      },
      cursor: 'pointer',
      padding: spacing(3, 0),
      textAlign: 'center',
      width: '20%',
    },
    login: {
      alignItems: 'center',
      display: 'flex',
      maxWidth: 600,
      padding: spacing(0,10),
      [breakpoints.down('md')]: {
        padding: spacing(0,2),
      },
    },
    paper: {
      border: `1px solid ${palette.grey[isDark ? 800 : 300]}`,
      borderTop: 'none',
    },
    section: {
      marginBottom: spacing(4),
    },
  };
});

export function Enroll() {
  const classes = useStyles();
  const rootIntl = 'home.intro';

  const renderedItems = [
    { icon: ['fal', 'credit-card-front'] },
    { icon: ['fal', 'money-bill-wave'] },
    { icon: ['fal', 'graduation-cap']},
    { icon: ['fal', 'percent'] },
    { icon: ['fal', 'money-check-edit-alt'] },
  ].map(({ icon, text }, i) => (
    <Grid className={classes.item} item key={i}>
      <div>
        <FontAwesomeIcon icon={icon} size='2x' />
        <Typography variant='subtitle1'>
          <Text id={`${rootIntl}.items.${i}.text`} />
        </Typography>
      </div>
    </Grid>
  ));

  return (
    <Section className={classes.section}>
      <Container className={classes.intro} justify='space-between'>
        <Hidden smDown>
          <Grid className={classes.ad} item md={5}>
            <Fade in timeout={1800}>
              <div>
                <Typography variant='h2'>
                  <Text id={`${rootIntl}.title`} />
                </Typography>
                <Button color='primary' size='large' variant='contained'> 
                  <Text id={`${rootIntl}.action`} />
                </Button>
              </div>
            </Fade>
          </Grid>
        </Hidden>
        <Grid className={classes.login} item md={6}>
          <HomeLoginWrapper />
        </Grid>
      </Container>
      <Container container justify='center'>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <Grid alignItems='center' container justify='center'>
              {renderedItems}
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </Section>
  );
};

export default Enroll;