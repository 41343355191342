import axios from 'axios';
import { AUTHENTICATIONS_PATH, encodeB64 } from '.';

 /**
 * An object containing Demo Authentication properties
 * @typedef {Object} AuthObject
 * @property {string} username - name of user to authenticate with
 */

/**
 * An object containing Demo Authentication status properties
 * @typedef {Object} AuthStatusObject
 * @property {number} authentication_id - check status on this transaction identifier
 */

 export async function deferPostAuthentication(args) {
   return await postAuthentication(args[0]);
 }

/**
 * Creates an authentication transaction for the user to authorize
 * @async
 * @param {AuthObject[]|AuthObject} authArgs
 * @returns {Object} whether "request" succeeded, not necessarily the auth
 */
export async function postAuthentication(args) {
  const { authenticationLevel, postOperationContext, preOperationContext, username } = args;

  return await axios.post(AUTHENTICATIONS_PATH, {
    authentication_level: authenticationLevel,
    pre_operation_context: {
      context_title_b64: encodeB64(preOperationContext.title),
      context_content_b64: encodeB64(preOperationContext.content),
      context_mime: 'text/plain',
    },
    post_operation_context: {
      context_title_b64 : encodeB64(postOperationContext.title),
      context_content_b64: encodeB64(postOperationContext.content),
      context_mime: 'text/plain',
    },
    username,
  });
};

/**
 * Fetches the status of an authentication transaction
 * @async
 * @param {AuthStatusObject} authStatus
 * @returns {Object} with status of the authentication request
 */
export async function getAuthenticationStatus({ authentication_id }) {
  return await axios.get(`${AUTHENTICATIONS_PATH}/${authentication_id}`);
};