import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { Page } from 'components';

import AccountsPanels from './AccountsPanels';

export function Accounts() {
  const { formatMessage } = useIntl();

  return (
    <Page>
      <Helmet title={formatMessage({ id: 'accounts.title' })} />
      <AccountsPanels />
    </Page>
  );
};

export default Accounts;