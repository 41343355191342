import Person from './Person';
import { SupportLayout } from 'components';

export const config = {
  entrypoint: Person,
  id: 'person',
  layout: SupportLayout,
  layoutProps: {},
  location: { pathname: '/bank/call-center/people/:username' },
};

export default config;