import axios from 'axios';
import {ENROLLMENTS_PATH, getCurrentAppConfig} from '.';

 /**
 * An object containing Demo Enrollment properties
 * @typedef {Object} EnrollObject
 * @property {string} username
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} username - name of user to authenticate with
 */

/**
 * An object containing Demo Enrollment status properties
 * @typedef {Object} EnrollStatusObject
 * @property {number} enrollment_id - check status on this transaction identifier
 */

/**
 * Creates an enrollment transaction for user to authorize
 * @async
 * @param {EnrollObject[]} enrollArgs
 * @returns {Object} and whether "request" succeeded, not necessarily the auth 
 */
export async function postUserEnrollment([username]) {
  let appConfig;
  await getCurrentAppConfig().then((value) => appConfig = value);

  return await axios.post(ENROLLMENTS_PATH, {
    username,
    app_config: appConfig
  });
};

/**
 * Fetches the status of an enrollment transaction
 * @async
 * @param {EnrollStatusObject} enrollStatus
 * @returns {Object} with status of the enrollment request 
 */
export async function getEnrollmentStatus({ enrollmentId }) {
  return await axios.get(`${ENROLLMENTS_PATH}/${enrollmentId}`);
};