import React from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';

import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import communityImage from 'assets/images/wsb-community.png';
import { Container, Section } from 'components';

const useStyles = makeStyles(({ palette, shadows, spacing }) => {
  return {
    card: {
      borderTop: `3px solid ${palette.primary.main}`,
      height: '100%',
    },
    content: {
      marginBottom: spacing(4),
    },
    image: {
      // boxShadow: shadows[2],
    },
    section: {
      marginBottom: spacing(4),
    },
  };
});

export function HomeCommunity() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const rootIntl = 'home.community';

  return (
    <Section className={classes.section}>
      <Container spacing={0}>
        <Grid item md={4} xs={12}>
          <img
            alt={formatMessage({ id: `${rootIntl}.imageAlt` })}
            className={classes.image}
            height='100%'
            src={communityImage}
            width='100%'
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Card className={classes.card}>
            <CardHeader title={<Text id={`${rootIntl}.title`} />} />
            <CardContent className={classes.content}>
              <Text id={`${rootIntl}.content`} />
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Section>
  );
};

export default HomeCommunity;