/** @module FormatHelpers */

import findIndex from 'lodash.findindex';
import get from 'lodash.get';

/**
 * @summary Reduces a nested object into a flatten hierarchy
 * @example { foo: { bar: 'baz' } } => { 'foo.bar' : 'baz' }
 * @param {Object} nestedMessages - object to flatten
 * @param {string} prefix - prepend flattened keys with this value
 * @returns {Object} a single flat object
 **/
export function flattenObject(nestedMessages, prefix = '') {
  if (nestedMessages === null) return {};

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if ( typeof value === 'string' ) {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenObject(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * @function getValues
 * @param fields - an array of Field components with overrides and extends
 * @param increment - increment is used to ensure keys are not the same
 * @returns 
 *  if variant === expanded, all values minus first value in array if array
 *  if variant == true, first value if array otherwise value
 *  if !variant, all values
 **/
export function getValues(data, item, variant) {
  const value = get(data, item.path || item);

  if (!variant) return value;

  if (Array.isArray(value) && value.length > 0) {
    const index = findIndex(value, item.filter);
    const selected = index === -1 ? 0 : index;
    
    return variant === 'expanded'
      ? value.splice(selected, 1) && value
      : value[selected];
  }

  return value;
};

/**
 * Checks whether all properties in array are either undefined or empty
 * @param arr - an array of data objects
 * @returns {boolean}
 **/
export function isEmpty(arr) {
  return arr.map((item) => {
    return (
      typeof item === 'undefined'
      || (Array.isArray(item) && item.length === 0)
    );
  }).every(bool => bool);
};

/**
 * TODO: Make into a function called formatter
 * @param {string} style
 * @param {...Object} - other attributes
 */
export const numberFormatter = (
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
);