import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => {
  return {
    error: {
      color: 'red',
      fontSize: 12,
      margin: ({ centered }) => centered ? '0 auto' : 0,
      textAlign: ({ centered }) => centered ? 'center' : 'left',
    },
  };
});

export function Error({ centered, error }) {
  const classes = useStyles({ centered });

  if (!error) return null;

  let message = error.message;

  if (error.response) {
    switch (error.response.status) {
      case 404:
        message = 'Not found';
        break;
      case 504:
        message = 'Gateway timeout';
        break;
      default:
        message = 'Something went wrong.';
    }
  }

  return (
    <div className={classes.error}>
      <Typography variant='body2'>{message}</Typography>
    </div>
  );
};

export default Error;