import React from 'react';
import { FormattedMessage as Text } from 'react-intl'
import moment from 'moment';

import { Button, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    button: {
      margin: '0 auto',
      marginTop: spacing(3),
    },
    none: {
      color: palette.grey[500],
    },
  };
});

export function AccountsBankActivity({ activity }) {
  const classes = useStyles();
  const rootIntl = 'accounts.activity';

  if (!activity || activity.length === 0) {
    return (
      <Typography className={classes.none}>
        <Text id={`${rootIntl}.none`} />
      </Typography>
    );
  };

  const renderedActivities = activity.map(({ cost, createdAt, name }) => (
    <TableRow key={name}>
      <TableCell>{moment(createdAt).format('MMM DD, YYYY')}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{cost}</TableCell>
    </TableRow>
  ));

  return (
    <>
      <Table>
        <TableBody>
          {renderedActivities}
        </TableBody>
      </Table>
      <Button className={classes.button} color='primary'>
        <Text id={`${rootIntl}.action`} />
      </Button>
    </>
  );
};

export default AccountsBankActivity;