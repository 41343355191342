import React from 'react';
import { Helmet } from 'react-helmet';

import { CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

import { ScrollToTop } from 'components';
import EncapFavicon from 'assets/images/favicon.ico';
import '../icons';
import { getTheme } from '../theme';

const MAX_WIDTH = 1920;

const useStyles = makeStyles(() => {
  return {
    bg: {
      background: `#2A0062`,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: MAX_WIDTH,
    },
  };
});

export function Landing({ children }) {
  const classes = useStyles();
  const title = 'Signicat Mobile Identity';

  return (
    <ScrollToTop>
      <ThemeProvider theme={getTheme(false)}>
        <CssBaseline />
        <Helmet defaultTitle={title} titleTemplate={`${title} | %s`}>
          <title>Signicat Mobile Identity Demos</title>
          <meta name='description' content='Signicat Mobile Identity demos' />
          <link rel='shortcut icon' href={EncapFavicon} />
        </Helmet>
        <div className={classes.bg}>
          <main className={classes.main}>
            {children}
          </main>
        </div>
      </ThemeProvider>
    </ScrollToTop>
  )
};

export default Landing;