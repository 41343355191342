import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { Link as RRLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  AppBar, Button, Collapse, Grid, Link, Paper, TextField as Field,
  Toolbar, Typography, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ breakpoints, palette, shadows, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    button: {
      marginBottom: spacing(2),
    },
    cc: {
      marginBottom: `${spacing(.75)}px !important`,
      minHeight: 64,
    },
    chevron: {
      transition: 'all .3s ease-in-out',
    },
    collapse: {
      backgroundColor: palette.grey[isDark ? 900 : 200],
      boxShadow: shadows[5],
      padding: spacing(3,10),
      position: 'absolute',
      textAlign: 'center',
      zIndex: 0,
    },
    collapseControl: {
      cursor: 'pointer',
      zIndex: 1,
    },
    collapseWrapper: {
      position: 'relative',
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
      },
      marginBottom: spacing(2.5),
    },
    icon: {
      color: palette.grey[isDark ? 300 : 800],
      paddingLeft: spacing(4),
      textAlign: 'center',
    },
    loggedIn: {
      marginBottom: spacing(2.5),
    },
    loginTitle: {
      color: palette.grey[800],
      fontWeight: 500,
      padding: spacing(3,4),
    },
    padding: {
      marginBottom: spacing(3),
      padding: spacing(0,4,0,5),
      textAlign: 'center',
    },
    root: {
      backgroundColor: palette.grey[isDark ? 900 : 200],
      boxShadow: shadows[5],
      width: '100%',
      [breakpoints.down('md')]: {
        backgroundColor: isDark ? '#000c' : '#fffc',
      },
    },
    rotate: {
      transform: 'rotate(90deg)',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    traditionalError: {
      boxSizing: 'content-box',
      display: 'block',
      minHeight: 20,
      padding: spacing(1),
    },
    unenrolled: {
      fontSize: 16,
      lineHeight: 1.5,
    },
  };
});

export default function HomeLogin({
  error,
  form,
  isAuth,
  onChange,
  onLogout,
  onSubmit,
  onTraditionalSubmit,
  traditionalError,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState();
  const { formatMessage } = useIntl();
  const rootIntl = 'home.login';

  const isNotLoggedIn = (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className={classes.loginTitle}
          color='primary'
          component='h2'
          gutterBottom
          variant='h5'
        >
          <Text id={`${rootIntl}.title`} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form className={classes.padding} onSubmit={onSubmit}>
          <Field
            className={clsx(classes.field, classes.cc)}
            error={!!error}
            fullWidth
            helperText={error && error.message}
            label={formatMessage({ id: `${rootIntl}.fields.0.label` })}
            name='cc_username'
            onChange={onChange}
            placeholder={formatMessage({ id: `${rootIntl}.fields.0.placeholder` })}
            required
            size='small'
            type='text'
            value={form.cc_username || ''}
            variant='outlined'
          />
          <Button
            className={classes.button}
            color='primary'
            fullWidth
            type='submit'
            variant='contained'
          >
            <Text id={`${rootIntl}.signIn`} />
          </Button>
          <Typography
            className={classes.unenrolled}
            component='div'
            variant='caption'
          >
            <Text id={`${rootIntl}.unenrolled1`} />
            <br />
            <Text id={`${rootIntl}.unenrolled2`} values={{
              dl: (val) => <Link href='#' underline='always'>{val}</Link>
            }} />
          </Typography>
        </form>
      </Grid>
      <Grid item xs={12}>
        <AppBar
          className={classes.collapseControl}
          color='inherit'
          elevation={3}
          position='relative'
          onClick={() => setOpen(!open)}
        >
          <Toolbar className={classes.toolbar} variant='dense'>
            <Text id={`${rootIntl}.backupOption`} />
            <FontAwesomeIcon
              className={clsx(classes.chevron, { [classes.rotate]: open })}
              icon={['fal','chevron-right']}
            />
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid className={classes.collapseWrapper} item xs={12}>
        <Collapse className={classes.collapse} in={open}>
          <form onSubmit={onTraditionalSubmit}>
            <Field
              className={classes.field}
              fullWidth
              label={formatMessage({ id: `${rootIntl}.fields.1.label` })}
              name='username'
              onChange={onChange}
              placeholder={formatMessage({ id: `${rootIntl}.fields.1.placeholder` })}
              required
              size='small'
              value={form.username || ''}
              variant='outlined'
            />
            <Field
              className={classes.field}
              fullWidth
              label={formatMessage({ id: `${rootIntl}.fields.2.label` })}
              name='password'
              onChange={onChange}
              placeholder={formatMessage({ id: `${rootIntl}.fields.2.placeholder` })}
              required
              size='small'
              style={{ marginBottom: 0 }}
              type='password'
              value={form.password || ''}
              variant='outlined'
            />
            <Typography
              className={classes.traditionalError}
              color='error'
              variant='caption'
            >
              {traditionalError}
            </Typography>
            <Button
              className={classes.button}
              color='primary'
              fullWidth
              type='submit'
              variant='contained'
            >
              <Text id={`${rootIntl}.login`} />
            </Button>
            <Typography component='div' variant='caption'>
              <Text id={`${rootIntl}.forgot`} values={{
                un: (val) => <Link href='#' underline='always'>{val}</Link>,
                pw: (val) => <Link href='#' underline='always'>{val}</Link>,
              }} />
            </Typography>
          </form>
        </Collapse>
      </Grid>
    </Grid>
  );

  const isLoggedIn = (
    <Grid alignContent='flex-start' container justify='space-between'>
      <Grid item xs={12}>
        <Typography
          className={classes.loginTitle}
          color='primary'
          component='h2'
          gutterBottom
          variant='h5'
        >
          <Text id={`${rootIntl}.welcome`} />
        </Typography>
      </Grid>
      <Grid className={classes.icon} item xs={2}>
        <FontAwesomeIcon icon={['fal', 'shield-check']} size='8x' />
      </Grid>
      <Grid item xs={8}>
        <div className={classes.padding}>
          <Typography
            className={classes.loggedIn}
            component='h2'
            gutterBottom
            variant='subtitle2'
          >
            <Text id={`${rootIntl}.signedIn`} />
          </Typography>
          <Button
            className={classes.button}
            color='primary'
            component={RRLink}
            to='/bank/accounts'
            variant='contained'
          >
            <Text id={`${rootIntl}.goToAccount`} />
          </Button>
          <Typography component='div' variant='caption'>
            <Text id={`${rootIntl}.orSignOut`} values={{
              a: (val) => (
                <Link href='' onClick={onLogout} underline='always'>
                  {val}
                </Link>
              ),
            }} />
          </Typography>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Zoom in timeout={1000}>
      <Paper className={classes.root}>
        {isAuth ? isLoggedIn : isNotLoggedIn}
      </Paper>
    </Zoom>
  );
};
