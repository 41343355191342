import React from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as IdCardSvg } from '../../../assets/images/Idcard.svg';


import { Loader } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    card: {
      marginBottom: spacing(2),
      padding: spacing(1, 2),
    },
    email: {
      color: palette.grey[500],
      fontSize: 14,
      textDecoration: 'underline',
    },
    svg:{
      height: 100,
      width: '100%',
    },
    text: {
      marginLeft: spacing(2),
      marginTop: spacing(2),
    },
    name: {
      fontSize: 18,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    title: {
      fontSize: 18,
      fontWeight: 300,
    },
  };
});

export function AccountsUserCard({ user }) {
  const classes = useStyles();

  if (!user) return <Loader />
  const { first_name, last_name, username } = user;

  return (
    <Card className={classes.card}>
      <CardHeader
        title={<Text id='accounts.myInfo.title' />}
        titleTypographyProps={{ className: classes.title, variant: 'h6' }}
      />
      <CardContent>
        <Grid container>
          <Grid item>
            <IdCardSvg className={classes.svg} />
          </Grid>
          <Grid item className={classes.text} >
            <Typography className={classes.name}>
              {`${first_name} ${last_name}`}
            </Typography>
            <Typography className={classes.email}>
              {username}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountsUserCard;