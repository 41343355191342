import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppBar, Drawer, Grid, IconButton, SvgIcon, TextField, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DemoMenu } from 'components/shared';
import Logo from '../Logo';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    icon: {
      color: palette.common.white,
    },
    input: {
      color: palette.common.white,
      '& fieldset': {
        border: 'none',
        outline: 'none',
      }
    },
    search: {
      backgroundColor: palette.primary.dark,
      marginTop: spacing(.5),
      minWidth: 350,
    },
    toolbar: {
      margin: '0 auto',
      width: '100%',
    },
  };
});

export default function SupportLayoutNav() {
  const classes = useStyles();

  return (
    <nav style={{ position: 'sticky', top: 0 }}>
      <AppBar elevation={0} position='sticky'>
        <Toolbar className={classes.toolbar} variant='dense'>
          <Grid alignItems='center' container>
            <Grid item style={{ flex: 1 }}>
              <Logo />
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                className={classes.search}
                InputProps={{ className: classes.input }}
                margin='dense'
                placeholder='Search...'
                type='search'
                variant='outlined'
              />
            </Grid>
            <Grid item style={{ flex: 1, textAlign: 'right' }}>
              <IconButton>
                <SvgIcon>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={['fad', 'user-headset']}
                  />
                </SvgIcon>
              </IconButton>
              <DemoMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar color='default' elevation={1} position='static'>
        <Toolbar style={{ maxHeight: 44, minHeight: 44 }} variant='dense' />
      </AppBar>
      <Drawer
        PaperProps={{ style: { width: 0 } }}
        open={true}
        style={{ width: 0, position: 'relative', zIndex: 1099 }}
        variant='persistent'
      >
        <Toolbar variant='dense' />
      </Drawer>
    </nav>
  );
};