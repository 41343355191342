import React, {useState} from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';

import { Dialog } from 'components/index';
import { setAppConfig } from 'api';



export function ChangeAppConfigDialog({ setSnackbar, openState, currentConfig, configList}) {
  const { formatMessage } = useIntl();
  const rootIntl = 'changeAppConfigDialog';

  const configs = configList;
  const [config, setConfig] = useState(currentConfig);

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [open, setOpen] = openState;

  const handleAccept = async () => {

    await setAppConfig(config).then(() => {
      showSnackbarMessage(
          formatMessage({id: `${rootIntl}.success`}, form),
          'success');
    }
   ).catch((errors) => {
      handleValidationFailure(errors);
      showSnackbarMessage(
          formatMessage({ id: `${rootIntl}.error` }, form),
          'error');
    });

    setOpen(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setConfig(event.target.value);

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const handleViewClose = () => {
    setErrors({});
    setForm({});
    setOpen(false);
  };

  const handleValidationFailure = (errors) => {
    setErrors(errors);
  }

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
                  message,
                  open: true,
                  severity: severity,
                });
  };

  return (
    <Dialog
      accept={{
        name: <Text id={`${rootIntl}.add`}/>,
        onClick: handleAccept
      }}
      decline={{
        name: <Text id={`${rootIntl}.cancel`}/>,
        onClick: handleViewClose
      }}
      maxWidth='sm'
      open={!!open}
      title={<Text id={`${rootIntl}.title`}/>}
      message={<Text id={`${rootIntl}.title`}/>}
    >

      <div>
        <Text id={`${rootIntl}.current`}/>
        <span>{currentConfig}</span>
      </div>

      <Text id={`${rootIntl}.change` }/>
      <select onChange={handleChange} defaultValue={currentConfig}>
        {configs.map((conf) => <option key={conf} value={conf}>{conf}</option>)}
      </select>

    </Dialog>
  );
};

export default ChangeAppConfigDialog;
