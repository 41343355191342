import Dashboard from './Dashboard';
import { SupportLayout } from 'components';

export const config = {
  entrypoint: Dashboard,
  id: 'people',
  layout: SupportLayout,
  location: { pathname: '/bank/call-center' },
};

export default config;