import React from 'react';
import { FormattedMessage as Text } from 'react-intl';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as TransferMoney } from '../../../assets/images/transfer-money.svg';

const useStyles = makeStyles(({ spacing }) => {
  return {
    text: {
      fontSize: 16,
      fontWeight: 300,
      padding: spacing(4,0),
    },
  };
});

export default function SendGraphic() {
  const classes = useStyles();

  return (
    <Grid item md={6} xs={12}>
      <TransferMoney height={375} width='100%' />
      <Typography
        align='center'
        className={classes.text}
        display='block'
        variant='caption'
      >
        <Text id='send.graphic' />
      </Typography>
    </Grid>
  );
};
