import axios from 'axios';
import {getToken, logout} from 'components';

const backendUrl = document.getElementById('demo-config') && document.getElementById('demo-config').getAttribute(
    'data-backend-url');

export const BASE_PATH = backendUrl || '/api/auth-demo/v1';
export const AUTHENTICATIONS_PATH = BASE_PATH + '/authentications';
export const DEVICES_PATH = BASE_PATH + '/devices';
export const ENROLLMENTS_PATH = BASE_PATH + '/enrollments';
export const USERS_PATH = BASE_PATH + '/users';
export const PORTAL_USER_PATH = BASE_PATH + '/portalusers';
export const APP_CONFIG_PATH = BASE_PATH + '/app-configs';

export * from './accounts';
export * from './authentications';
export * from './device';
export * from './enrollments';
export * from './users';
export * from './appConfigs';

/*
  Some values passed to the backend require encoding the value twice.
  This is a known issue with Encap.
*/
export const encodeB64 = (val) => btoa(btoa(val));

axios.interceptors.request.use(async config => {
    return getToken().then(token => {
          config.headers.Authorization = token.jwtToken;
          return config;
    });

}, error => {
    console.log("Failed to create request");
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => catchError(error));

export function catchError(error) {
    const {data, status} = error && error.response;

    if (!data) {
        return error;
    }

    if(status === 401 && data.code === "ACCESS_TOKEN_VALIDATION_FAILED"){
        logout();
        window.location = '/login';
    }

    const e = new Error(data.message);
    e.status = status;
    throw e;
}