import React, {useState} from 'react';
import {FormattedMessage as Text, useIntl} from 'react-intl';

import {TextField as Field} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles'

import {changeName, Dialog, Fields} from 'components';

const useStyles = makeStyles(({palette, spacing}) => {
    return {
        error: {
            fontSize: 14,
            marginLeft: spacing(2),
            paddingBottom: spacing(2),
        },
        field: {
            '& input': {
                backgroundColor: palette.background.paper,
                fontSize: 16
            },
            marginBottom: spacing(1),
            fontSize: 16
        },
    }
        ;
});

export function ChangeNameDialog({setSnackbar, openState, currentName}) {
    const classes = useStyles();
    const {formatMessage} = useIntl();
    const rootIntl = 'changeNameDialog';

    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [open, setOpen] = openState;

    const fields = [
        {name: 'portalUserFirstName', value: form.portalUserFirstName},
        {name: 'portalUserLastName', value: form.portalUserLastName,
            helperText: "Your current name is: " + currentName.first_name + " " + currentName.last_name
        },
    ].map((field, i) => {
        const error = errors[field.name] && errors[field.name][0];

        return {
            ...field,
            autoFocus: i === 0,
            error: !!error,
            label: <Text id={`${rootIntl}.fields.${i}.label`}/>,
            placeholder: formatMessage({id: `${rootIntl}.fields.${i}.placeholder`}),
            type: 'input',
        };
    });

    const handleAccept = () => {
        if (Object.keys(form).length === 0) {
            setOpen(false)
            setForm({})
        } else {
            handleNameChange()
        }
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        setForm({...form, [name]: value});
        setErrors({...errors, [name]: null});
    };

    const handleNameChange = async () => {
        showSnackbarMessage(
            formatMessage({id: `${rootIntl}.adding`}, form));

        changeName(form.portalUserFirstName, form.portalUserLastName)
            .then(() => {

                showSnackbarMessage(
                    formatMessage({id: `${rootIntl}.success`}, form),
                    'success');

                setOpen(false);
                setForm({});

            }).catch(err => {
            console.log(err);

            let formMessage = formatMessage({id: `${rootIntl}.error`}, form)

            showSnackbarMessage(
                formMessage,
                'error');

        });

    };

    const handleViewClose = () => {
        setErrors({});
        setForm({});
        setOpen(false);
    };

    const showSnackbarMessage = (message, severity) => {
        setSnackbar({
                        message,
                        open: true,
                        severity: severity,
                    });
    };

    return (
        <Dialog
            accept={{
                name: <Text id={`${rootIntl}.add`}/>,
                onClick: handleAccept
            }}
            decline={{
                name: <Text id={`${rootIntl}.cancel`}/>,
                onClick: handleViewClose
            }}
            maxWidth='sm'
            open={!!open}
            title={<Text id={`${rootIntl}.title`}/>}
            message={<Text id={`${rootIntl}.title`}/>}
        >
            <Fields
                className={classes.field}
                edit={true}
                onChange={handleChange}
            >
                {fields.map((field, i) => (
                    <Field key={i} {...field} />
                ))}
            </Fields>

        </Dialog>
    );
}

export default ChangeNameDialog;
