import Login from './Login';

import { LandingLayout } from 'components';

export const config = {
  entrypoint: Login,
  id: 'login',
  layout: LandingLayout,
  location: { pathname: '/login' },
  private: false,
};

export default config;