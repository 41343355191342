import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,
  IconButton, SvgIcon, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) => {
  return {
    heading: {
      fontSize: 18,
      fontWeight: 300,
    },
    icon: {
      marginRight: spacing(2),
    },
  };
});

export function AccountsPanelTitle({ title, ...other }) {
  const classes = useStyles();
  
  return (
    <ExpansionPanelSummary {...other}>
      <Grid alignItems='center' component={Grid} container>
        <Grid item>
          <IconButton className={classes.icon} size='small'>
            <SvgIcon>
              <FontAwesomeIcon icon={['fal', 'caret-down']} />
            </SvgIcon>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography className={classes.heading}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </ExpansionPanelSummary>
  );
};

export function AccountsPanel({ title, children, ...other }) {
  return (
    <ExpansionPanel {...other}>
      <AccountsPanelTitle title={title} />
      <Divider />
      <ExpansionPanelDetails>
        <Grid container>
          {children}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AccountsPanel;