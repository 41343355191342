import React from 'react';
import omit from 'lodash.omit';

import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    actions: {
      padding: spacing(2, 3, 2, 3),
    },
    progress: {
      color: palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: spacing(1),
      position: 'relative',
    },
  };
});

export function DialogFooter({ accept, decline, setOpen }) {
  const classes = useStyles();

  const onClose = () => {
    setOpen && setOpen(false);
  };

  const onAccept = async () => {
    accept.onClick && await accept.onClick();
    onClose();
  };

  const onDecline = async () => {
    decline.onClick && await decline.onClick();
    onClose();
  };

  const withProgress = (isPending, element) => {
    return (
      <div className={classes.wrapper}>
        {React.cloneElement(element, element.props)}
        {isPending && (
          <CircularProgress className={classes.progress} size={24}  />
        )}
      </div>
    );
  };

  const acceptButton = withProgress(
    accept.isPending,
    <Button
      color='primary'
      variant='contained'
      {...omit(accept, 'isPending')}
      onClick={onAccept}
    >
      {accept.name || 'Yes' }
    </Button>
  );

  const declineButton = decline ? (
    <Button
      color='default'
      variant='outlined'
      {...decline}
      onClick={onDecline}
    >
      {decline.name || 'No'}
    </Button>
  ) : null;

  return (
    <DialogActions className={classes.actions}>
      {declineButton}
      {acceptButton}      
    </DialogActions>
  );
}

export default DialogFooter;