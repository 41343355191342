import React, { useState } from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import find from 'lodash.find';
import fromPairs from 'lodash.frompairs';
import get from 'lodash.get';
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, List,
  ListItem, ListItemIcon, ListItemText, SvgIcon, Tooltip, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getSessionsByDeviceId } from 'api';
import { AsyncRecord, SupportHeading } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    status: { // ORDER MATTERS
      backgroundColor: palette.secondary.dark,
      borderRadius: 4,
      color: 'black',
      display: 'inline-flex',
      margin: spacing(1, 0),
      padding: '4px 12px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: 48,
      '& *': {
        flexBasis: '50%',
      },
      '& button:hover': {
        color: palette.primary.main,
      },
    },
    button: {
      marginBottom: spacing(2),
    },
    cancelled_by_device: {
      backgroundColor: palette.warning.main,
      color: palette.common.white,
    },
    cancelled_by_sp: {
      backgroundColor: palette.warning.dark,
      color: palette.common.white,
    },
    cell: {
      padding: spacing(0, 1),
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: palette.grey[500],
    },
    container: {
      padding: spacing(4),
    },
    expandValue: {
      marginBottom: spacing(2),
    },
    failed: {
      backgroundColor: palette.error.dark,
      color: palette.getContrastText(palette.error.dark),
    },
    in_progress: {
      backgroundColor: palette.primary.dark,
      color: palette.getContrastText(palette.primary.dark),
    },
    listIcon: {
      minWidth: 28,
    },
    notApplicable: {
      color: palette.grey[600],
      fontSize: 12,
    },
    purpose: {
      '& > span:first-child': {
        textTransform: 'capitalize',
      },
      fontSize: 12,
    },
    purposeWrapper: {
      minWidth: 24,
    },
    securityTitle: {
      paddingTop: spacing(3),
    },
    success: {
      backgroundColor: palette.success.dark,
      color: palette.getContrastText(palette.success.dark),
    },
    tooltip: {
      cursor: 'pointer',
    }
  };
});

export default function PersonLog({ deviceId }) {
  const classes = useStyles();
  const rootIntl = 'support.person.log';
  const [activity, setActivity] = useState(null);
  const [security, setSecurity] = useState(null);
  const [poll, setPoll] = useState(false);
  const matches = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const getGoogleMapUrl = (coords) => (
    `https://www.google.com/maps/place/${coords}`
  );

  const formatTime = (time) => {
    return moment(time).format('MMM DD, YYYY @ hh:mm a');
  };

  const handleActivityDialogClose = () => {
    setActivity(null);
    setPoll(prevPoll => !prevPoll)
  };

  const handleActivityDialogOpen = (event, row) => {
    event.preventDefault();
    setActivity(row);
  };

  const handleSecurityDialogOpen = (event) => {
    event.preventDefault();
    setSecurity(true);
  };

  const handleSecurityDialogClose = () => {
    setSecurity(null);
    setPoll(prevPoll => !prevPoll)
  };

  const NotApplicableValue = () => {
    return (
      <Typography className={classes.notApplicable}>
        <Text id={`${rootIntl}.notApplicable`} />
      </Typography>
    );
  };

  const StyledActions = ({ item, session_status={} }) => {
    return (
      <div className={classes.actions}>
        <Tooltip title={<Text id={`${rootIntl}.expand.action`} />}>
          <div>
            <IconButton
              onClick={(event) => handleActivityDialogOpen(event, item)}
              size='small'
              style={{ display: 'inline' }}
            >
              <FontAwesomeIcon icon={['fal','expand']} />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={<Text id={`${rootIntl}.checklist.action`} />}>
          <div>
            <IconButton
              disabled={session_status.status !== 'SUCCESS'}
              style={{ display: 'inline' }}
              onClick={handleSecurityDialogOpen}
              size='small'
            >
              <FontAwesomeIcon icon={['fal', 'shield-check']} />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
  };

  const StyledLocation = ({ coords }) => {
    if (typeof coords === 'undefined' || coords === '') {
      return <NotApplicableValue />;
    };

    const parsedCoords = coords.split(',');
    const parsedLat = parseFloat(parsedCoords[0]).toFixed(2);
    const parsedLong = parseFloat(parsedCoords[1]).toFixed(2)

    return (
      <Link
        component='a'
        href={getGoogleMapUrl(coords.replace(' ', ''))}
        onClick={(event) => event.stopPropagation()}
        rel='noopener noreferrer'
        target='_blank'
      >
        {`${parsedLat}, ${parsedLong}`}
      </Link>
    );
  };

  const StyledStatus = ({ status }) => {
    return (
      <Tooltip
        arrow={true}
        className={classes.tooltip}
        placement='bottom-start'
        title={<Text id={`${rootIntl}.statusTips.${status}`} />}
      >
        <p className={clsx(classes.status, classes[status.toLowerCase()])}>
          {status.replace(/_/g, ' ')}
        </p>
      </Tooltip>
    );
  };

  const StyledPurpose = ({ purpose }) => {
    const icons = {
      'ACTIVATION': ['fal', 'lock'],
      'AUTHENTICATION': ['fal', 'lock-open'],
      'AUTH_AND_ACTIVATE': ['fal', 'key'],
    };

    return (
      <div className={classes.purposeWrapper}>
        <Tooltip
          className={clsx(classes.tooltip, classes.purpose)}
          title={capitalize(purpose)}
        >
          <SvgIcon fontSize='small'>
            <FontAwesomeIcon icon={icons[purpose]} />
          </SvgIcon>
        </Tooltip>
      </div>
    );
  };

  const relIntl = `${rootIntl}.headers`;

  const ActivityDialog = () => {
    if (!activity) return null;

    const Attribute = ({ modifier, path }) => {
      const value = get(allAttributes, path);

      return !!value ? (
        <div key={path}>
          <Typography variant='subtitle2'>
            <Text id={`${rootIntl}.attributes.${path}`} />
          </Typography>
          <Typography className={classes.expandValue} component='div'>
            {modifier && value ? modifier(value) : value}
          </Typography>
        </div>
      ) : null;
    };

    const riskAttributes = activity.risk_attributes
      && fromPairs(activity.risk_attributes.map((attr) => {
        return [attr.name, attr.value];
      }));

    const allAttributes = { ...activity, ...riskAttributes };

    const deviceAttributes = [
      { path: 'device_id' },
      { path: 'device_manufacturer' },
      { path: 'device_model' },
      { path: 'operating_system_type' },
      { path: 'operating_system_version' },
      { path: 'is_secure_screen_lock_enabled' },
      { path: 'is_root_available' },
    ].map((item) => <Attribute key={item.path} {...item} />);

    const contextAttributes = [
      {
        modifier: (val) => <StyledStatus status={val} />,
        path: 'session_status.status'
      },
      { path: 'session_status.description' },
      { path: 'authentication_level' },
      {
        modifier: (val) => atob(atob(val)),
        path: 'pre_operation_context.context_title_b64',
      },
      {
        modifier: (val) => atob(atob(val)),
        path: 'pre_operation_context.context_content_b64',
      },
      {
        modifier: (val) => atob(atob(val)),
        path: 'post_operation_context.context_title_b64',
      },
      {
        modifier: (val) => atob(atob(val)),
        path: 'post_operation_context.context_content_b64',
      },
    ].map((item) => <Attribute key={item.path} {...item} />);

    const locationAttributes = [
      {
        modifier: (val) => `${val} meters`,
        path: 'location.accuracy',
      },
      {
        modifier: (val) => `${parseFloat(val).toFixed(2)} meters`,
        path: 'location.altitude',
      },
      {
        modifier: (val) => `${parseFloat(val).toFixed(2)}\u2070`,
        path: 'location.latitude',
      },
      {
        modifier: (val) => `${parseFloat(val).toFixed(2)}\u2070`,
        path: 'location.longitude',
      },
    ].map((item) => <Attribute key={item.path} {...item} />);

    return (
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={handleActivityDialogClose}
        open={!!activity}
      >
        <DialogTitle disableTypography>
          <Typography variant='h4'>
            <Text id={`${rootIntl}.title`} />
          </Typography>
          <Typography className={classes.purpose} variant='caption'>
            <span>{allAttributes.purpose.toLowerCase()}</span>
            &nbsp;on&nbsp;
            <span>{formatTime(activity.session_created_time)}</span>
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleActivityDialogClose}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container justify='space-between'>
            {!matches ? null : (
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  color='secondary'
                  onClick={handleSecurityDialogOpen}
                  variant='contained'
                >
                  <Text id={`${rootIntl}.checklist.action`} />
                </Button>
              </Grid>
            )}
            <Grid item md={4} xs={12}>
              <Typography gutterBottom variant='h6'>
                <Text id={`${rootIntl}.expand.deviceTitle`} />
              </Typography>
              {deviceAttributes}
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography gutterBottom variant='h6'>
                <Text id={`${rootIntl}.expand.contextTitle`} />
              </Typography>
              {contextAttributes}
            </Grid>
            <Grid item md={4} xs={12}>
              {typeof allAttributes.location === 'undefined' ? null : (
                <>
                  <Typography gutterBottom variant='h6'>
                    <Text id={`${rootIntl}.expand.locationTitle`} />
                  </Typography>
                  {locationAttributes}
                  <Button
                    color='primary'
                    href={allAttributes.location && getGoogleMapUrl(`
                      ${allAttributes.location.latitude},
                      ${allAttributes.location.longitude}
                    `)}
                    rel='noopener noreferrer'
                    target='_blank'
                    variant='contained'
                  >
                    <Text id={`${rootIntl}.expand.locationAction`} />
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const SecurityDialog = () => {
    if (!security) return null;

    const categoricalLists = {
      'checklist.consent.title': [
        { name: 'checklist.consent.userApproval' },
      ],
      'checklist.crypto.title': [
        { name: 'checklist.crypto.dynamicKey' },
        { name: 'checklist.crypto.hardwareKey' },
        { name: 'checklist.crypto.e2eTunnel' },
      ],
      'checklist.risk.title': [
        { name: 'checklist.risk.location' },
        { name: 'checklist.risk.rootDetect' },
        { name: 'checklist.risk.debugStatus' },
        { name: 'checklist.risk.clientIP' },
      ],
      'checklist.device.title': [
        { name: 'checklist.device.cloneDetect' },
        { name: 'checklist.device.appAttest' },
      ],
      'checklist.auth.title': [
        { name: 'checklist.auth.pin' },
        { name: 'checklist.auth.device' },
        { name: 'checklist.auth.bio' },
      ],
    };

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        onClose={handleSecurityDialogClose}
        open={!!security || true}
      >
        <DialogTitle className={classes.securityTitle} disableTypography>
          <Typography align='center' variant='h4'>
            <Text id={`${rootIntl}.checklist.title`} />
          </Typography>
          <Typography align='center' className={classes.purpose}>
            <Text id={`${rootIntl}.checklist.desc`} />
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleSecurityDialogClose}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container justify='space-between'>
            <Grid item xs={12}>
              {Object.entries(categoricalLists).map(([key, list]) => {
                const items = list.map((item) => (
                  <ListItem key={item.name}>
                    <ListItemIcon className={classes.listIcon}>
                      <SvgIcon htmlColor='green' fontSize='small'>
                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText>
                      <Text id={`${rootIntl}.${item.name}`} />
                    </ListItemText>
                  </ListItem>
                ));

                return (
                  <List dense key={key}>
                    <Typography gutterBottom variant='h6'>
                      <Text id={`${rootIntl}.${key}`} />
                    </Typography>
                    {items}
                  </List>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const modifyData = (data = []) => {
    return data.map((item) => {
      const {
        location = {},
        risk_attributes = [],
        pre_operation_context = {},
        purpose,
        session_created_time,
        session_status = {},
        ...other
      } = item;
      const coords = location && location.latitude && location.longitude
        ? `${location.latitude}, ${location.longitude}`
        : '';

      let session_context = '';

      if (purpose === 'ACTIVATION') {
        session_context = btoa(btoa('Enrolling device'))
      } else if (purpose === 'AUTH_AND_ACTIVATE') {
        session_context = btoa(btoa('Activating new auth method'))
      } else if (pre_operation_context) {
        session_context = pre_operation_context.context_content_b64;
      }

      const mfr = find(risk_attributes, { name: 'device_manufacturer' });
      const model = find(risk_attributes, { name: 'device_model' })

      return {
        device_model: `${mfr ? mfr.value : ''} ${model ? model.value : ''}`,
        session_created_time: formatTime(session_created_time),
        session_status: <StyledStatus status={session_status.status} />,
        session_purpose: <StyledPurpose purpose={purpose} />,
        session_context: session_context ? atob(atob(session_context)) : <NotApplicableValue />,
        ...!matches ? { location: <StyledLocation coords={coords} /> } : {},
        ...other,
        ...!matches ? { actions: <StyledActions item={item} session_status={session_status} /> } : {},
      };
    });
  };

  return (
    <div className={classes.container}>
      <SupportHeading title={<Text id={`${rootIntl}.title`} />} />
      {!!deviceId ? (
        <AsyncRecord
          AsyncProps={{
            deviceId,
            onResolve: () => !activity && !security && !setPoll(prevPoll => !prevPoll),
            promiseFn: getSessionsByDeviceId,
            watch: poll,
          }}
          mapDataToHeaders={{
            session_purpose: '',
            session_created_time: <Text id={`${relIntl}.0`} />,
            device_model: <Text id={`${relIntl}.1`} />,
            session_status: <Text id={`${relIntl}.2`} />,
            session_context: <Text id={`${relIntl}.4`} />,
            ...!matches ? { location: <Text id={`${relIntl}.5`} /> } : {},
            ...!matches ? { actions: '' } : {},
          }}
          modify={(data) => modifyData(data.items).reverse()}
          TableProps={{
            padding: 'none',
            size: 'small',
          }}
          TableCellProps={{
            className: classes.cell,
          }}
          TableRowProps={{
            onClick: matches ? handleActivityDialogOpen : undefined,
          }}
        />
      ) : (
        <Typography align='center'>
          <Text id={`${rootIntl}.deviceNotFound`} />
        </Typography>
      )}
      <ActivityDialog />
      <SecurityDialog />
    </div>
  );
};