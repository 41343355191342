import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowLeft,
  faBars,
  faCaretDown,
  faChevronRight,
  faCreditCardFront,
  faGraduationCap,
  faICursor,
  faList,
  faMoneyBillWave,
  faMoneyCheckEditAlt,
  faPercent,
  faShieldCheck,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

import {
  faDna,
  faEllipsisVAlt,
  faFileSearch,
  faIdCard,
  faMoneyCheck,
  faPlus,
  faRocket,
  faSwatchbook,
  faTrash,
  faTreasureChest,
  faUndo,
  faUniversity,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faArrowLeft,
  faBars,
  faCaretDown,
  faChevronRight,
  faCreditCardFront,
  faDna,
  faEllipsisVAlt,
  faFileSearch,
  faGraduationCap,
  faICursor,
  faIdCard,
  faList,
  faMoneyBillWave,
  faMoneyCheck,
  faMoneyCheckEditAlt,
  faPercent,
  faPlus,
  faRocket,
  faShieldCheck,
  faSwatchbook,
  faTimes,
  faTrash,
  faTreasureChest,
  faUndo,
  faUniversity,
);