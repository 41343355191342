import React from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, spacing }) => ({
  cardContent: {
    padding: 0,
  },
  content: {
    padding: 0,
    position: 'relative',
    transition: 'all .3s ease-in-out',
  },
  insight: {
    '&:hover #content': {
      transform: 'translateY(-48px)',
    },
    cursor: 'pointer',
  },
  insightAction: {
    '& button': {
      color: palette.primary.main,
    },
    bottom: -48,
    padding: spacing(2, 2, 1, 2),
    position: 'absolute',
  },
  insightCategory: {
    backgroundColor: '#0008',
    color: palette.common.white,
    fontSize: 12,
    left: 16,
    letterSpacing: 2,
    padding: spacing(0.5, 2),
    position: 'absolute',
    textTransform: 'uppercase',
    top: -25,
  },
  insightContent: {
    backgroundColor: palette.background.paper,
    padding: spacing(2),
    height: '100%',
    width: '100%',
  },
}));

export default function SlideCard({
  actions,
  category = '',
  media,
  subtitle,
  title,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.insight}>
      <CardMedia image={media} style={{ height: 250 }} />
      <div className={classes.content} id='content'>
        {category && <div className={classes.insightCategory}>{category}</div>}
        <CardContent className={classes.cardContent}>
          <div className={classes.insightContent}>
            <Typography variant='h6'>{title}</Typography>
            <Typography variant='subtitle1'>{subtitle}</Typography>
          </div>
        </CardContent>
        <div className={classes.insightAction}>
          {actions}
        </div>
      </div>
    </Card>
  );
}
