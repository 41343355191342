import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';

import AsyncContent from '../AsyncContent';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  return {
    section: {
      backgroundColor: ({ color }) => color ? color : palette.background.default,
      padding: spacing(1, 0),
      [breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  };
});

export function Section({ AsyncProps, children, className, color, ...rest }) {
  const classes = useStyles({ color });

  const content = AsyncProps
    ? <AsyncContent {...AsyncProps}>{children}</AsyncContent>
    : children;

  return (
    <section className={clsx(classes.section, className)} {...rest}>
      {content}
    </section>
  );
};

export default Section;