import {getAsyncLocalStorageItem, setAsyncLocalStorageItem} from 'components';
import axios from "axios";
import {APP_CONFIG_PATH} from "./index";

const CONFIG = 'config';

let defaultConfig = process.env.REACT_APP_CONFIG;

if (!defaultConfig) {
    defaultConfig = "encapDemo";
    console.log("No App Config set, will use default: ", defaultConfig);
}

export async function setAppConfig(config) {
    await setAsyncLocalStorageItem(CONFIG, config);
}

export function getCurrentAppConfig() {
    return getAsyncLocalStorageItem(
        CONFIG,
        {set: defaultConfig}
    );
}

export async function getAppConfigList() {
    return await axios.get(APP_CONFIG_PATH);
}
