import React from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Container } from '../Container';

const useStyles = makeStyles(({ palette, spacing }) => {
  const isDark = palette.type === 'dark';

  const one = palette.primary.main;
  const two = palette.grey[isDark ? 400 : 400];

  return {
    button: {
      '&:hover': {
        backgroundColor: ({ colors }) => colors.buttonBgHover
      },
      backgroundColor: ({ colors }) => colors.buttonBg,
      textAlign: 'center',
    },
    container: {
      paddingTop: spacing(4),
      height: '100vh',
    },
    icon: {
      marginBottom: spacing(4),
      textAlign: 'center',
      '--fa-primary-color': ({ colors }) => colors.iconPrimary || one,
      '--fa-secondary-color': ({ colors }) => colors.iconSecondary || two,
      '--fa-secondary-opacity': 1.0,
    },
    subtitle: {
      color: ({ colors }) => colors.subtitle || palette.text.primary,
      marginBottom: spacing(4),
    },
    title: {
      color: ({ colors }) => colors.title || palette.primary.main,
      fontWeight: 700,
      marginBottom: spacing(3),
    },
  };
});

export function NotFound({ colors={} }) {
  const classes = useStyles({ colors });
  const rootIntl = 'shared.notFound';

  return (
    <Container
      alignContent='center'
      alignItems='center'
      className={classes.container}
      justify='center'
    >
      <Grid className={classes.icon} item xs={12}>
        <FontAwesomeIcon size='8x' icon={['fad', 'file-search']} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          align='center'
          className={classes.title}
          component='h1'
          gutterBottom
          variant='h1'
        >
          <Text id={`${rootIntl}.title`} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align='center'
          className={classes.subtitle}
          component='h2'
          gutterBottom
          variant='subtitle2'
        >
          <Text id={`${rootIntl}.desc`} />
        </Typography>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xl={2} xs={10}>
        <Button
          className={classes.button}
          color='primary'
          component={RouterLink}
          fullWidth to='/'
          variant='contained'
        >
          <Text id={`${rootIntl}.action`} />
        </Button>
      </Grid>
    </Container>
  );
};

export default NotFound;