import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { Page } from 'components';

import SendGraphic from './SendGraphic';
import SendMoney from './SendMoney';
import SendTitle from './SendTitle';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => {
  return {
    page: {
      minHeight: 'calc(100vh - 160px)',
    },
  };
});

export function Send() {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page className={classes.page}>
      <Helmet title={formatMessage({ id: 'send.title' })} />
      <SendTitle />

      <Grid container justify='center'>
        <SendMoney />
        <SendGraphic />
      </Grid>
    </Page>
  );
};

export default Send;
