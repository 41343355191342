import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage as Text } from 'react-intl';

import { Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SlideCard } from 'components';
import CSR from 'assets/images/csr.jpg';
import CardSwipe from 'assets/images/card-swipe.jpg';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  return {
    cardContent: {
      alignItems: 'center',
      backgroundColor: 'inherit',
      color: 'inherit',
      display: 'flex',
      fontSize: 8,
    },
    cards: {
      padding: spacing(6,2),
      width: '100%',
      [breakpoints.down('md')]: {
        padding: spacing(4,2),
      },
    },
    header: {
      alignItems: 'center',
      backgroundImage: `linear-gradient(#3334, #fff4),url(${CSR})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: palette.common.black,
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      minHeight: 450,
      padding: spacing(4),
      [breakpoints.up('xl')]: {
        minHeight: 650,
      },
    },
    icon: {
      color: palette.secondary.dark,
      marginRight: spacing(2),
    },
    subtitle: {
      letterSpacing: 4,
      textTransform: 'uppercase',
      [breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    title: {
      fontWeight: 500,
      marginTop: spacing(2),
      [breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  };
});

export default function Dashboard() {
  const classes = useStyles();
  const rootIntl = 'support.dashboard';

  const NewLink = (props) => <Link component={RouterLink} {...props} />;

  return (
    <>
      <Grid
        alignItems='center'
        container
        justify='center'
      >
        <Grid className={classes.header} item xs={12}>
          <Grid container spacing={2}>
            <Typography
              align='left'
              className={classes.title}
              component={Grid}
              display='block'
              item
              variant='h4'
              xs={12}
            >
              <Text id={`${rootIntl}.title`} />
            </Typography>
            <Typography
              align='left'
              className={classes.subtitle}
              component={Grid}
              display='block'
              item
              variant='h6'
              xs={12}
            >
              <Text id={`${rootIntl}.tagline`} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cards} container justify='center' spacing={4}>
        <Grid item xs={12}>
          <Typography align='center' variant='h4'>
            <Text id={`${rootIntl}.heading`} />
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
          <NewLink to='/bank/call-center/people' underline='none'>
            <SlideCard
              actions={(
                <Button>
                  <Text id={`${rootIntl}.items.0.action`} />
                </Button>
              )}
              media={CardSwipe}
              title={(
                <div className={classes.cardContent}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={['fad', 'users']}
                    size='4x'
                  />
                  <Typography variant='h5'>
                    <Text id={`${rootIntl}.items.0.name`} />
                  </Typography>
                </div>
              )}
            />
          </NewLink>
        </Grid>
      </Grid>
    </>
  );
};