import React, { useEffect, useState } from 'react';
import { FormattedMessage as Text } from 'react-intl'

import { DemoAlert } from 'components';

export default function TransactionStatus({
  onClick,
  response,
  rootIntl,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(Object.keys(response).length > 0);
  }, [response]);

  const handleClose = () => {
    setOpen(false);
  };

  const getErrorMessage = (error) => {
    return error.status === 423 ? statusToMessage('LOCKED') : error.message;
  };

  const getNoAuthMessage = (response) => {
    const { description, status } = response.data;
    return statusToMessage(status) || description;
  };

  const getTransactionMessage = () => {
    return response.data ? getNoAuthMessage(response) : getErrorMessage(response);
  };

  const statusToMessage = (status) => (
    <Text id={`${rootIntl}.statusTips.${status}`} />
  );

  return (
    <DemoAlert
      actionText={`${rootIntl}.statusConfirmed`}
      open={open}
      onClick={onClick}
      onClose={handleClose}
      severity='warning'
    >
      {getTransactionMessage(response)}
    </DemoAlert>
  );
};
