import { createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { getTheme as bankTheme } from 'components/bank/theme';

export const getTheme = (isDark) => createMuiTheme({
  ...bankTheme(isDark),
  palette: {
    ...bankTheme(isDark).palette,
    warning: {
      dark: orange[700],
      light: orange[300],
      main: orange[500],
    },
  },
  typography: {
    fontSize: 12,
  },
});

export default getTheme;