import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

export function VisibleButton({
  IconProps,
  TooltipProps,
  visible,
  ...rest
}) {
  const statefulTitle = visible ? 'Hide' : 'Show';

  return (
    <BaseButton
      Icon={FontAwesomeIcon}
      IconProps={{
        icon: ['fad', visible ? 'eye-slash' : 'eye'],
        ...IconProps,
      }}
      TooltipProps={{ title: statefulTitle, ...TooltipProps }}
      type='fa'
      {...rest}
    />
  );
};

export default VisibleButton;