import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const getTheme = (isDark) => createMuiTheme({
  palette: {
    background: {
      ...isDark ? {
        default: grey[900],
        paper: '#313131',
      } : {},
    },
    primary: {
      main: '#2A0062',
      dark: '#8066A1',
    },
    secondary: {
      main: '#e5ebee',
    },
    signicat: {
      purple: '#2A0062',
      green: '#41FFD1',
      lightPurple: '#8066A1',
    },
    type: isDark ? 'dark' : 'light',
  },
  shape: {
    borderRadius: 0,
  },
});

export default getTheme;