import React, { useRef, useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { Button, Grid, Stepper, Step, StepContent, StepLabel, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import QRCode from 'qrcode.react';

import {
  AsyncContent, Container, expireCookie, getCookie, Page, poll, Section, Timer,
} from 'components';
import { getEnrollmentStatus, postUserEnrollment } from 'api';
import appStore from '../../../assets/images/app_store.png';
import googlePlay from '../../../assets/images/google_play.png';
import phoneInHand from '../../../assets/images/phone-in-hand.png';
import { ReactComponent as Completed } from '../../../assets/images/completed.svg';
import { ReactComponent as MobileDevicePerson } from '../../../assets/images/mobile-device-person.svg';
import { useEffect } from 'react';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  return {
    button: {
      marginRight: spacing(2),
    },
    caption: {
      color: palette.primary.dark,
    },
    desc: {
      '& span': {
        backgroundColor: palette.background.paper,
        color: palette.primary.dark,
        fontWeight: 500,
        padding: spacing(.25,.5),
      },
      fontWeight: 300,
    },
    expiration: {
      color: palette.grey[500],
      fontSize: 14,
    },
    img: {
      height: 40,
      marginBottom: spacing(2),
    },
    imgFeature: {
      border: '1px solid darkgrey',
      marginBottom: spacing(3),
    },
    stepper: {
      backgroundColor: palette.background.default,
      minHeight: 600,
    },
    title: {
      paddingTop: spacing(6),
      [breakpoints.down('sm')]: {
        paddingTop: spacing(2),
      },
    },
  };
});

export default function Activate() {
  const classes = useStyles();
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const [activeStep, setActiveStep] = useState(0);
  const rootIntl = 'activate';
  const LAST_STEP = 2;

  const buttonProps = {
    className: classes.button,
    disableElevation: true,
    variant: 'contained',
  };

  const handleActivation = async () => {
    const username = getCookie('username');
    return postUserEnrollment([username]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep > 0 ? prevActiveStep - 1 : 0;
    });
  };

  const handleFinish = () => {
    expireCookie('username');
    expireCookie('authentication_id');
    expireCookie('sign_in_method');
    return true;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep < LAST_STEP ? prevActiveStep + 1 : LAST_STEP;
    });
  };

  const BackButton = (props) => (
    <Button color='default' onClick={handleBack} {...buttonProps} {...props}>
      <Text id={`${rootIntl}.buttonBack`} />
    </Button>
  );

  const FinishButton = (props) => (
    <Button
      color='primary'
      component={RRLink}
      onClick={handleFinish}
      to={'/bank'}
      {...buttonProps}
      {...props}
    >
      <Text id={`${rootIntl}.buttonFinish`} />
    </Button>
  );

  const NextButton = (props) => (
    <Button color='primary' onClick={handleNext} {...buttonProps} {...props}>
      <Text id={`${rootIntl}.buttonNext`} />
    </Button>
  );

  const Hidden = ({ children, condition, ...other }) => !!condition ? null : (
    <StepContent {...other}>{children}</StepContent>
  );

  const steps = [
    'step1.title',
    'step2.title',
    'step3.title',
  ].map((step) => <Text id={`${rootIntl}.${step}`} />);

  const FulfilledContent = ({ data: { data } }) => {
    const { activation_code, enrollment_id } = data;
    const poller = useRef(null);

    useEffect(() => {
      const pollActivation = async () => {
        poller.current = await poll({
          enrollmentId: enrollment_id,
          promiseFn: getEnrollmentStatus,
          onResponse: { success: () => handleNext() },
        });
      };

      pollActivation();
      return () => poller.current.cancel();
    }, [enrollment_id]);

    const handleCancel = () => {
      poller.current.cancel();
      handleBack();
    };

    return (
      <Grid
        alignContent='space-between'
        alignItems='center'
        container
        justify='space-between'
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography className={classes.desc} variant='subtitle2'>
            <Text
              id={`${rootIntl}.step2.content`}
              values={{ code: <span>{activation_code}</span> }}
            />
          </Typography>
        </Grid>
        <Grid item style={{ marginLeft: 24 }}>
          <QRCode
            bgColor={palette.background.default}
            fgColor={palette.primary.main}
            renderAs='svg'
            size={200}
            value={activation_code}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.expiration}>
            <Text id={`${rootIntl}.step2.expired`} />
            <Timer time={300} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BackButton onClick={handleCancel} />
        </Grid>
      </Grid>
    );
  };

  const renderedSteps = steps.map((key, i) => (
    <Step key={key}>
      <StepLabel>{key}</StepLabel>
      <Hidden condition={activeStep !== 0 || i !== 0}>
        <Grid
          alignContent='space-between'
          alignItems='center'
          container
          justify='space-between'
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography className={classes.desc} variant='subtitle2'>
              <Text id={`${rootIntl}.step1.desc`} />
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <MobileDevicePerson height={200} width='100%' />
          </Grid>
          <Grid item xs={5}>
            <img
              alt={formatMessage({ id: `${rootIntl}.step2.imgAppStore`})}
              className={classes.img}
              src={appStore}
            />
            <img
              alt={formatMessage({ id: `${rootIntl}.step2.imgGooglePlay`})}
              className={classes.img}
              src={googlePlay}
            />
          </Grid>
          <Grid item xs={12}>
            <NextButton />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden condition={activeStep !== 1 || i !== 1}>
        <AsyncContent promiseFn={handleActivation}>
          <FulfilledContent />
        </AsyncContent>
      </Hidden>
      <Hidden condition={activeStep !== 2 || i !== 2}>
      <Grid
        alignContent='space-between'
        alignItems='center'
        container
        justify='space-between'
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography className={classes.desc} variant='subtitle2'>
            <Text id={`${rootIntl}.step3.desc`} />
          </Typography>
        </Grid>
        <Grid item>
          <Completed height={200} width='100%' />
        </Grid>
        <Grid item xs={12}>
          <FinishButton />
        </Grid>
      </Grid>
      </Hidden>
    </Step>
  ));

  const stepper = (
    <Stepper
      activeStep={activeStep}
      className={classes.stepper}
      orientation='vertical'
    >
      {renderedSteps}
    </Stepper>
  );

  return (
    <Page>
      <Helmet title={formatMessage({ id: 'activate.title' })} />
      <Section className={classes.title}>
        <Container>
          <Grid item xs={12}>
            <Typography align='center' variant='h3'>
              <Text id={`${rootIntl}.title`} />
            </Typography>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container alignItems='center' spacing={4}>
          <Grid item md={6} xs={12} style={{ textAlign: 'center' }}>
            <img
              alt='WSB Mobile App on Mobile Device'
              className={classes.imgFeature}
              src={phoneInHand}
              width='65%'
            />
            <Typography gutterBottom variant='h5'>
              <Text id={`${rootIntl}.feature.title`} />
            </Typography>
            <Typography gutterBottom variant='body2'>
              <Text id={`${rootIntl}.feature.desc`} />
            </Typography>
            <Typography className={classes.caption} variant='caption'>
              <Text id={`${rootIntl}.feature.caption`} />
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            {stepper}
          </Grid>
        </Container>
      </Section>
    </Page>
  );
};