import React, { useRef, useState } from 'react';
import Async, { useAsync } from 'react-async';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getAuthenticationStatus, getUserByName, deferPostAuthentication } from 'api';
import {
  getCookie,
  PendingTransaction,
  setCookie,
  TransactionStatus,
  poll,
  deleteAuthSession
} from 'components';

import HomeLogin from './HomeLogin';

export default function HomeLoginWrapper() {
  const history = useHistory();
  const [form, setForm] = useState({});
  const poller = useRef(null);
  const [traditionalError, setTraditionalError] = useState('');
  const [transactionResponse, setTransactionResponse] = useState({});
  const { formatMessage } = useIntl();
  const rootIntl = 'home.login';
  
  const authentication_id = getCookie('authentication_id');
  const authOverride = getCookie('sign_in_method');

  let throttleOn = false;

  const { data: auth } = useAsync({
    authentication_id,
    promiseFn: authentication_id !== '' && authOverride !== 'traditional'
      ? getAuthenticationStatus 
      : null,
  });

  const handleCancelByUser = () => {
    stopPoller();
    setTransactionResponse({});
  };

  const handleChange = (event, setError) => {
    event.preventDefault();
    setError(null);

    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleLogout = (event) => {
    event.preventDefault();
    deleteAuthSession();
  };

  const handleNoAuth = (response={}) => {
    if (throttleOn) return;
    
    stopPoller();
    setTransactionResponse(response);
  };

  const handleNoAuthConfirm = () => {
    setTransactionResponse({});
  };
  
  const handleSignIn = (event, run) => {
    event.preventDefault();

    const username = form.cc_username;
    
    run({
      postOperationContext: {
        title: formatMessage({ id: `${rootIntl}.postOpTitle`}),
        content: formatMessage({ id: `${rootIntl}.postOpContent`}),
      },
      preOperationContext: {
        title: formatMessage({ id: `${rootIntl}.preOpTitle`}),
        content: formatMessage({ id: `${rootIntl}.preOpContent`}),
      },
      username,
    });
  };

  const handleSignInSuccess = async (result) => {
    const { authentication_id } = result.data;
    const username = form.cc_username;

    throttleOn = false;
    poller.current = await poll({
      authentication_id,
      promiseFn: getAuthenticationStatus,
      onResponse: {
        notAuthenticated: (res) => handleNoAuth(res),
        success: () => {
          setCookie('authentication_id', authentication_id);
          history.push('/bank/accounts', { authentication_id });
        },
      },
    });

    setCookie('sign_in_method', 'crosschannel');
    setCookie('username', username);
    setTransactionResponse({});
  };

  const handleTraditionalSignIn = async (event) => {
    event.preventDefault();
    const { username } = form;
    
    try {
      const user = await getUserByName({ username });

      if (user && user.devices && user.devices.length > 0) {
        return setTraditionalError(formatMessage({
          id: `${rootIntl}.traditionalError1`,
        }));
      }

      setCookie('sign_in_method', 'traditional');
      setCookie('username', username);
      history.push('/bank/accounts');
    } catch {
      return setTraditionalError(formatMessage({
        id: `${rootIntl}.traditionalError2`,
      }));
    }
  };

  const stopPoller = () => {
    poller && poller.current && poller.current.cancel();
    poller.current = null;
    throttleOn = true;
  };

  return (
    <>
      <Async
        deferFn={deferPostAuthentication}
        onResolve={handleSignInSuccess}
      >
        {(data) => (
          <HomeLogin
            data={data}
            error={data.error}
            form={form}
            isAuth={auth || authOverride === 'traditional'}
            onChange={(event) => handleChange(event, data.setError)}
            onLogout={handleLogout}
            onSubmit={(event) => handleSignIn(event, data.run, data.error)}
            onTraditionalSubmit={handleTraditionalSignIn}
            traditionalError={traditionalError}
          />
        )}
      </Async>
      <PendingTransaction
        action={<Text id={`${rootIntl}.cancel`} />}
        message={<Text id={`${rootIntl}.pending.desc`} />}
        onClick={handleCancelByUser}
        open={!!poller.current}
        title={<Text id={`${rootIntl}.pending.title`} />}
      />
      <TransactionStatus
        onClick={handleNoAuthConfirm}
        response={transactionResponse}
        rootIntl={rootIntl}
      />
    </>
  );
};