import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'components';

// Bank 
import BankAccountsConfig from 'views/Bank/Accounts';
import BankActivateConfig from 'views/Bank/Activate';
import BankHomeConfig from 'views/Bank/Home';
import BankSendConfig from 'views/Bank/Send';

// Landing
import LandingConfig from 'views/Landing';

// Login
import LoginConfig from 'views/Login';

// Other
import NotFoundConfig from 'views/NotFound';

// Call Center
import SupportDashboardConfig from 'views/Support/Dashboard';
import SupportPeopleConfig from 'views/Support/People';
import SupportPersonConfig from 'views/Support/Person';

const manifest = {
  views: [
    BankAccountsConfig,
    BankActivateConfig,
    BankHomeConfig,
    BankSendConfig,
    LandingConfig,
    LoginConfig,
    SupportDashboardConfig,
    SupportPeopleConfig,
    SupportPersonConfig,
    NotFoundConfig, // order matters here
  ],
};

ReactDOM.render(<App manifest={manifest} />, document.getElementById('root'));