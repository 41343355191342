import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Drawer as Drwr, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing, zIndex }) => {
  const DRAWER_WIDTH = 300;

  return {
    drawer: {
      position: 'relative',
      width: 0,
      zIndex: zIndex.appBar - 1,
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
    },
    listItem: {
      paddingLeft: spacing(3.5),
    },
    paper: {
      height: '100%',
      minHeight: 'calc(100vh - 92px)',
      width: DRAWER_WIDTH,
    },
  };
});

export default function Drawer({ openState }) {
  const [open, setOpen] = openState;
  const classes = useStyles({ open });
  const location = useLocation();

  const onClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      icon: ['fad', 'university'],
      title: 'Dashboard',
      to: '/bank/call-center',
    },
    {
      icon: ['fad', 'users'],
      title: 'Users',
      to: '/bank/call-center/people',
    },
    {
      disabled: true,
      icon: ['fad', 'stream'],
      title: 'Queue',
      to: location.pathname,
    },
  ].map(({ icon, title, ...other }) => {
    return (
      <ListItem
        button
        className={classes.listItem}
        component={Link}
        key={title}
        onClick={onClose}
        {...other}
      >
        <ListItemIcon>
          <SvgIcon>
            <FontAwesomeIcon icon={icon} />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  });

  return (
    <Drwr
      className={clsx(classes.drawer, { [classes.drawerOpen]: open })}
      PaperProps={{ classes: { root: classes.paper }}}
      onClose={onClose}
      open={open}
      variant='persistent'
    >
      <Toolbar variant='dense' />
      <Toolbar variant='dense' />
      <List>{menuItems}</List>
    </Drwr>
  );
};