import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Logo from 'assets/images/wsb-header-new.png';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    image: {
      marginRight: spacing(3),
    },
    title: {
      color: ({ color }) => color !== 'default'
        ? palette[color].main
        : palette.common.white,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
    },
  };
});

export function BankLogo({ color='default', path='/bank' }) {
  const classes = useStyles({ color });

  return (
    <Link component={RouterLink} to={path} underline='none'>
      <div className={classes.wrapper}>
        <img
          alt='logo'
          className={classes.image}
          src={Logo}
          height={40}
        />
      </div>
    </Link>
  );
};

export default BankLogo;