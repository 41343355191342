import React from 'react';

import { AppBar, Grid, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BankLogo, DemoMenu } from 'components';

const useStyles = makeStyles(({ palette }) => {
  return {
    actions: {
      flex: 1,
      textAlign: 'right',
    },
    icon: {
      color: palette.common.white,
    },
    input: {
      color: palette.common.white,
      '& fieldset': {
        border: 'none',
        outline: 'none',
      }
    },
    logoWrapper: {
      flex: 1,
    },
    toolbar: {
      margin: '0 auto',
      width: '100%',
    },
  };
});

export default function NavBar({ themeState }) {
  const classes = useStyles();

  return (
    <AppBar elevation={0} position='relative'>
      <Toolbar className={classes.toolbar} variant='dense'>
        <Grid alignItems='center' container>
          <Grid className={classes.logoWrapper} item>
            <BankLogo path='/bank/call-center' />
          </Grid>
          <Grid className={classes.actions} item>
            <DemoMenu themeState={themeState} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}