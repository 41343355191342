import Send from './Send';
import { BankLayout } from 'components';

export const config = {
  entrypoint: Send,
  id: 'send',
  layout: BankLayout,
  location: { pathname: '/bank/send' },
  private: true,
};

export default config;