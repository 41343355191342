import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';

import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import {confirmPassword, Dialog, Fields, forgotPasswordConstraints, passwordConstraints} from 'components';
import validate from "validate.js";

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    error: {
      fontSize: 14,
      marginLeft: spacing(2),
      paddingBottom: spacing(2),
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
        fontSize: 16
      },
      marginBottom: spacing(1),
      fontSize: 16
    },
  }
    ;
});

export function PasswordCodeVerificationDialog({ setSnackbar, openState, username }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const rootIntl = 'passCodeDialog';
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [open, setOpen] = openState;

  const fields = [
    { name: 'code', value: form.code || '' },
    { name: 'password', value: form.password || '', helperText: 'Password must be at least 8 character long', type: 'password' },
  ].map((field, i) => {
    const error = errors[field.name] && errors[field.name][0];

    return {
      ...field,
      autoFocus: i === 0,
      error: !!error,
      helperText: error || field.helperText || ' ',
      label: <Text id={`${rootIntl}.fields.${i}.label`}/>,
      placeholder: formatMessage({ id: `${rootIntl}.fields.${i}.placeholder` }),
      required: true,
      type: field.type || 'input',
    };
  });

  const handleAccept = () => {
    /* ValidateJS does not support async/await */
    validate.async(form, forgotPasswordConstraints)
        .then(async (attributes) => {
          handleValidationSuccess();
        })
        .catch((errors) => {
          handleValidationFailure(errors);
        });
  };

  const handleValidationSuccess = () => {

    showSnackbarMessage(formatMessage({ id: `${rootIntl}.adding` }, form));

    confirmPassword(username, form.code, form.password)
        .then(() => {
          showSnackbarMessage(
              formatMessage({ id: `${rootIntl}.success` }, form),
              'success');

          setOpen(false);
          setForm({});

        }).catch(err => {

      let formMessage;
      if (err.code === "CodeMismatchException") {
        formMessage = formatMessage({ id: `${rootIntl}.codeMismatchError` }, form)
        setForm({});
      } else {
        formMessage = formatMessage({ id: `${rootIntl}.error`}, form)
      }

      showSnackbarMessage(
          formMessage,
          'error');

    });

  };

  const handleValidationFailure = (errors) => {
    setErrors(errors);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: null });
  };


  const handleViewClose = () => {
    //WHat to do then??
    setErrors({});
    setForm({});
    setOpen(false);
  };

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
                  message,
                  open: true,
                  severity: severity,
                });
  };

  return (
    <Dialog
      accept={{
        name: <Text id={`${rootIntl}.add`}/>,
        onClick: handleAccept
      }}
      decline={{
        name: <Text id={`${rootIntl}.cancel`}/>,
        onClick: handleViewClose
      }}
      maxWidth='sm'
      open={!!open}
      title={<Text id={`${rootIntl}.title`}/>}
    >
      <Fields
        className={classes.field}
        edit={true}
        onChange={handleChange}
      >
        {fields.map((field, i) => (
          <Field key={i} {...field} />
        ))}

      </Fields>
      <Text id={`${rootIntl}.message`}/>
    </Dialog>
  );
};

export default PasswordCodeVerificationDialog;
