import React from 'react';

import { MenuItem } from '@material-ui/core';

export function DemoMenuItem({ item, onClose }) {
  const { onClick, ...other } = item;

  const handleItemClick = () => {
    onClick && onClick();
    onClose();
  };

  return <MenuItem dense onClick={handleItemClick} {...other} />;
};

export default DemoMenuItem;