import React, { useState } from 'react';

import { Menu } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

export function MenuButton({
  IconProps,
  items,
  title,
  TooltipProps,
  open=false,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const statefulTitle = open ? 'Close menu' : 'Open menu';

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const menuItems = React.Children.map(items, (item) => {
    const { onClick, ...rest } = item.props;
    return React.cloneElement(item, {
      button: true,
      onClick: () => {
        handleClose();
        !!onClick && onClick();
      },
      ...rest,
    });
  });

  return (
    <>
      <BaseButton
        Icon={FontAwesomeIcon}
        IconProps={{
          icon: ['fas', 'ellipsis-v'],
          ...IconProps,
        }}
        onClick={handleOpen}
        TooltipProps={{ title: statefulTitle, ...TooltipProps }}
        type='fa'
        {...rest}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default MenuButton;