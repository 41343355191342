import React, { useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import filter from 'lodash.filter';

import PrivateRoute from '../ProtectedRoute/PrivateRoute';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import PublicRoute from "../ProtectedRoute/PublicRoute";

export function Routes({portalLoggedIn, authFn, manifest, redirect}) {
    const {views} = manifest;
    const darkState = useState(false);

    const routeKey = (path, i) => path ? path.slice(1) : i;

    const enabledRoutes = filter(views, (route) => {
        return !(
            typeof route.featureFlags !== 'undefined'
            && route.featureFlags.disabled
        );
    });

  const routes = [];

  enabledRoutes.forEach((route, i) => {
    const {
      entrypoint: Component, exact=true, id, layout: Layout, layoutProps, 
      location, private: isPrivate,
    } = route;

        const routeProps = {
            exact: exact,
            key: routeKey(id, i),
            path: location ? location.pathname : null,
            render: (props) => {
                return Layout ? (
                    <Layout
                        isPrivate={isPrivate}
                        darkState={darkState}
                        {...props}
                        {...layoutProps}
                    >
                        <Component {...props} />
                    </Layout>
                ) : <Component {...props} />;
            },
        };

        const loginPage = route.id === "login";
        const directRoute = !!loginPage ?  <PublicRoute portalLoggedIn={portalLoggedIn} {...routeProps} /> :
                            !!route.private ? <PrivateRoute portalLoggedIn={portalLoggedIn} authFn={authFn} redirect={redirect} {...routeProps} /> :
                            <ProtectedRoute  portalLoggedIn={portalLoggedIn} {...routeProps} />;

        routes.push(directRoute);

        if (route.pathAliases && route.pathAliases.length > 0) {
            route.pathAliases.forEach((alias) => {
                routes.push(
                    <Route key={alias} exact path={alias} render={() => (
                        <Redirect to={route.location.pathname}/>
                    )}/>
                );
            });
        }
    });

    return (
        <BrowserRouter>
            <Switch>
                {routes}
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;