import React from 'react';
import clsx from 'clsx';

import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => {
  return {
    button: {
      '& .svg-inline--fa:hover': {
        color: palette.secondary.main,
      },
    },
  };
});

export function BaseButton({ Icon, IconProps, TooltipProps, type, ...rest }) {
  const classes = useStyles();
  
  return (
    <Tooltip {...TooltipProps}>
      <IconButton className={clsx(classes.button, rest.className)} {...rest}>
        <Icon {...IconProps} />
      </IconButton>
    </Tooltip>
  );
};

export default BaseButton;