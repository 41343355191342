/** @module CookieHelpers */

/**
 * Expire a single browser cookie thereby deleting it
 * @param {string} cname - cookie name
 */
export function expireCookie(cname) {
  const d = new Date();
  d.setTime(d.getTime() - (1 * 24 * 60 * 60 * 1000));

  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "= ;" + expires + ";path=/";
};

/**
 * Retrive a single browser cookie by name
 * @param {string} cname - cookie name
 */
export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * Create or update a single browser cookie by name with value
 * and set an expiration time
 * @function
 * @param {string} cname - cookie name
 * @param {string} cvalue - cookie value
 * @param {string} exdays - days until automatic expiration
 */
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

};