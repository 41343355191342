import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

export function ExpandButton({ IconProps, title, TooltipProps, open=false, ...rest }) {
  const statefulTitle = open ? 'Collapse all' : 'Expand all';

  return (
    <BaseButton
      Icon={FontAwesomeIcon}
      IconProps={{
        icon: ['fas', 'layer-group'],
        ...IconProps,
      }}
      TooltipProps={{ title: statefulTitle, ...TooltipProps }}
      type='fa'
      {...rest}
    />
  );
};

export default ExpandButton;