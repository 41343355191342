import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import Drawer from '../Drawer';
import NavBar from '../NavBar';
import SubBar from '../SubBar';

const useStyles = makeStyles(({ zIndex }) => {
  return {
    nav: {
      position: 'sticky',
      top: 0,
      zIndex: zIndex.drawer + 1,
    },
  };
});

export default function SupportLayoutNav({ SubBarProps, themeState }) {
  const classes = useStyles();
  const openState = useState(false);

  return (
    <nav className={classes.nav}>
      <NavBar themeState={themeState} />
      <SubBar openState={openState} {...SubBarProps} />
      <Drawer openState={openState} />
    </nav>
  );
};