import React from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { makeStyles } from '@material-ui/styles';

import { getUserBankAccounts } from 'api';
import { AsyncContent, AsyncRecord, numberFormatter, SupportHeading } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    container: {
      padding: spacing(4),
    },
    negative: {
      color: palette.error.main,
    }
  };
});

export default function PersonDetails({ username }) {
  const classes = useStyles();
  const { format } = numberFormatter;
  const rootIntl = 'support.person.details';

  const FulfilledContent = ({ data }) => {
    const getDollars = (val) => {
      if (val < 0) return (
        <span className={classes.negative}>{`-${format(Math.abs(val))}`}</span>
      );

      return format(val);
    }
    
    const modifyData = (data) => {
      return data.map(({ available, current, credits, debits, ...other}) => {


        return {
          available: getDollars(available),
          current: getDollars(current),
          credits: getDollars(credits),
          debits: getDollars(debits),
          ...other,
        };
      });
    };

    const relIntl = `${rootIntl}.headers`;

    return (
      <AsyncRecord
        data={modifyData(data)}
        mapDataToHeaders={{
          title: <Text id={`${relIntl}.0`} />,
          accountNumber: <Text id={`${relIntl}.1`} />,
          available: <Text id={`${relIntl}.2`} />,
          current: <Text id={`${relIntl}.3`} />,
          credits: <Text id={`${relIntl}.4`} />,
          debits: <Text id={`${relIntl}.5`} />,
        }}
      />
    );
  };

  return (
    <div className={classes.container}>
      <SupportHeading title={<Text id={`${rootIntl}.title`} />} />
      <AsyncContent promiseFn={getUserBankAccounts} username={username}>
        <FulfilledContent />
      </AsyncContent>
    </div>
  )
};