import React from 'react';
import {IntlProvider} from 'react-intl';

import {ThemeProvider} from '@material-ui/styles';

import {getAuthenticationStatus} from 'api';
import {getCookie, getTheme, getToken, Routes} from 'components';
import getIntlConfig from 'i8n';

export function App({manifest}) {
    /**
     *
     * @param {object} location - Location object used primarily for app state
     * @description getAuth checks whether the authentication_id is passed in
     * via location state (e.g., right after login and before cookie is set)
     * or fetches the cookie to verify authentication
     */
    const getAuth = async () => {
        const authentication_id = getCookie('authentication_id');
        const override = getCookie('sign_in_method') === 'traditional';

        if (override) {
            return true;
        }

        if (typeof authentication_id === 'undefined' || authentication_id === '') {
            return false;
        }

        const response = await getAuthenticationStatus({authentication_id});
        return response.data.status === 'SUCCESS';
    };

    const getLoggedIn = async () => {
        return await getToken().then(function () {
            return true;
        }).catch((error) => {
            return false;
        });
    };

    const ManifestContext = React.createContext(manifest);
    const ManifestProvider = ManifestContext.Provider;

    return (
        <ManifestProvider value={manifest}>

            <ThemeProvider theme={getTheme(false)}>
                <IntlProvider {...getIntlConfig()}>
                    <Routes
                        portalLoggedIn={getLoggedIn}
                        authFn={getAuth}
                        manifest={manifest}
                        redirect={{
                            to: (location) => {
                                const splitPath = location.pathname.split('/');
                                return splitPath.length >= 2 ? `/${splitPath[1]}` : '/';
                            }
                        }}
                    />
                </IntlProvider>
            </ThemeProvider>

        </ManifestProvider>
    );
};

export default App;