import React, { forwardRef, useState } from 'react';
import { FormattedMessage as Text } from 'react-intl';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton, ListItemIcon, Menu, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DemoAlert, logout } from 'components';
import DemoMenuItem from './DemoMenuItem';
import UserAddDialog from './UserAddDialog';
import UserDeleteDialog from './UserDeleteDialog';
import ChangeAppConfigDialog from "./ChangeAppConfigDialog";
import { getAppConfigList, getCurrentAppConfig } from "api";

const useStyles = makeStyles(({ palette }) => {
  return {
    icon: {
      color: palette.common.white,
    },
    iconListItem: {
      minWidth: 30,
    }
  };
});

export function DemoMenu({ themeState = [] }) {
  const classes = useStyles();
  const location = useLocation();
  const rootIntl = 'navigation.navbar.demoMenu';
  const [anchorEl, setAnchorEl] = useState();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showChangeAppConfigDialog, setShowChangeAppConfigDialog] = useState(false);
  const [currentConfig, setCurrentConfig] = useState('');
  const [configList, setConfigList] = useState([]);
  const [snackbar, setSnackbar] = useState({});
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleAdd = () => {
    setShowAddDialog(true);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  }

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleLogout = () => {
    logout();
    history.push('/login');
  }

  const handleRunbook = () => {
      window.open(
          'http://demodocs.encapsecurity.com/',
          '_blank'
      );
  }

  const handleChangeAppConfig = async () => {
    await getCurrentAppConfig().then((value) => setCurrentConfig(value));
    await getAppConfigList().then((list) => setConfigList(list.data));
    setShowChangeAppConfigDialog(true);
  }

  const specificItems = {
    bank: [
      location.pathname.split('/')[2] === 'call-center'
      ? {
          component: RouterLink,
          icon: ['fad', 'university'],
          text: <Text id={`${rootIntl}.externalSite`}/>,
          to: '/bank',
          target: "_blank",
          rel: "noreferrer"
        } : {
          component: RouterLink,
          icon: ['fad', 'user-headset'],
          text: <Text id={`${rootIntl}.callCenter`}/>,
          to: '/bank/call-center',
          target: "_blank",
          rel: "noreferrer"
        },
    ],
  };


    const items = [
    {
      icon: ['fad', 'plus'],
      onClick: handleAdd,
      text: <Text id={`${rootIntl}.addUser`}/>,
    },
    {
      icon: ['fad', 'trash'],
      onClick: handleDelete,
      text: <Text id={`${rootIntl}.removeUser`}/>,
    },
    ...specificItems[location.pathname.split('/')[1]],
    {
      icon: ['far', 'user-circle'],
      text: <Text id={`${rootIntl}.changeAppConfig`}/>,
      onClick: handleChangeAppConfig,
    },
    {
        component: RouterLink,
        icon: ['fad', 'rocket'],
        text: <Text id={`${rootIntl}.demoLanding`}/>,
        to: '/',
    },
      {
      icon: ['fad', 'sign-out'],
      text: <Text id={`${rootIntl}.logoutPortalUser`}/>,
      onClick: handleLogout,
    },
      {
        icon: ['far', 'question-circle'],
        text: <Text id={`${rootIntl}.runbook`}/>,
        onClick: handleRunbook,
      },
    ].map(({ icon, text, ...other }) => ({
    children: (
      <>
        <ListItemIcon className={classes.iconListItem}>
          <FontAwesomeIcon icon={icon}/>
        </ListItemIcon>
        <Typography>{text}</Typography>
      </>
    ),
    ...other,
  }));

  const MenuItems = forwardRef((_, ref) => items.map((item, i) => (
    <DemoMenuItem item={item} key={i} onClose={handleClose} forwardRef={ref}/>
  )));

  return (
    <>
      <Tooltip title={open ?
                      <Text id={`${rootIntl}.closeMenu`}/> :
                      <Text id={`${rootIntl}.openMenu`}/>}
      >
        <IconButton onClick={handleClick}>
          <SvgIcon>
            <FontAwesomeIcon
              className={classes.icon}
              icon={['fad', 'ellipsis-v-alt']}
            />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        getContentAnchorEl={null}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItems/>
      </Menu>
      <UserAddDialog
        setSnackbar={setSnackbar}
        openState={[showAddDialog, setShowAddDialog]}
      />
      <UserDeleteDialog
        setSnackbar={setSnackbar}
        openState={[showDeleteDialog, setShowDeleteDialog]}
      />
      <ChangeAppConfigDialog
          setSnackbar={setSnackbar}
          openState={[showChangeAppConfigDialog, setShowChangeAppConfigDialog]}
          currentConfig={currentConfig}
          configList={configList}
      />
      <DemoAlert
        actionText={`${rootIntl}.statusConfirmed`}
        onClick={handleCloseSnackbar}
        onClose={handleCloseSnackbar}
        severity={snackbar.severity}
        SnackbarProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
        }}
        {...snackbar}
      >
        {snackbar.message}
      </DemoAlert>
    </>
  );
};

export default DemoMenu;
