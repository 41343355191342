import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';

import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { postUser } from 'api';
import { Dialog, Fields, userConstraints } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    error: {
      fontSize: 14,
      marginLeft: spacing(2),
      paddingBottom: spacing(2),
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
      },
      marginBottom: spacing(1),
      fontSize: 16
    },
  };
});

export function UserAddDialog({ setSnackbar, openState }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const rootIntl = 'addDialog';

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [open, setOpen] = openState;

  const fields = [
    { name: 'userId', value: form.userId || '' },
    { name: 'firstName', value: form.firstName || '' },
    { name: 'lastName', value: form.lastName || '' },
  ].map((field, i) => {
    const error = errors[field.name] && errors[field.name][0];

    return {
      ...field,
      autoFocus: i === 0,
      error: !!error,
      helperText: error || field.helperText || ' ',
      label: <Text id={`${rootIntl}.fields.${i}.label`} />,
      placeholder: formatMessage({ id: `${rootIntl}.fields.${i}.placeholder` }),
      required: true,
    };
  });

  const handleAccept = () => {
    /* ValidateJS does not support async/await */
    validate.async(form, userConstraints)
      .then(async (attributes) => {
        handleValidationSuccess();
      })
      .catch((errors) => {
        handleValidationFailure(errors);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const handleValidationSuccess = async () => {
    showSnackbarMessage(
      formatMessage({ id: `${rootIntl}.adding` }, form), 'info');

      try {
        await postUser(form);

        const peopleListPath = '/bank/call-center/people';
        if (location.pathname === peopleListPath) {
          window.location.reload();
        }
      }
      catch (exception) {
        showSnackbarMessage(
          formatMessage({ id: `${rootIntl}.error` }, form),
          'error');
      }

    showSnackbarMessage(
      formatMessage({ id: `${rootIntl}.success` }, form),
      'success');

    setOpen(false);
    setForm({});
    setErrors({});
  };

  const handleValidationFailure = (errors) => {
    setErrors(errors);
  }

  const handleViewClose = () => {
    setErrors({});
    setForm({});
    setOpen(false);
  };

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
      message,
      open: true,
      severity: severity,
    });
  };

  return (
    <Dialog
      accept={{
        name: <Text id={`${rootIntl}.add`} />,
        onClick: handleAccept
      }}
      decline={{
        name: <Text id={`${rootIntl}.cancel`} />,
        onClick: handleViewClose
      }}
      maxWidth='sm'
      open={!!open}
      title={<Text id={`${rootIntl}.title`} />}
    >

      <Fields
        className={classes.field}
        edit={true}
        onChange={handleChange}
      >
        {fields.map((field, i) => (
          <Field key={i} {...field} />
        ))}
      </Fields>
    </Dialog>
  );
};

export default UserAddDialog;
