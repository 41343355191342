/** @module TimeHelpers */

import moment from 'moment';

/**
 * Formats time for use across UIs.
 * TODO: Update function for different localizations
 * @param {(Date|string)} val - the value to format
 * @returns {string} the newly formatted time string 
 */
export function formatTime(val) {
  return moment(val).format('MMM D, YYYY, h:mm A [GMT]Z');
};

/**
 * Used to delay the executing async function from which this is called
 * @param {number} ms - the time in milliseconds, e.g., 3000 = 3 seconds
 * @returns {Promise} resolves after the passed in "ms" 
 */
export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};