import React, { useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { FormattedMessage as Text } from 'react-intl';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Link, Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TransactionStatus } from 'components';
import { ReactComponent as NotFound } from '../../../assets/images/not-found.svg';
import PersonCallFlow from './PersonCallFlow';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    field: {
      marginBottom: spacing(2),
    },
    notActivated: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      borderTop: 'none',
      height: '100%',
      [breakpoints.up('sm')]: {
        height: '100%',
        minHeight: 'calc(100vh - 92px)',
        overflowY: 'scroll',
      },
      [breakpoints.down('xs')]: {
        borderBottom: `1px solid ${palette.grey[isDark ? 900 : 200]}`,
        borderTop: `1px solid ${palette.grey[isDark ? 900 : 200]}`,
      },
    },
    title: {
      marginBottom: 16,
      padding: spacing(3, 3, 0, 3),
    },
  };
});

export default function PersonCallFlowAside({ isActivated, name }) {
  const classes = useStyles();
  const rootIntl = 'support.person.stepper';
  const [response, setResponse] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [dialog, setDialog] = useState(false);

  const handleAcceptDialog = () => {
    setActiveStep(0);
    setDialog(!dialog);
  };
  const handleDialog = () => setDialog(!dialog);

  const handleNoAuthConfirm = () => {
    setResponse({});
  };

  const NotActivated = () => (
    <div className={classes.notActivated}>
      <Typography className={classes.title} variant='h5'>
        <Text id={`${rootIntl}.notActivated.title`} />
      </Typography>
      <div>
        <NotFound height={200} width='100%' />
        <Typography
          align='center'
          className={classes.title}
          component='div'
        >
          <Text
            id={`${rootIntl}.notActivated.desc`}
            values={{
              l: (val) => (
                <Link component={RRLink} to='/bank/activate'>{val}</Link>
              ),
            }}
          />
        </Typography>
      </div>
    </div>
  );

  const HangUpDialog = () =>  (
    <Dialog onClose={handleDialog} open={dialog}>
      <DialogTitle>
        <Text id={`${rootIntl}.dialog.title`} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Text id={`${rootIntl}.dialog.text`} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color='primary'>
          <Text id={`${rootIntl}.dialog.cancel`} />
        </Button>
        <Button onClick={handleAcceptDialog} color='primary'>
          <Text id={`${rootIntl}.dialog.accept`} />
        </Button>
      </DialogActions>
    </Dialog>
  );


  return (
    <>
      <Paper className={classes.paper}>
        {isActivated ? (
          <PersonCallFlow
            activeStep={activeStep}
            handleActiveStep={setActiveStep}
            handleDialog={setDialog}
            handleResponse={setResponse}
            name={name}
          />
        ) : <NotActivated />}
      </Paper>
      <HangUpDialog />
      <TransactionStatus
        onClick={handleNoAuthConfirm}
        response={response}
        rootIntl='support.person.log'
      />
    </>
  );
};
