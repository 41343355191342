import React from 'react';
import { Fade } from '@material-ui/core';

export function Page({ children, timeout=750, ...rest }) {
  return (
    <article {...rest}>
      <Fade in timeout={timeout}>
        <div>
          {children}
        </div>
      </Fade>
    </article>
  );
};

export default Page;