import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppBar, IconButton, SvgIcon, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    appBar: {
      backgroundColor: palette.background.paper,
      borderBottom: `1px solid ${isDark ? `${palette.grey[400]}20` : palette.grey[300]}`,
      boxShadow: 'none',
    },
    iconButton: {
      marginLeft: spacing(-1),
    },
    subtitle: {
      flex: 1,
      marginLeft: spacing(2.25),
    },
    toolbar: {
      maxHeight: 44,
      minHeight: 44,
    }
  }
});

export default function SubBar({ actions, openState }) {
  const classes = useStyles();
  const [open, setOpen] = openState;

  return (
    <AppBar
      className={classes.appBar}
      color='default'
      elevation={1}
      position='relative'
    >
      <Toolbar className={classes.toolbar} variant='dense'>
        <Tooltip title={`${open ? 'Close' : 'Open'} menu`}>
          <IconButton
            className={classes.iconButton}
            edge='start'
            onClick={() => setOpen(!open)}
          >
            <SvgIcon>
              <FontAwesomeIcon icon={['fal', 'bars']} />
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Typography className={classes.subtitle} variant='subtitle1'>
          Call Center Support Portal
        </Typography>
      </Toolbar>
    </AppBar>
  );
};