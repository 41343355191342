import React from 'react';
import Async from 'react-async';

import Error from '../Error';
import Loader from '../Loader';

export function AsyncContent({ children, errorMap, ...other}) {
  if (!children) return null;

  const getCustomError = (error) => {
    const customError = errorMap && errorMap[error.statusCode];
    return customError ? customError : error;
  };

  return (
    <Async {...other}>
      <Async.Pending initial>
        <Loader />
      </Async.Pending>
      <Async.Rejected>
        {(error, state) => (
          <Error centered error={getCustomError(error)} { ...state } />
        )}
      </Async.Rejected>
      <Async.Fulfilled persist>
        {(data, state) => React.cloneElement(children, { data, ...state })}
      </Async.Fulfilled>
    </Async>
  );
};

export default AsyncContent;