import Landing from './Landing';
import { LandingLayout } from 'components';

export const config = {
  entrypoint: Landing,
  id: 'landing',
  layout: LandingLayout,
  location: { pathname: '/' },
  private: false
};

export default config;