/** @module EventHelpers */
import JSZip from 'jszip';

export const FILE_TYPES = {
  TEXT: 'txt',
  ZIP: 'zip',
};

/**
 * Creates a downloadable file for the user to save/open
 * @param {byte[]} data - bytes to place in file download
 * @param {string} filename - name of file that user will see
 * @param {string} type - designate file type ('txt' or 'zip')
 */
export function createDownload(data, filename, type=FILE_TYPES.TEXT) {
  const element = document.createElement('a');
  let href;

  switch(type) {
    case FILE_TYPES.ZIP:
      href = window.URL.createObjectURL(data);
      break;
    case FILE_TYPES.TEXT:
    default:
      href = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
      break;
  }

  element.setAttribute('href', href);
  element.setAttribute('download', filename);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Zip the provided files
 * @param {byte[]} files - list of {{string} filename, {byte[]} data}
 */
export async function createZip(files) {
  const zip = new JSZip();
  files.forEach((item) => { zip.file(item.filename, item.data); });

  return await zip.generateAsync({ type: 'blob' });
};

/**
 * Maps keyboard events to specified events
 * @param {number} accessor - key code to access shortcuts (eg. shift key)
 * @param {Object} keybindings - { keyCode: eventFn }
 * @param {Object} history -
 * @param {Object} state -
 */
export function createShortcuts(accessor, keybindings, history, state) {
  if (!keybindings) return;

  const navigate = (event) => {
    event.preventDefault();
    if (!accessor(event)) return;

    const shortcut = keybindings[event.keyCode];

    if (typeof shortcut !== 'undefined') {
      shortcut({ event, history, state });
      return document.removeEventListener('keyup', navigate);
    }
  };

  document.addEventListener('keyup', navigate);
};

export function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

/**
 * @typedef PollObject
 * @type {Object}
 * @property {Promise} promiseFn - the function to poll
 * @property {Object} onResponse - map of functions to call based on response
 * @property {number} [n=1000] - polling interval
 * @property {...Object} args - arguments to pass into promiseFn
 */

/**
 * Polls a promise function (such as an API call)
 * @async
 * @param {PollObject}
 * @returns {Object} interval information and functions to act on interval
 */
export async function poll({ promiseFn, onResponse, timeout=1000, ...args }) {

  const id = setInterval(async () => {
    const res = await promiseFn(args);

    switch (res.data.status) {
      case 'CANCELLED_BY_DEVICE':
      case 'CANCELLED_BY_SP':
      case 'EXPIRED':
      case 'LOCKED_PIN_VERIFICATION_FAILED':
        clearInterval(id);
        return await onResponse.notAuthenticated(res);
      case 'SUCCESS':
        clearInterval(id);
        return await onResponse.success(res);
      default:
        break;
    }
  }, timeout);

  return {
    cancel: () => clearInterval(id),
    intervalId: id,
  };
};

/**
 * Copies text to operating system clipboard
 * @param {string} text - value to copy to clipboard
 * @returns {Promise}
 */
export async function updateClipboard(text) {
  return await navigator.clipboard.writeText(text);
};