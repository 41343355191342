const statusTips = {
  ACTIVATION_CODE_CREATION_FAILED: 'There was a problem generating a unique activation code.',
  ALREADY_REGISTERED: 'The user\'s device was already registered.',
  AUTH_LEVEL_NOT_ALLOWED: 'The authentication level requested was not supported by the application configuration.',
  AUTH_LEVEL_NOT_AVAILABLE_FOR_DEVICE: 'The requested authentication level was not available on the user\'s device.',
  AUTH_METHOD_AND_AUTH_LEVEL_MISMATCH: 'The requested authentication level did not match the authentication method.',
  AUTH_METHOD_NOT_ACTIVATED_FOR_DEVICE: 'The requested authentication method was not available on the user\'s device.',
  AUTH_METHOD_NOT_ALLOWED: 'The requested authentication method was not supported by the application configuration.',
  CALLBACK_FAILED: 'There was a problem sending a callback to the service provider.',
  CANCELLED_AUTH_METHOD_DEACTIVATED: 'The transaction was cancelled as the user\'s device no longer allows the authentication method.',
  CANCELLED_BY_DEVICE: 'The user cancelled the transaction from his or her device.',
  CANCELLED_BY_SP: 'The service provider cancelled the transaction.',
  EXPIRED: 'The transaction expired.',
  EXPIRY_TIME_EXCEEDS_MAXIMUM: 'The transaction expiry time was higher than the maximum allowed by the server.',
  FAILED: 'The transaction has timed out or is in a failure state.',
  ILLEGAL_REQUEST: 'The transaction did not correctly include the required parameters.',
  IN_PROGRESS: 'The transaction is currently being sent to the user and awaiting authorization.',
  INVALID_OTP: 'The one-time password was not correct.',
  LOCKED: 'The user\'s device is locked.',
  LOCKED_BY_ADMIN: 'The user\'s device was locked by the service provider.',
  LOCKED_DEVICE_VERIFICATION_FAILED: 'The user\'s device was locked due to authentication key validation failure.',
  LOCKED_HW_KEY_VERIFICATION_FAILED: 'The user\'s device was locked due to hardware key signature verification failure.',
  LOCKED_INCORRECT_SALT_KEY_ID: 'The user\'s device was locked due to salt encryption key ID mismatch.',
  LOCKED_PIN_VERIFICATION_FAILED: 'The user\'s device was locked due to excessive incorrect PIN attempts.',
  MISSING_APPLICATION_ID: 'The transaction was missing an application id.',
  NO_SESSION: 'The transaction session was not provided or no longer exists.',
  NOT_ACTIVATED: 'The user\'s device was not yet fully activated.',
  NOT_AUTHENTICATED: 'The transaction session was not yet authenticated by the user\'s device.',
  NOT_REGISTERED: 'The user\'s device is not registered.',
  NOT_SIGNED: 'The user\'s device had not completed generation of the signature.',
  NOT_SUPPORTED: 'The operation was not supported.',
  NOT_VALID: 'Verification failed.',
  OTHER: 'The state of the transaction cannot be matched.',
  PINLESS_AUTHENTICATION_NOT_SUPPORTED: 'PINless authentication not supported by the user\'s device.',
  SUCCESS: 'The transaction was successfully approved by the user.',
  TOO_MANY_ACTIVATION_SESSIONS_IN_PROGRESS: 'Too many activation sessions were in progress.',
  UNEXPECTED: 'An unexpected condition caused the transaction to fail.',
};

const accounts = {
  accts: {
    actions: [
      'Send money',
      'Transfer money',
      'View statements',
      'Manage alerts'
    ],
    balances: [
      '<available-balance>',
      'Available balance',
      '<current-balance>',
      'Current balance',
      '<pending-credits>',
      'Pending credits',
      '<pending-debits>',
      'Pending debits'
    ],
    dialog: {
      accept: 'Activate Device',
      cancel: 'Cancel',
      desc: `
        In order to send money you must first increase the security on your
        account. To do so, please activate a device for your account using the
        WSB mobile app.
      `,
      title: `Device Must Be Activated`,
    },
    none: 'There was a problem loading your account',
    title: 'My accounts',
  },
  activate: {
    action: 'Learn more',
    download: `Download the WSB mobile app to speed up and secure transactions`,
  },
  activity: {
    action: 'See all activity',
    none: 'No recent activity',
    title: 'Recent activity',
  },
  myInfo: {
    title: 'My info',
  },
  title: 'Accounts',
};

const activate = {
  buttonBack: `Back`,
  buttonFinish: `Login with your activated device`,
  buttonNext: `Next`,
  buttonReset: `Reset`,
  feature: {
    caption: `No identifying information leaves your device.`,
    desc: `
      Activating your device with the WSB Mobile App secures and
      speeds up transactions. Using bank-grade security, it ensures your
      device is not compromised and biometrically authenticates you.
    `,
    title: `No more passwords, no more hassle.`,
  },
  step1: {
    title: `Download our mobile app`,
    desc: `The WSB Mobile App is available for download from the App Store and
      Google Play.`,
  },
  step2: {
    imgAppStore: 'Download from App Store',
    imgGooglePlay: 'Download from Google Play',
    content: `
      Using the WSB Mobile App, scan the QR code below or enter the activation
      code {code} to continue the activation process.
    `,
    expired: `Activation code expires in `,
    title: `Register your device`,
  },
  step3: {
    desc: `
      You have successfully activated and registered using the WSB Mobile App!
      Click finish to log in with your new authentication method.
    `,
    title: `Complete activation`,
  },
  title: 'Activate your device',
};

const addDialog = {
  cancel: 'Cancel',
  add: 'Add',
  adding: 'Adding user "{userId}"...',
  error: 'There was a problem adding user "{userId}".',
  exists: '"{userId}" already exists.',
  fields: [
    { label: 'Username', placeholder: 'Type your username...' },
    { label: 'First name', placeholder: 'Type your first name...' },
    { label: 'Last name', placeholder: 'Type your last name...' },
  ],
  success: 'Successfully added user {userId}.',
  title: 'Add Demo User',
};

const changePasswordDialog = {
  cancel: 'Cancel',
  add: 'Update password',
  adding: 'Updating password ...',
  error: 'There was a problem updating the password.',
  policyError: 'There was a problem with the new password.',
  limitError: 'You have tried to update you password to many times. Please try after some time',
  authError: 'There was a problem updating the password, verify that you are using the correct current password.',
  fields: [
    { label: 'Current password', placeholder: 'Type your current password...' },
    { label: 'New password', placeholder: 'Type your new password...' },
  ],
  success: 'Password is updated.',
  title: 'Update password',
};

const changeNameDialog = {
  cancel: 'Cancel',
  add: 'Update name',
  adding: 'Updating name ...',
  error: 'There was a problem updating the name.',
  fields: [
    { label: 'First Name', placeholder: 'Type your first name...' },
    { label: 'Last Name', placeholder: 'Type your last name...' },
  ],
  success: 'Name is updated.',
  title: 'Update name',
};

const changeAppConfigDialog = {
  cancel: 'Cancel',
  add: 'Change',
  error: 'There was a problem updating the app config.',
  success: 'App Config is changed.',
  title: 'Change App Config',
  current: 'Current App Config: ',
  change: 'Change to: ',
};

const signUpDialog = {
  cancel: 'Cancel',
  add: 'Sign up!',
  adding: 'Creating your user ...',
  error: 'There was a problem creating your user.',
  usernameExistsError: 'Your email is already registered. Try lo login, or reset your password.',
  fields: [
    { label: 'First Name', placeholder: 'Type your first name...' },
    { label: 'Last Name', placeholder: 'Type your last name...' },
    { label: 'Email', placeholder: 'Type your email...'},
    { label: 'New password', placeholder: 'Type your new password...' },
  ],
  success: 'Your user is created! Once yo have verified your email you can login.',
  title: 'Sign up',
};

const codeDialog = {
  cancel: 'Cancel',
  add: 'Confirm email',
  adding: 'Verifying your email ...',
  error: 'There was a problem verifying your email.',
  codeMismatchError: 'Incorrect verification code, please try again',
  fields: [
    { label: 'Verification code', placeholder: 'Type your verification code...' },
  ],
  success: 'Your email is verified and you can now login!',
  title: 'Verify email',
  message: 'Please check your email to get the code, (it might end up in spam/junk)'
};

const forgotPassDialog = {
  cancel: 'Cancel',
  add: 'Reset password',
  adding: 'Resetting your password...',
  error: 'There was a problem resetting',
  fields: [
    { label: 'Email', placeholder: 'Type your email...'},
  ],
  success: 'We have sent you a verification code to your email.',
  title: 'Forgot password',
};

const passCodeDialog = {
  cancel: 'Cancel',
  add: 'Reset password',
  adding: 'Resetting your password...',
  error: 'There was a problem resetting',
  codeMismatchError: 'Incorrect verification code, please try again',
  fields: [
    { label: 'Verification code', placeholder: 'Type your verification code...' },
    { label: 'New password', placeholder: 'Type your new password...' },
  ],
  success: 'We have sent you a verification code to your email.',
  title: 'Forgot password',
  message: 'Please check your email to get the code, (it might end up in spam/junk)'
};

const loginForm = {
  error: 'Log in failed. Try again, if you are not able to log in contact support',
  userNotConfirmedError: 'You have not confirmed your email',
  statusConfirmed: 'Okay',
};

const deleteDialog = {
  blank: 'Username must be provided',
  cancel: 'Cancel',
  desc: `
    Remove a user from the demo experience by entering the name of an
    existing user in the input below.
  `,
  deleting: 'Deleting user "{userId}"...',
  deletingAll: 'Deleting all users...',
  error: 'There was a problem deleting user "{userId}".',
  errorAll: 'There was a problem deleting all users.',
  notFound: 'Unable to find user "{userId}".',
  remove: 'Delete',
  success: 'Successfully deleted user "{userId}".',
  successAll: 'Successfully deleted all user.',
  title: 'Remove Demo User',
  deleteAll: 'Remove All Demo Users',
};

const home = {
  blurbs: {
    items: [
      {
        action: 'Open Account',
        content: `
          Online Banking offers you a quick and convenient way to manage your
          finances, pay bills and keep up to date with your account when and
          wherever you need it.
        `,
        title: 'Online Banking',
      },
      {
        action: 'Learn more',
        content: `
          WSB has you covered with low payments and premiums for all insurance
          types including home, auto, renter, life, and travel.
        `,
        title: 'Get Insured',
      },
    ],
  },
  community: {
    content: `
      We’re the most awesome bank in the world, because we help our community
      do cool and significant things.
    `,
    imageAlt: 'Serving our community',
    title: 'Serving our community',
  },
  intro: {
    action: 'Get Started',
    items: [
      { text: 'Earn reward points' },
      { text: 'Take control of your debt' },
      { text: 'Explore college debt options' },
      { text: 'Check current rates' },
      { text: 'Find account or routing numbers' },
    ],
    title: `Find the card that's right for you.`
  },
  login: {
    backupOption: 'Log in with username and password',
    cancel: 'Cancel log in request',
    statusConfirmed: 'Ok',
    statusTips: {
      ...statusTips,
      CANCELLED_BY_DEVICE: `The log in request was canceled from your WSB Mobile App.`,
      EXPIRED: `The log in request has expired.`,
      LOCKED_PIN_VERIFICATION_FAILED: `Your device has been locked after too many failed authentication attempts.`,
      UNEXPECTED: 'There was an unexpected error in handling this transaction',
    },
    fields: [
      { label: 'Username', placeholder: 'Enter your username...' },
      { label: 'Username', placeholder: 'Enter your username...' },
      { label: 'Password', placeholder: 'Enter your password...' },
    ],
    forgot: 'Forgot your <pw>password</pw>?',
    goToAccount: 'Go to your account',
    login: 'Log in',
    logout: 'Log out',
    orSignOut: 'Or <a>sign out</a>',
    pending: {
      desc: 'Have your mobile device ready to log in to your account',
      title: 'Connecting to your mobile device',
    },
    postOpTitle: 'Log in completed!',
    postOpContent: 'Web log in was approved with WSB Mobile App.',
    preOpTitle: 'Log in request',
    preOpContent: 'Do you want to log in to the WSB web portal?',
    signedIn: `You're already signed in!`,
    signIn: 'Log in',
    title: 'Log in with your WSB Mobile App',
    traditionalError1: 'You must use the WSB Mobile App to sign in.',
    traditionalError2: 'Username / password combination failed.',
    unenrolled1: `Don't have the WSB Mobile App yet?`,
    unenrolled2: `<dl>Download</dl> it right away`,
    welcome: `Welcome to WSB!`
  },
};

const shared = {
  notFound: {
    action: 'Leave now and never come back',
    desc: `
      We seem to have misplaced this page — that or maybe it never
      really existed.
    `,
    title: 'Whoops!',
  },
};

const navigation = {
  navbar: {
    menu: ['Customer Service', 'ATMs / Locations'],
    demoMenu: {
      addUser: 'Add Demo User',
      callCenter: 'Call Center',
      closeMenu: 'Close demo menu',
      darkTheme: 'Dark Theme',
      demoLanding: 'Demo portal',
      externalSite: 'Bank website',
      lightTheme: 'Light Theme',
      openMenu: 'Open demo menu',
      removeUser: 'Remove Demo User',
      statusConfirmed: 'Okay',
      logoutPortalUser: "Logout from portal",
      changePassPortalUser: "Change password",
      addNamePortalUser: "Change name",
      changeAppConfig: "Change App Config",
      runbook: "Help - Runbooks",
    },
  },
  subbar: {
    private: {
      primary: ['Accounts', 'Investments', 'Pay & Transfer'],
      secondary: ['Logout', 'About WSB'],
    },
    public: {
      primary: ['Personal', 'Small Business', 'Commerical'],
      secondary: ['About WSB'],
    },
  },
};

const send = {
  backText: 'Return to accounts',
  graphic: 'Send money quickly and securely — all you need is your WSB mobile app.',
  resetText: 'Send more money',
  statusConfirmed: 'Okay',
  statusTips: {
    ...statusTips,
    CANCELLED_BY_DEVICE: `The transfer request was canceled from your WSB Mobile App`,
    EXPIRED: 'The transaction expired.',
    LOCKED: `The transfer request cannot be completed because your device is locked.`,
    LOCKED_PIN_VERIFICATION_FAILED: `Your device has been locked after too many failed authentication attempts.`,
    REST_REGISTRATION_IS_LOCKED_PIN_VERIFICATION_FAILED: `Your device has been locked after too many failed authentication attempts.`,
    UNEXPECTED: 'There was an unexpected error in handling this transaction',
  },
  steps: [
    {
      fields: [
        { label: 'From:', secondary: 'Available balance:' },
        { label: 'To:', secondary: 'Account#:' },
        { label: 'Amount:', placeholder: 'Specify an amount to send...' },
        {
          label: 'Frequency:',
          options: [
            'One time, immediately',
            'Bimonthly (1st & 15th of each month)',
            'Weekly (each Monday)',
          ],
        },
      ],
      nextText: 'Next',
      title: 'Fill in transfer details'
    },
    {
      authorizing: 'Awaiting authorization...',
      cancelText: 'Cancel',
      connection: `Connecting to {name}'s {device}`,
      instructions: 'Please check your WSB Mobile App and follow the ' +
        'instructions to authorize the transfer.',
      postOpContextTitle: `Transfer completed!`,
      postOpContextContent: `Transfer approved for {amt} to {name}`,
      preOpContextTitle: `Send money to {name}`,
      preOpContextContent: `Do you want to approve transfer for {amt} to {name}`,
      title: 'Authorize transaction from your WSB Mobile App'
    },
    {
      fields: [
        { label: 'From:' },
        { label: 'To:' },
        { label: 'Amount:' },
      ],
      successText: 'Your transfer was completed successfully!',
      title: 'Completed transfer'
    },
  ],
  title: 'Send Money',
};

const support = {
  title: 'Customer Service Portal',
  dashboard: {
    title: 'WSB Call Center Support Portal',
    tagline: 'Above and Beyond the Call',
    heading: 'What are you working on today?',
    items: [{ action: 'Search our users', name: 'Users' }],
  },
  people: {
    filter: {
      label: 'Filter customers',
      placeholder: 'Type the customer name or email...',
    },
    headers: [
      'Name', 'User ID', 'Phone', 'Street Address', 'City', 'State',
      'Postal Code',
    ],
  },
  person: {
    details: {
      title: 'Customer Accounts',
      headers: [
        'Name', 'Account #', 'Available Balance', 'Current Balance',
        'Pending Credits', 'Pending Debits',
      ],
    },
    info: {
      title: 'Customer Information',
      fields: [
        'First Name', 'Last Name', 'User ID', 'Phone', 'Street Address',
        'City', 'State',
      ]
    },
    log: {
      title: 'Device Activity',
      attributes: {
        authentication_level: 'Authentication Level',
        device_id: 'Device ID',
        device_manufacturer: 'Device Manufacturuer',
        device_model: 'Device Model',
        is_secure_screen_lock_enabled: 'Is Secure Screen Lock Enabled',
        is_root_available: 'Is Root Available',
        location: {
          accuracy: 'Accuracy',
          altitude: 'Altitude',
          latitude: 'Latitude',
          longitude: 'Longitude',
        },
        operating_system_type: 'Operating System Type',
        operating_system_version: 'Operating System Version',
        post_operation_context: {
          context_content_b64: 'Post-Operation Message',
          context_title_b64: 'Post-Operation Title',
        },
        pre_operation_context: {
          context_content_b64: 'Pre-Operation Message',
          context_title_b64: 'Pre-Operation Title',
        },
        purpose: 'Purpose',
        session_status: {
          description: 'Description',
          status: 'Status',
        },
      },
      checklist: {
        title: 'Security Checklist',
        action: 'View security checklist',
        desc: `
          A list of the security measures taken to validate, verify, and
          approve the request.
        `,
        auth: {
          title: 'Authentication Factors',
          bio: '[ARE] User biometric validated',
          device: '[HAVE] User device validated',
          pin: '[KNOW] User PIN validated',
        },
        consent: {
          title: 'User Consent',
          userApproval: 'User approved transaction context',
        },
        crypto: {
          title: 'Cryptographic Integrity',
          e2eTunnel: 'Application Layer End-to-End Tunnel',
          dynamicKey: 'Dynamic key validated',
          hardwareKey: 'Hardware key validated',
        },
        device: {
          title: 'Device Authenticity',
          appAttest: 'App attestation validated',
          cloneDetect: 'Clone detection',
        },
        risk: {
          title: 'Risk Data Verification',
          clientIP: 'Client IP Address',
          debugStatus: 'Debug status',
          location: 'Location data',
          rootDetect: 'Root and jailbreak detection',
        },
      },
      expand: {
        action: 'Expand data',
        deviceTitle: 'Device Information',
        contextTitle: 'Context Information',
        locationAction: 'See on map',
        locationTitle: 'Location',
      },
      notApplicable: 'Not applicable',
      deviceNotFound: 'No activated devices',
      headers: [
        'Date & Time', 'Device Model', 'Status', 'Purpose', 'Context', 'GPS Coordinates',
      ],
      statusConfirmed: 'Okay',
      statusTips,
    },
    stepper: {
      actions: {
        cancel: 'Cancel',
        done: 'Complete',
        next: 'Next',
        prev: 'Back',
      },
      dialog: {
        accept: 'Hang up',
        cancel: 'Cancel',
        title: 'Complete Call?',
        text: `
          Ensure you've asked "Is there anything else that I can help you
          with?". If there isn't, you can complete the call and hang up.
        `,
      },
      notActivated: {
        desc: `
          There were no activated devices found for the current user. Please
          ask the user to <l>activate a device</l>.
        `,
        title: `Device Activation Needed`,
      },
      steps: [
        {
          name: `
            At this point, you should already have the caller's information,
            so confirm their name and phone number by repeating it back to them.
          `,
        },
        {
          fields: [
            {
              label: 'Request message (seen by the caller)',
              placeholder: 'Type in a contextual message...'
            },
          ],
          name: `
            Inform the caller that "I'm going to send an authentication push
            request to your WSB Mobile App. Please approve the request once
            received."
          `,
        },
        {
          awaiting: 'Awaiting authentication...',
          desc: `
            Remind the caller to check his or her WSB Mobile App and follow the
            instructions to authenticate.
          `,
          name: `Send the request to the WSB Mobile App on their device.`,
          postOpContextTitle: `Authentication completed!`,
          postOpContextContent: `Successfully authenticated for `,
          preOpContextTitle: `WSB Call Center request`,
          preOpContextContentOptions: [
            `Confirm identity for WSB Support`,
            `Change beneficiary for WSB account`,
            `Request replacement card for WSB`,
            `Open investment account with WSB`,
          ],
          preOpContextContentTooltip: [
            `Switch to predefined messages`,
            `Switch to custom message`,
          ],
          sending: 'Sending request...',
          title: `Connecting to {name}'s mobile device`,
        },
        {
          name: `They are now authenticated through the WSB Mobile App!`,
          title: `
            Please verify that you have his or her approval to proceed
            and that you're here to help them with whatever they need.
          `,
        },
        {
          name: `
            Pick from the list below that most closely resembles the caller's
            issue.
          `,
          options: [
            'Account Issues',
            'Lost or stolen card',
            'Payments & Transfers',
            'Open or Close Account',
            'Request checks',
            'Other',
          ],
          title: 'What are they needing help with?',
        },
      ],
      title: 'Call steps',
    },
  },
};

const allTranslations = {
  accounts,
  activate,
  addDialog,
  deleteDialog,
  home,
  navigation,
  send,
  shared,
  support,
  changePasswordDialog,
  changeNameDialog,
  changeAppConfigDialog,
  signUpDialog,
  codeDialog,
  loginForm,
  forgotPassDialog,
  passCodeDialog
};

export default allTranslations;
