import React from 'react';

import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SupportLayoutNav from './SupportLayoutNav';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      backgroundColor: palette.grey[200],
      height: 'calc(100vh - 48px)',
      margin: '0 auto',
    },
  };
});

export default function SupportLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <SupportLayoutNav />
      <main className={classes.root}>
        {children}
      </main>
    </>
  );
};