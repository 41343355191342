import React from 'react';
import LoginForm from './LoginForm';
import {Page} from 'components';

export function Login() {

    return (
        <Page>
                <LoginForm/>
        </Page>
    );
};

export default Login;

