import React from 'react';
import { Helmet } from 'react-helmet';

import { CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

import { ScrollToTop } from 'components';
import WsbFavicon from 'assets/images/wsb-favicon.ico';

import '../icons';
import Footer from '../Footer';
import NavBar from '../NavBar';
import SubBar from '../SubBar';
import getTheme from '../theme';

const MAX_WIDTH = 1400;
const PADDING = 3;

const useStyles = makeStyles(() => {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: '0 auto',
      maxWidth: MAX_WIDTH,
      minHeight: 'calc(100vh - 160px)', // height of footer and nav bars
      padding: `0 ${PADDING * 8}`,
    },
  };
});

export function Layout({ children, isPrivate, darkState }) {
  const classes = useStyles();
  const [isDark, setIsDark] = darkState;

  const title = 'West SpringField Bank';

  return (
    <ScrollToTop>
      <ThemeProvider theme={getTheme(isDark)}>
        <Helmet defaultTitle={title} titleTemplate={`${title} | %s`}>
          <meta name='description' content={`${title} Demo Home Page`} />
          <link rel='shortcut icon' href={WsbFavicon} />
        </Helmet>
        <CssBaseline />
        <NavBar
          padding={PADDING}
          width={MAX_WIDTH}
          themeState={[isDark, setIsDark]}
        />
        <SubBar
          isPrivate={isPrivate}
          padding={PADDING}
          width={MAX_WIDTH}
        />
        <main className={classes.main}>
          {children}
        </main>
        <Footer />
      </ThemeProvider>
    </ScrollToTop>
  );
};

export default Layout;