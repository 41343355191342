import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import arrows from 'assets/images/arrow.gif';
import logo from 'assets/images/wsb-large-logo.png';
import logoMobile from 'assets/images/logo-mobile.png';

const useStyles = makeStyles(({ palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    arrows: {
      marginLeft: spacing(8),
      marginBottom: spacing(4),
    },
    bank: {
      color: palette.primary.main,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    cancel: {
      position: 'absolute',
      right: 20,
      top: 10,
    },
    icon: {
      '--fa-primary-color': palette.background.paper,
      '--fa-primary-opacity': 1,
      '--fa-secondary-color': palette.grey[isDark ? 500 : 500],
      '--fa-secondary-opacity': 1,
    },
    paper: {
      boxSizing: 'content-box',
      padding: spacing(3,8,0,8),
    },
    title: {
      color: palette.primary.main,
      fontWeight: 500,
    },
    transmission: {
      '& > div': {
        textAlign: 'center'
      },
      overflowY: 'hidden',
    }
  };
});

export default function HomeLoginPending({ action, desc, onClick, open, title }) {
  const classes = useStyles();

  return (
    <Dialog
      closeAfterTransition
      open={open}
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography align='center' className={classes.title} variant='h4'>
          {title}
          <Tooltip className={classes.cancel} title={action}>
            <IconButton onClick={onClick} variant='outlined'>
              <FontAwesomeIcon icon={['fal', 'times']} />
            </IconButton>
          </Tooltip>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          alignItems='center'
          className={classes.transmission}
          container
          justify='center'
          spacing={3}
        >
          <Grid item md={4}>
            <img alt='phone app' height={200} src={logo} />
          </Grid>
          <Grid item md={4}>
            <img
              alt='connecting'
              className={classes.arrows}
              height={40}
              src={arrows}
            />
          </Grid>
          <Grid item md={4}>
            <img alt='phone app' height={200} src={logoMobile} />
          </Grid>
          <Grid item xs={12}>
            <Typography align='center'>
              {desc}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};