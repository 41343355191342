import Home from './Home';
import { BankLayout } from 'components';

export const config = {
  entrypoint: Home,
  id: 'home',
  layout: BankLayout,
  location: { pathname: '/bank' },
  private: false,
};

export default config;