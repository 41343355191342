import React, { useState } from 'react';
import clsx from 'clsx';
import omit from 'lodash.omit';

import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import VisibilityAdornment from '../VisibilityAdornment';
import FieldCurrency from './FieldCurrency';

const useStyles = makeStyles(({ palette }) => {
  return {
    disabled: {
      color: palette.text.primary,
    },
    field: {
      '& fieldset': {
        transition: 'all .75s ease-in-out',
      },
    },
    input: {
      fontSize: 12,
    },
    read: {
      '& fieldset': {
        border: '1px solid rgba(0,0,0,0) !important',
        transition: 'all .75s ease-in-out',
      },
    },
  };
});

/**
 * @function Fields
 * @param fields - an array of Field components with overrides and extends
 * @param increment - increment is used to ensure keys are not the same
 **/
export function Fields({
  children,
  edit=false,
  errors={},
  helperTexts={},
  InputProps,
  InputLabelProps,
  ...rest
}) {
  const classes = useStyles();
  const [visible, setVisible] = useState({});
  errors = (Object.entries(errors).length === 0
    && errors.constructor === Object)
    ? helperTexts : errors;

  return React.Children.map(children, (child, i) => {
    const {
      className, isCurrency, InputProps: ChildInputProps,
      InputLabelProps: ChildInputLabelProps, label, name, type, value, ...other
    } = child.props;

    const endAdornment = type === 'password'
      ? <VisibilityAdornment name={name} visibleState={[visible, setVisible]} />
      : null;

    const placeholder = edit && label
      ? `Type in the ${typeof label === 'string' ? label.toLowerCase() : label}...`
      : 'not provided';

    const fieldProps = {
      className: clsx(classes.field, { [classes.read]: !edit }, className),
      disabled: !edit,
      helperText: helperTexts[name],
      error: !!errors[name],
      fullWidth: true,
      InputProps: {
        className: classes.input, classes: { disabled: classes.disabled },
        endAdornment,
        inputComponent: isCurrency ? FieldCurrency : undefined,
        ...(InputProps || ChildInputProps),
      },
      InputLabelProps: {
        shrink: true,
        ...(InputLabelProps || ChildInputLabelProps)
      },
      key: label,
      label,
      margin: 'dense',
      placeholder,
      value: value || '',
      variant: 'outlined',
      type: visible[name] && type === 'password' ? 'text' : type,
      ...rest,
      ...other,
    };

    return isCurrency
      ? <Field {...omit(fieldProps, ['isCurrency'])} />
      : React.cloneElement(child, fieldProps);
  });
};

export default Fields;
