import find from 'lodash.find';
import findIndex from 'lodash.findindex';

import { getAsyncLocalStorageItem, setAsyncLocalStorageItem, getCookie, setCookie } from 'components';

const BANK_ACCOUNTS_STORAGE_ID = 'bank-accounts';
const BANK_ACTIVITY_STORAGE_ID = 'bank-activity';

/**
 * Stores demo bank account data to local storage 
 * @async
 * @returns {Promise} ensures data is actually stored
 */
export async function getUserBankAccounts() {
  const bankAccounts = [
    {
      accountNumber: '5678901234',
      available: 10000,
      credits: 72.91,
      current: 797.71,
      debits: -275.20,
      icon: ['fad', 'money-check'],
      title: 'Primary Checking',
    },
    {
      accountNumber: '1234567890',
      available: 50000,
      credits: 0,
      current: 50000,
      debits: 0,
      icon: ['fad', 'treasure-chest'],
      title: 'Primary Savings',
    },
  ];

  return JSON.parse(
    await getAsyncLocalStorageItem(
      BANK_ACCOUNTS_STORAGE_ID, 
      { set: JSON.stringify(bankAccounts) 
    })
  );
};

/**
 * An object containing Demo Bank Account properties
 * @typedef {Object} BankAccountObject
 * @property {number} accountNumber - account to update
 * @property {number} amount - amount to subtract from account balance
 */

/**
 * @summary Updates demo bank account data within local storage 
 * @desc NOTE: Currently simulates a bank transfer and subtracts from account balance
 * @async
 * @param {BankAccountObject} Account - the account to be updated
 * @returns {Promise} ensures data is actually updated
 */
export async function updateUserBankAccounts({ accountNumber, amount }) {
  const data = JSON.parse(await getAsyncLocalStorageItem(BANK_ACCOUNTS_STORAGE_ID));
  const accountIndex = findIndex(data, { accountNumber });
  const account = find(data, { accountNumber });

  data.splice(accountIndex, 1, {
    ...account,
    available: account.available - amount,
    current: account.current - amount,
  });

  await setAsyncLocalStorageItem(
    BANK_ACCOUNTS_STORAGE_ID,
    JSON.stringify(data)
  );

  return true;
};

/**
 * Stores demo bank activity data to local storage 
 * @async
 * @returns {Promise} ensures data is actually stored
 */
export async function getUserBankActivity() {
  const bankActivity = [
    {
      createdAt: '2019-11-12T00:00:00',
      cost: '$22.56',
      isPending: true,
      name: 'SQ *QUATTRO GATTI',
    },
    {
      createdAt: '2019-11-11T00:00:00',
      cost: '$6.43',
      isPending: true,
      name: 'SQ *MEDICI',
    },
    {
      createdAt: '2019-11-10T00:00:00',
      cost: '$6.19',
      isPending: false,
      name: 'STARBUCKS *DOMAIN',
    },
    {
      createdAt: '2019-11-10T00:00:00',
      cost: '$103.27',
      isPending: false,
      name: 'TARGET',
    },
    {
      createdAt: '2019-11-10T00:00:00',
      cost: '$63.84',
      isPending: false,
      name: 'APPLE.COM/BILL',
    },
  ];

  return JSON.parse(
    await getAsyncLocalStorageItem(
      BANK_ACTIVITY_STORAGE_ID, 
      { set: JSON.stringify(bankActivity) 
    })
  );
};

/**
 * Retrieves demo user information
 * @async
 * @returns {Object} user information
 */
export async function getUser() {
  return {
    username: `${getCookie('username')}`,
    name: JSON.parse(getCookie('name')),
  };
};

/**
 * Sets demo user information
 * @async
 * @returns {Object} user information
 */
export async function setUser(user) {
  return setCookie('name', JSON.stringify({ 
    first: user.form.firstName,
    last: user.form.lastName,
  }));
};

/**
 * Retrieves demo user "saved" recipients
 * @async
 * @returns {Object[]} recipients information information
 */
export async function getSavedRecipients() {
  return [
    {
      accountNumber: '5014369287',
      name: 'John Doe'
    },
    {
      accountNumber: '5870921436',
      name: 'Jane Doe'
    },
  ];
};

/**
 * Single call to retrieve send information upon Send view load
 * @async
 * @returns {Object} - send view information
 */
export async function getSendDetails() {
  return {
    recipients: await getSavedRecipients(),
    userAccounts: await getUserBankAccounts(),
  };
};
