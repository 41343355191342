import React from 'react';

import { InputAdornment } from '@material-ui/core';

import { VisibleButton } from '../Button';

export function VisibilityAdornment({
  IconButtonProps,
  name,
  visibleState,
  ...rest
}) {
  const [isVisible, setIsVisible] = visibleState;

  const handleClick = (event) => event.preventDefault();
  const handleMouseDown = () => setIsVisible({ ...isVisible, [name]: !isVisible[name] });

  return (
    <InputAdornment position='end' {...rest}>
      <VisibleButton
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        visible={isVisible[name]}
      />
    </InputAdornment>
  );
};

export default VisibilityAdornment;