import Accounts from './Accounts';
import { BankLayout } from 'components';

export const config = {
  entrypoint: Accounts,
  id: 'home',
  layout: BankLayout,
  location: { pathname: '/bank/accounts' },
  private: true,
};

export default config;