import React from 'react';
import Async from 'react-async';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage as Text } from 'react-intl';

import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { numberFormatter } from 'components';
import { updateUserBankAccounts } from 'api';

const useStyles = makeStyles(({ spacing }) => {
  return {
    button: {
      marginRight: spacing(2),
      marginTop: spacing(3),
    },
    container: {
      padding: spacing(2),
    },
    fields: {
      marginRight: spacing(2),
    },
  };
});

export function SendMoneyStepComplete({ form={}, onReset }) {
  const classes = useStyles();
  const rootIntl = 'send.steps.2';
  const { amount, from={}, to={} } = form;
  const { format } = numberFormatter;

  const ResolvedContent = () => (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Typography gutterBottom variant='subtitle1'>
          <Text id={`${rootIntl}.successText`} />
        </Typography>
      </Grid>
      <Grid className={classes.fields} item>
        {[0, 1, 2].map((item) => (
          <Typography align='right' key={item} variant='body2'>
            <b><Text id={`${rootIntl}.fields.${item}.label`} /></b>
          </Typography>
        ))}
      </Grid>
      <Grid item>
        {[from.title, to.name, format(amount)].map((item, i) => (
          <Typography key={i} variant='body2'>{item}</Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          color='primary'
          onClick={onReset}
          variant='contained'
        >
          <Text id='send.resetText' />
        </Button>
        <Button
          className={classes.button}
          color='secondary'
          component={RouterLink}
          to='/bank/accounts'
          variant='contained'
        >
          <Text id='send.backText' />
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Async
      accountNumber={from.accountNumber}
      amount={amount}
      promiseFn={updateUserBankAccounts}
    >
      <Async.Rejected>
        {(error) => <div>{`There was an error: ${error}`}</div>}
      </Async.Rejected>
      <Async.Resolved>
        {() => <ResolvedContent />}
      </Async.Resolved>
    </Async>
  );
};

export default SendMoneyStepComplete;