import validate from 'validate.js';
import { getUserByName } from 'api';

validate.validators.checkUserIdExists = async function(userId) {
  try {
    if (userId) {
      await getUserByName({ username: userId });
    }
  } catch (error) {
    throw new Error(error.status === 404 ? 'deleteDialog.notFound' : error);
  }
};