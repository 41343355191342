import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

export function SaveButton({ IconProps, title='Save', TooltipProps, ...rest }) {
  return (
    <BaseButton
      Icon={FontAwesomeIcon}
      IconProps={{
        icon: ['fal', 'save'],
        ...IconProps,
      }}
      TooltipProps={{ title, ...TooltipProps }}
      type='fal'
      {...rest}
    />
  );
};

export default SaveButton;