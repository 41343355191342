import { makeStyles } from "@material-ui/styles";
import {AppBar, Button, Grid, IconButton, SvgIcon, TextField} from "@material-ui/core";
import {authenticate, DemoAlert, getCognitoUser, Page} from "../../components";
import Logo from "../../assets/images/Signicat_negative.png";
import React, { useState } from "react";
import {useHistory} from "react-router-dom";
import {SignUpDialog} from "./SignUpDialog";
import CodeVerificationDialog from "./CodeVerificationDialog";
import {useIntl} from "react-intl";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import PasswordCodeVerificationDialog from "./PasswordCodeVerificationDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    button: {
      marginBottom: spacing(2),
    },
    loginButton: {
        marginBottom: spacing(2),
        backgroundColor: '#41FFD1',
        color: `#2A0062`
    },
    linkButton: {
        backgroundColor: 'transparent',
        color: '#41FFD1',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        fontSize: 14,
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
      },
      marginBottom: spacing(2.5),
      '& label.Mui-focused': {
          color: palette.secondary.dark,
          backgroundColor: palette.background.paper,
      },
    },
    container: {
      margin: '0 auto',
      maxWidth: 1920,
      minHeight: '100vh',
      padding: spacing(8),
    },
    imgContainer: {
      textAlign: 'center',
      marginBottom: spacing(2.5),
    },
    imgLogo: {
       maxWidth: '90%',
    },
    textContainer: {
        textAlign: 'center',
        color: palette.secondary.light,
        padding: spacing(4),
    },
    regularIcon: {
        color: palette.secondary.light,
    },
  };
});

export function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [snackbar, setSnackbar] = useState({});
  const [user, setUser] = useState({});
  const [username, setUsername] = useState({});

  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
  const [showConfirmPasswordDialog, setShowConfirmPasswordDialog] = useState(false);

  const { formatMessage } = useIntl();
  const rootIntl = 'loginForm';
  const history = useHistory();

  const onSubmit = event => {
    event.preventDefault();

    authenticate(email, password)
      .then(function () {
        history.push('/');
      })
      .catch(err => {

          setPassword('');

          let message;
          if (err.code === "UserNotConfirmedException") {
              message = formatMessage({id: `${rootIntl}.userNotConfirmedError`})
              handleEmailNotConfirmed()
          } else {
              message = formatMessage({id: `${rootIntl}.error`})
          }

          showSnackbarMessage(
              message,
              'error');

      });
  };

  const classes = useStyles();

  const login =
    <form onSubmit={onSubmit}>
      <TextField
        className={classes.field}
        fullWidth
        label="Email"
        name='email'
        onChange={event => setEmail(event.target.value)}
        required
        size='small'
        value={email}
        variant='outlined'
      />
      <TextField
        className={classes.field}
        fullWidth
        label="Password"
        name='password'
        onChange={event => setPassword(event.target.value)}
        required
        size='small'
        type='password'
        value={password}
        variant='outlined'
      />
      <Button
        className={classes.loginButton}
        fullWidth
        type='submit'
        variant='contained'
      >
        Log in
      </Button>
    </form>
  ;

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
                  message,
                  open: true,
                  severity: severity,
                });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  }

  const handleSignUp = () => {
      setEmail('')
      setPassword('')
      setShowSignUpDialog(true)
  }

  const handleEmailNotConfirmed = () => {
    setUser(getCognitoUser(email))
    setShowCodeDialog(true)
  }

    const handleForgotPassword = () => {
        setShowForgotPasswordDialog(true)
    }

    const handleRunbook = () => {
        window.open(
            'http://demodocs.encapsecurity.com/',
            '_blank'
        );
    }

    const helpBar = <AppBar style={{ background: 'transparent', boxShadow: 'none', top: 'auto', bottom: 0  }}>
        <IconButton onClick={handleRunbook} style={{ marginLeft: 'auto' }} >
            <SvgIcon style={{ minWidth: '50', minHeight: '50' }}>
                <FontAwesomeIcon
                    className={classes.regularIcon}
                    icon={['far', 'question-circle']}
                />
            </SvgIcon>
        </IconButton>
    </AppBar>


    return (
    <Page timeout={1500}>
        <SignUpDialog
            setSnackbar={setSnackbar}
            setUser={setUser}
            setShowCodeDialog={setShowCodeDialog}
            openState={[showSignUpDialog, setShowSignUpDialog]}
        />
        <CodeVerificationDialog
            setSnackbar={setSnackbar}
            openState={[showCodeDialog, setShowCodeDialog]}
            user={user}
        />
        <ForgotPasswordDialog
            setSnackbar={setSnackbar}
            setShowCodeDialog={setShowConfirmPasswordDialog}
            openState={[showForgotPasswordDialog, setShowForgotPasswordDialog]}
            setUsername={setUsername}
        />
        <PasswordCodeVerificationDialog
            setSnackbar={setSnackbar}
            openState={[showConfirmPasswordDialog, setShowConfirmPasswordDialog]}
            username={username}
        />

        <Grid
        alignItems='center'
        alignContent='center'
        className={classes.container}
        container
        justify='center'>

        <Grid item lg={6} xs={12}>
          <Grid alignItems='center' container justify='center' spacing={3}>
            <Grid className={classes.imgContainer} item lg xs={12}>
              <img className={classes.imgLogo} alt='Signicat' src={Logo}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Grid container justify='center' spacing={3}>
              {login}
          </Grid>

          <Grid container justify='center' spacing={1}>
            <Grid className={classes.textContainer} container justify='center' spacing={2} >
              <div>
                  <button type='button' className={classes.linkButton} onClick={handleForgotPassword}>Forgot password?</button><br/>
                  Don't have an account? <button type='button' className={classes.linkButton} onClick={handleSignUp}>Sign up!</button><br/>
                  If you have any issues please contact mobile-identity-demo@signicat.com.
              </div>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <DemoAlert
        actionText={`${rootIntl}.statusConfirmed`}
        onClick={handleCloseSnackbar}
        onClose={handleCloseSnackbar}
        severity={snackbar.severity}
        SnackbarProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
        }}
        {...snackbar}
      >
        {snackbar.message}
      </DemoAlert>

        {helpBar}
    </Page>
  );
};

export default LoginForm;