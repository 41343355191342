import React, { useRef } from 'react';
import Async from 'react-async';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getCookie, numberFormatter, sleep } from 'components';
import { getAuthenticationStatus, postAuthentication } from 'api';

const useStyles = makeStyles(({ spacing }) => {
  return {
    container: {
      padding: spacing(2),
    },
  };
});

export function SendMoneyStepAwait({
  form={},
  onCancel,
  onNext,
  recipient={},
  setTransactionResponse,
}) {
  const classes = useStyles();
  const cancel = useRef(null);
  const { format } = numberFormatter;
  const rootIntl = 'send.steps.1';
  const { formatMessage } = useIntl();
  let throttleOn = false;

  const handleAuthenticationFailure = async (error={}) => {
    onCancel();
    setTransactionResponse(error);
  };

  /**
   * Polls the promise function getAuthenticationStatus Waits for the promise to
   * finish before firing a new one.
   */
  const poll = async (authentication_id) => {
    let attempts = 0;

    const onResponse = {
      notAuthenticated: handleNoAuth,
      success: onNext,
    };

    while (attempts < 1000) {
      const result = await getAuthenticationStatus({ authentication_id });
      attempts = attempts + 1;

      if (cancel.current) break;

      switch (result.data.status) {
        case 'CANCELLED_BY_DEVICE':
        case 'EXPIRED':
        case 'LOCKED_PIN_VERIFICATION_FAILED':
        case 'REST_REGISTRATION_IS_LOCKED_PIN_VERIFICATION_FAILED':
          return await onResponse.notAuthenticated(result);
        case 'SUCCESS':
          return await onResponse.success(result);
        default:
          break;
      }

      sleep(1500);
    }
  }

  const handleAuthenticationSuccess = async (result) => {
    const { authentication_id } = result.data;
    throttleOn = false;
    poll(authentication_id);
  };

  const handleCancelClick = () => {
    handleNoAuth();
  };

  const handleNoAuth = (response={}) => {
    if (throttleOn) return;

    stopPoller();
    onCancel();
    setTransactionResponse(response);
  };

  const stopPoller = () => {
    cancel.current = true;
    throttleOn = true;
  };

  const Content = () => (
    <Grid
      alignItems='center'
      className={classes.container}
      container
      spacing={4}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant='subtitle1'>
          <Text
            id={`${rootIntl}.connection`}
            values={{
              name: getCookie('username'),
              device: 'mobile device'
            }}
          />
        </Typography>
        <Typography gutterBottom variant='body2'>
          <Text id={`${rootIntl}.instructions`} />
        </Typography>
      </Grid>
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>
          <Text id={`${rootIntl}.authorizing`} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          color='secondary'
          onClick={handleCancelClick}
          variant='contained'
        >
          <Text id={`${rootIntl}.cancelText`} />
        </Button>
      </Grid>
    </Grid>
  );

  const { name } = recipient;
  const { amount, frequency } = form;

  return (
    <Async
      authenticationLevel={amount >= 100 ? 'TWO_FACTOR' : 'ONE_FACTOR'}
      postOperationContext={{
        title: formatMessage({ id: `${rootIntl}.postOpContextTitle`}),
        content: formatMessage(
          { id: `${rootIntl}.postOpContextContent` },
          { amt: format(amount), name }
        ),
      }}
      preOperationContext={{
        title: formatMessage(
          { id: `${rootIntl}.preOpContextTitle` },
          { name: recipient.name }
        ),
        content: `${formatMessage(
          { id: `${rootIntl}.preOpContextContent` },
          { amt: format(amount), name })} ${frequency.charAt(0).toLowerCase() + frequency.slice(1)}.`
      }}
      promiseFn={postAuthentication}
      username={getCookie('username')}
      onReject={handleAuthenticationFailure}
      onResolve={handleAuthenticationSuccess}
    >
      {() => <Content />}
    </Async>
  );
};

export default SendMoneyStepAwait;