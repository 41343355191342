import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

import BaseButton from './BaseButton';
import { updateClipboard } from '../helpers';

const useStyles = makeStyles(({ spacing }) => {
  return {
    'top-right': {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
    },
  };
}); 

export function CopyButton({
  IconProps,
  onClick,
  position='top-right',
  title=['Copy', 'Copied!'],
  TooltipProps,
  valueToCopy,
  ...rest
}) {
  const classes = useStyles(position);
  const [hover, setHover] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    onClick && onClick(event);
    updateClipboard(valueToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <BaseButton
      className={classes[position]}
      Icon={FontAwesomeIcon}
      IconProps={{
        fontSize: 'sm',
        icon: ['fas', 'copy'],
        ...IconProps,
      }}
      onClick={handleClick}
      size='small'
      TooltipProps={{
        disableHoverListener: true,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        open: hover,
        title: title[isCopied ? 1 : 0],
        ...TooltipProps
      }}
      type='fa'
      {...rest}
    />
  );
};

export default CopyButton;