/** @module SessionHelpers */

import { expireCookie, setCookie } from "./cookies";
import { deleteAsyncLocalStorageItem, setAsyncLocalStorageItem } from "./storage";

/**
 * @typedef CreateSessionObject
 * @type {Object}
 * @property {Array.<Object.<string, string>>} cookies
 * @property {Array.<(Function|Array)>} promiseFns
 * @property {Array.<Object.<string, string>>} storageKeys
 */

/**
 * Simplifies session creation by setting neccessary session cookies,
 * executing promise functions (e.g., token creation via API, analytics, etc.),
 * and setting any local storage values
 * @param {CreateSessionObject} SessionObject
 * @returns {*} - result of promiseFns in order passed in
 */
export async function createSession({ cookies, promiseFns, storageKeys }) {
  const result = [];
  
  if (promiseFns && promiseFns.length > 0) {
    for (const promiseFn of promiseFns) {
      if (Array.isArray(promiseFn)) {
        result.push(await promiseFn[0](promiseFn[1]));
      } else {
        result.push(await promiseFn());
      }
    }
  }
  
  if (cookies && cookies.length > 0) {
    for (const cookie of cookies) {
      setCookie(cookie[0], cookie[1]);
    }
  }

  if (storageKeys && storageKeys.length > 0) {
    for (const storageKey of storageKeys) {
      setAsyncLocalStorageItem(storageKey[0], storageKey[1]);
    }
  }

  return result;
};

/**
 * @typedef DeleteSessionObject
 * @type {Object}
 * @property {Array.<Object.<string, string>>} cookies
 * @property {Array.<(Function|Array)>} promiseFns
 * @property {Array.<Object.<string, string>>} storageKeys
 */

/**
 * Simplifies session deletion by unsetting neccessary session cookies,
 * executing promise functions (e.g., token deletion via API, analytics, etc.),
 * and unsetting any local storage values
 * @todo Update to consider order of operation
 * @param {DeleteSessionObject} SessionObject 
 * @param {Object} history - React Router history object 
 * @returns window refresh via React router
 */
export async function deleteSession(
  { cookies, promiseFns, storageKeys },
  history,
  timeout=0) {
  if (promiseFns && promiseFns.length > 0) {
    for (const promiseFn of promiseFns) {
      await promiseFn();
    }
  }
  
  if (cookies && cookies.length > 0) {
    for (const cookie of cookies) {
      expireCookie(cookie);
    }
  }

  if (storageKeys && storageKeys.length > 0) {
    for (const storageKey of storageKeys) {
      deleteAsyncLocalStorageItem(storageKey);
    }
  }

  return setTimeout(() => {
    history ? history.go(0) : window.location.reload();
  }, timeout);
};

// Deletes demo user specific cookies and local sessions
export async function deleteAuthSession() {
  await deleteSession({
                        cookies: ['authentication_id', 'username', 'sign_in_method'],
                        storageKeys: ['bank-accounts', 'bank-activity'],
                      }
  );
};

// Deletes demo user and portal user specific cookies and local sessions
export async function deleteAllSessionData(){
  window.localStorage.clear();
  await deleteAuthSession()
};