import {CognitoUserPool} from 'amazon-cognito-identity-js';

const STAGING = "STAGING";
let env = process.env.REACT_APP_ENV;

if (!env || (env !== STAGING && env !== "PROD")) {
    console.log("Unknown environment {" + env + "}, will use STAGING.");
    env = "STAGING";
}

let poolData
if (env === STAGING) {
    poolData = {
        UserPoolId: 'eu-west-1_TowuPpyFR',
        ClientId: '5hba71msrj144pri5t4gg65n36'
    }
} else {
    poolData = {
        UserPoolId: 'eu-west-1_bauQSVrDf',
        ClientId: '4s1a2v50tfcbv17pf2nknfd37h'
    }
}
;

export default new CognitoUserPool(poolData)