import React from 'react';
import NumberFormat from 'react-number-format';

export function FieldCurrency({ inputRef, onChange, ...rest }) {
  return (
    <NumberFormat
      {...rest}
      allowEmptyFormatting={false}
      allowLeadingZeros={false}
      allowNegative={false}
      decimalScale={2}
      getInputRef={inputRef}
      isNumericString
      onValueChange={values => {
        onChange({ target: { name: 'amount', value: values.value }});
      }}
      thousandSeparator
    />
  );
};

export default FieldCurrency;