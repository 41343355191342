import { createMuiTheme } from '@material-ui/core/styles';

export const getTheme = (isDark) => createMuiTheme({
  palette: {
    background: {
      default: '#014c98',
    },
    primary: {
      dark: '#083e96',
      light: '#346ac7',
      main: '#014c98',
    },
    secondary: {
      dark: '#4b4d53',
      light: '#b7b9be',
      main: '#848790',
    },
    signicat: {
      purple: '#2A0062',
      green: '#41FFD1',
      lightPurple: '#8066A1',
      white: '#FFFFFF'
    },
    type: isDark ? 'dark' : 'light',
  },
  shape: {
    borderRadius: 0,
  },
});

export default getTheme;