import React, { useState } from 'react';

import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

const useStyles = makeStyles(({ palette }) => {
  return {
    cancel: {
      color: palette.secondary.main,
    },
    save: {
      color: palette.primary.main,
    },
  };
});

export function EditButton({
  active,
  IconProps,
  onCancel,
  onEdit,
  onSave,
  title={ cancel: 'Cancel', edit: 'Edit', save: 'Save' },
  TooltipProps,
  ...rest
}) {
  const [isActive, setIsActive] = useState(active || false);
  const classes = useStyles();

  const handleEdit = () => {
    setIsActive(true);
    typeof onEdit === 'function' && onEdit();
  };

  const handleSave = () => {
    setIsActive(false);
    typeof onSave === 'function' && onSave();
  };

  const handleCancel = () => {
    setIsActive(false);
    typeof onCancel === 'function' && onCancel();
  };

  if (isActive) {
    return (
      <Fade in={isActive}>
        <span>
          <BaseButton
            Icon={FontAwesomeIcon}
            IconProps={{
              className: classes.save,
              icon: ['fas', 'save'],
              ...IconProps,
            }}
            onClick={handleSave}
            TooltipProps={{ title: title.save, ...TooltipProps }}
            type='fa'
            {...rest}
          />
          <BaseButton
            Icon={FontAwesomeIcon}
            IconProps={{
              className: classes.cancel,
              icon: ['fas', 'times'],
              ...IconProps,
            }}
            onClick={handleCancel}
            TooltipProps={{ title: title.cancel, ...TooltipProps }}
            type='fa'
            {...rest}
          />
        </span>
      </Fade>
    );
  }

  return (
    <BaseButton
      Icon={FontAwesomeIcon}
      IconProps={{
        icon: ['fas', 'wrench'],
        ...IconProps,
      }}
      onClick={handleEdit}
      TooltipProps={{ title: title.edit, ...TooltipProps }}
      type='fa'
      {...rest}
    />
  );
};

export default EditButton;