import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getUserByName } from 'api';
import { AsyncContent } from 'components';

import PersonCallFlowAside from './PersonCallFlowAside';
import PersonDetails from './PersonDetails';
import PersonInfo from './PersonInfo';
import PersonLog from './PersonLog';

const useStyles = makeStyles(() => {
  return {
    section: {
      height: 'calc(100vh - 92px)',
      position: 'relative',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  };
});

export default function Person() {
  const classes = useStyles();
  const params = useParams();
  const { username } = params;

  const FulfilledContent = ({ data={} }) => {
    const isActivated = data.devices && data.devices.length > 0;
    const devices = data.devices;
    devices.sort((a, b) => b.createdAt - a.createdAt);
    const device0 = devices && devices[0];
    const deviceId = device0 ? device0.device_id : '';

    return (
      <Grid alignItems='flex-start' component='article' container>
        <Grid
          className={classes.section}
          component='section'
          item
          lg={8}
          sm={8}
          xl={9}
          xs={12}
        >
          <PersonInfo data={data} />
          <Divider variant='middle' />
          <PersonDetails />
          <Divider variant='middle' />
          <PersonLog deviceId={deviceId} />
        </Grid>
        <Grid
          component='aside'
          item
          lg={4}
          sm={4}
          xl={3}
          xs={12}
          variant='outlined'
        >
          <PersonCallFlowAside
            name={data.first_name}
            isActivated={isActivated}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <AsyncContent promiseFn={getUserByName} username={username}>
      <FulfilledContent />
    </AsyncContent>
  );
};