import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import validate from 'validate.js';

import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { changePassword, Dialog, Fields, passwordConstraints } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    error: {
      fontSize: 14,
      marginLeft: spacing(2),
      paddingBottom: spacing(2),
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
        fontSize: 16
      },
      marginBottom: spacing(1),
      fontSize: 16
    },
  }
    ;
});

export function ChangePasswordDialog({ setSnackbar, openState }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const rootIntl = 'changePasswordDialog';

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [open, setOpen] = openState;

  const fields = [
    { name: 'currentPassword', value: form.currentPassword || '' },
    { name: 'newPassword', value: form.newPassword || '', helperText: 'Password must be at least 8 character long' },
  ].map((field, i) => {
    const error = errors[field.name] && errors[field.name][0];

    return {
      ...field,
      autoFocus: i === 0,
      error: !!error,
      helperText: error || field.helperText || ' ',
      label: <Text id={`${rootIntl}.fields.${i}.label`}/>,
      placeholder: formatMessage({ id: `${rootIntl}.fields.${i}.placeholder` }),
      required: true,
      type: 'password',
    };
  });

  const handleAccept = () => {
    /* ValidateJS does not support async/await */
    validate.async(form, passwordConstraints)
      .then(async (attributes) => {
        handleValidationSuccess();
      })
      .catch((errors) => {
        handleValidationFailure(errors);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const handleValidationSuccess = async () => {
    showSnackbarMessage(
      formatMessage({ id: `${rootIntl}.adding` }, form));

    changePassword(form.currentPassword, form.newPassword)
      .then(() => {

        showSnackbarMessage(
          formatMessage({ id: `${rootIntl}.success` }, form),
          'success');

        setOpen(false);
        setForm({});

      }).catch(err => {
      console.log(err);

      let formMessage;
      if (err.code === "NotAuthorizedException") {
        // NotAuthorizedException  -> Current password was wrong
        formMessage = formatMessage({ id: `${rootIntl}.authError` }, form)
      } else if (err.code === "InvalidPasswordException") {
        // InvalidPasswordException -> New password was invalid
        formMessage = formatMessage({ id: `${rootIntl}.policyError`}, form) + ' "' +  err.message + '"'
      } else if (err.code === "LimitExceededException") {
        // LimitExceededException -> Tried update to many times
        formMessage = formatMessage({ id: `${rootIntl}.limitError` }, form)
      } else {
        formMessage = formatMessage({ id: `${rootIntl}.error`}, form)
      }

      showSnackbarMessage(
        formMessage,
        'error');

    });

  };

  const handleValidationFailure = (errors) => {
    setErrors(errors);
  }

  const handleViewClose = () => {
    setErrors({});
    setForm({});
    setOpen(false);
  };

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
                  message,
                  open: true,
                  severity: severity,
                });
  };

  return (
    <Dialog
      accept={{
        name: <Text id={`${rootIntl}.add`}/>,
        onClick: handleAccept
      }}
      decline={{
        name: <Text id={`${rootIntl}.cancel`}/>,
        onClick: handleViewClose
      }}
      maxWidth='sm'
      open={!!open}
      title={<Text id={`${rootIntl}.title`}/>}
      message={<Text id={`${rootIntl}.title`}/>}
    >

      <Fields
        className={classes.field}
        edit={true}
        onChange={handleChange}
      >
        {fields.map((field, i) => (
          <Field key={i} {...field} />
        ))}
      </Fields>

    </Dialog>
  );
};

export default ChangePasswordDialog;
