import validate from 'validate.js'
import { getUserByName } from 'api';

export const sendConstraints = {
  amount: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'must be greater than $0.00',
    },
    equality: {
      attribute: 'from.available',
      message: '^Amount must be less than available balance',
      comparator: function (v1, v2) {
        return v1 <= v2;
      },
    },
    presence: true,
  },
  frequency: {
    presence: true,
  },
  'from.accountNumber': {
    numericality: true,
    length: {
      minimum: 10,
      tooShort: 'must be at least %{count} characters',
    },
    presence: true,
  },
  'from.available': {
    numericality: true,
    equality: {
      attribute: 'amount',
      message: '^Available balance must be greater than specified amount',
      comparator: function (v1, v2) {
        return v1 >= v2;
      },
    },
    presence: true,
  },
  'to.accountNumber': {
    numericality: true,
    length: {
      minimum: 10,
      tooShort: 'must be at least %{count} characters',
    },
    presence: true,
  },
  'to.name': {
    presence: true,
  },
};

validate.validators.userExists = async function (username) {
  try {
    await getUserByName({ username });
    return '^User already exists';
  } catch (e) {
    if (e.status !== 404) {
      return '^Error checking if user already exists';
    }
  }
};

export const userConstraints = {
  userId: {
    length: {
      minimum: 4,
      tooShort: 'must be at least %{count} characters',
    },
    presence: { allowEmpty: false },
    userExists: true,
  },
  firstName: {
    presence: { allowEmpty: false },
  },
  lastName: {
    presence: { allowEmpty: false },
  },
};

export const passwordConstraints = {
  currentPassword: {
    length: {
      minimum: 8,
      tooShort: 'must be at least %{count} characters',
    },
    presence: { allowEmpty: false },
  },
  newPassword: {
    length: {
      minimum: 8,
      tooShort: 'must be at least %{count} characters',
    },
    presence: {allowEmpty: false},
  }
};

export const forgotPasswordConstraints = {
  code: {
    presence: { allowEmpty: false },
  },
  password: {
    length: {
      minimum: 8,
      tooShort: 'must be at least %{count} characters',
    },
    presence: { allowEmpty: false },
  },
};



export const signUpConstraints = {
  email: {
    presence: { allowEmpty: false },
    email:  {
      message: "not a valid email format"
    }
  },
  firstName: {
    presence: { allowEmpty: false },
  },
  lastName: {
    presence: { allowEmpty: false },
  },
  password: {
    length: {
      minimum: 8,
      tooShort: 'must be at least %{count} characters',
    },
    presence: { allowEmpty: false },
  },
};
