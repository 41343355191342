import axios from 'axios';
import find from 'lodash.find';

import { USERS_PATH } from '.';
import { setAsyncLocalStorageItem } from 'components';

let usersInfo = JSON.parse(localStorage.getItem('users')) || [];

const USERS = 'users';

const cityStates = [
  ['Juneau', 'AK'],
  ['Montgomery', 'AL'],
  ['Little Rock', 'AR'],
  ['Phoenix', 'AZ'],
  ['Sacremento', 'CA'],
  ['Denver', 'CO'],
  ['Hartford', 'CT'],
  ['Dover', 'DE'],
  ['Tallahassee', 'FL'],
  ['Atlanta', 'GA'],
  ['Honolulu', 'HI'],
  ['Des Moines', 'IA'],
  ['Boise', 'ID'],
  ['Chicago', 'IL'],
  ['Indianapolis', 'IN'],
  ['Topeka', 'KS'],
  ['Frankfort', 'KY'],
  ['Baton Rouge', 'LA'],
  ['Boston', 'MA'],
  ['Annapolis', 'MD'],
  ['Augusta', 'ME'],
  ['Lansing', 'MI'],
  ['Saint Paul', 'MN'],
  ['Jefferson City', 'MO'],
  ['Jackson', 'MS'],
  ['Helena', 'MT'],
  ['Raleigh', 'NC'],
  ['Bismarck', 'ND'],
  ['Lincoln', 'NE'],
  ['Concord', 'NH'],
  ['Trenton', 'NJ'],
  ['Santa Fe', 'NM'],
  ['Carson City', 'NV'],
  ['Albany', 'NY'],
  ['Columbus', 'OH'],
  ['Oklahoma City', 'OK'],
  ['Salem', 'OR'],
  ['Harrisburg', 'PA'],
  ['Providence', 'RI'],
  ['Columbia', 'SC'],
  ['Pierre', 'SD'],
  ['Nashville', 'TN'],
  ['Austin', 'TX'],
  ['Salt Lake City', 'UT'],
  ['Richmond', 'VA'],
  ['Montpelier', 'VT'],
  ['Olympia', 'WA'],
  ['Madison', 'WI'],
  ['Charleston', 'WV'],
  ['Cheyenne', 'WY'],
];

const streets = [
  'Main St.', 'Second St.', 'Third St.', 'Fourth St.', 'Pine Ave.', 'Domain Ave.',
  'Kirby Ln.', 'LakeShore Dr.', 'Hwy 66', 'Sandy Ln.', 'Red River Ave.',
  'FM 969',
];

export async function deleteUserByName({ username }) {
  await axios.delete(`${USERS_PATH}/name/${username}`);
};

export async function deleteAllUsers() {
  await axios.delete(`${USERS_PATH}`);
};

export async function getUserByName({ username }) {
  if (!usersInfo) await getUsers();
  const user = await axios.get(`${USERS_PATH}/name/${username}`);
  return { ...user.data, ...find(usersInfo, { username }) };
};

export async function getUsers() {
  const users = await axios.get(USERS_PATH);

  if (!users || !users.data) return [];

  usersInfo = [];

  for (const user of users.data) {
    const cityState = cityStates[Math.floor(Math.random() * cityStates.length)];

    usersInfo.push({
      ...user,
      phone: `+1 ${Math.floor(1000000000 + Math.random() * 9000000000)}`,
      city: cityState[0],
      postalCode: Math.floor(10000 + Math.random() * 50000),
      state: cityState[1],
      street_address: `
        ${Math.floor(Math.random() * 4000)}
        ${streets[Math.floor(Math.random() * streets.length)]}
      `,
    });
  }

  await setAsyncLocalStorageItem(USERS, JSON.stringify(usersInfo));
  return usersInfo;
};

export async function postUser(user) {
  return await axios.post(USERS_PATH, {
    username: user.userId,
    first_name: user.firstName,
    last_name: user.lastName,
  });
};
