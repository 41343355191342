import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage as Text } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as IdCardSvg } from '../../../assets/images/Idcard.svg';

import { SupportHeading } from 'components';

const useStyles = makeStyles(({ spacing }) => {
  return {
    textWrapper: {
      marginTop: spacing(2)
    },
    container: {
      padding: spacing(2, 4, 4, 4),
      width: '100%',
    },
    label: {
      fontSize: 10,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    value: {
      marginBottom: spacing(2),
    },
  };
});

export default function PersonInfo({ data }) {
  const classes = useStyles();
  const rootIntl = 'support.person.info';

  const { first_name, last_name } = data;
  const name = `${first_name} ${last_name}`;

  const fields = [
    { attr: 'first_name' },
    { attr: 'last_name' },
    { attr: 'username' },
    { attr: 'phone' },
    { attr: 'street_address' },
    { attr: 'city' },
    { attr: 'state' },
  ].map(({ attr }, i) => {
    return (
      <Grid item  key={attr} xs={6}>
        <Typography className={classes.label}>
          <Text id={`${rootIntl}.fields.${i}`} />
        </Typography>
        <Typography className={classes.value}>
          {data[attr]}
        </Typography>
      </Grid>
    );
  });

  return (
    <>
      <Helmet title={name} />
      <Grid className={classes.container} container spacing={2}>
        <SupportHeading title={<Text id={`${rootIntl}.title`} />} />
        <Grid item xl={2} xs={3}>
            <IdCardSvg  />
        </Grid>
        <Grid item className={classes.textWrapper} md={6} xs={9}>
          <Grid container>
            {fields}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};