import React from 'react';
import clsx from 'clsx';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) => {
  return {
    button: {
      left: '50%',
      margin: spacing(-1.5, 0, 0, -1.5),
      position: 'absolute',
      top: '50%',
    },
    fullWidth: {
      flexBasis: '100%',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

export function Loader({ active=true, button, fullWidth, ProgressProps }) {
  const classes = useStyles();

  if (!active) return null;
  
  return (
    <div className={clsx(classes.loading, {
        [classes.button]: button,
        [classes.fullWidth]: fullWidth,
      })}
    >
      <CircularProgress {...ProgressProps} />
    </div>
  );
};

export default Loader;