import React from 'react';
import Async from 'react-async';
import {Link, Redirect, Route} from 'react-router-dom';

import {Button, CircularProgress, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => {
    return {
        container: {
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
        },
        errorMsg: {
            marginBottom: 16,
        },
    };
});

export function PrivateRoute({portalLoggedIn, authFn, location, redirect = {to: '/login'}, render, ...rest}) {
    const classes = useStyles();

    const isRedirectToFn = ({to}, location) => {
        return typeof to === 'function' ? to(location) : to;
    };

    const isAccessingRedirectLocation = (location) => (
        location.pathname === isRedirectToFn(redirect, location)
    );

    const getRedirect = (redirect, location) => {
        const {to, ...other} = redirect;
        return {...other, to: isRedirectToFn({to}, location)};
    };

    return (
        <Async promiseFn={portalLoggedIn}>
          <Async.Pending>
            <div className={classes.container}>
              <CircularProgress color='primary' size={64} thickness={4}/>
            </div>
          </Async.Pending>
          <Async.Rejected>
            {(error) => (
                <div className={classes.container}>
                  <div>
                    <Typography variant='h1'>Uh oh.</Typography>
                    <Typography className={classes.errorMsg} display='block'>
                      Something went terribly wrong. Please sign in again.
                    </Typography>
                    <Button
                        color='primary'
                        component={Link}
                        to='/login'
                        variant='contained'
                    >
                      Sign in
                    </Button>
                  </div>
                </div>
            )}
          </Async.Rejected>
          <Async.Resolved>
            {(data) => (
                <Route
                    {...rest}
                    render={(props) => {
                      if (!data) {
                        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                      }
                    }}
                />
            )}
          </Async.Resolved>



            <Async promiseFn={authFn}>
                <Async.Pending>
                    <div className={classes.container}>
                        <CircularProgress color='primary' size={64} thickness={4}/>
                    </div>
                </Async.Pending>
                <Async.Rejected>
                    {(error) => (
                        <div className={classes.container}>
                            <div>
                                <Typography variant='h1'>Uh oh.</Typography>
                                <Typography className={classes.errorMsg} display='block'>
                                    Something went terribly wrong. Please sign in again.
                                </Typography>
                                <Button
                                    color='primary'
                                    component={Link}
                                    to='/login'
                                    variant='contained'
                                >
                                    Sign in
                                </Button>
                            </div>
                        </div>
                    )}
                </Async.Rejected>
                <Async.Resolved>
                    {(data) => (
                        <Route
                            {...rest}
                            render={(props) => {
                                const location = props.location;
                                if (!data && !isAccessingRedirectLocation(location)) {
                                    return <Redirect {...getRedirect(redirect, props.location)} />;
                                }
                                return render(props);
                            }}
                        />
                    )}
                </Async.Resolved>
            </Async>
        </Async>
    );
};

export default PrivateRoute;