import React, { useState } from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, CardContent,
  CardHeader, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { numberFormatter, getCookie } from 'components';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    action: {
      '&:hover': {
        color: palette.primary.main,
      },
      color: palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
      marginBottom: spacing(1.75),
    },
    actions: {
      paddingLeft: spacing(3),
      marginTop: spacing(3),
      [breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    available: {
      color: palette.grey[500],
      fontSize: 12,
      fontWeight: 300,
      marginBottom: spacing(3),
      textDecoration: 'underline',
    },
    availableValue: {
      fontSize: 24,
      fontWeight: 300,
    },
    paper: {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.grey[isDark ? 700 : 300]}`,
      },
      width: '100%',
    },
    column: {
      marginRight: spacing(4),
    },
    column2: {
      borderRight: '1px solid lightgrey',
      [breakpoints.down('md')]: {
        border: 'none',
      }
    },
    icon: {
      '--fa-primary-color': palette.primary.main,
      '--fa-secondary-color': palette.grey[500],
      marginRight: spacing(3),
    },
    iconBox: {
      position: 'relative',
      top: spacing(2),
    },
    none: {
      color: palette.grey[500],
    },
    other: {
      color: palette.grey[500],
      fontSize: 12,
      fontWeight: 300,
      marginBottom: spacing(3),
      textDecoration: 'underline',
    },
    otherValue: {
      fontSize: 16,
      fontWeight: 300,
    },
    title: {
      color: palette.primary.main,
      fontSize: 14,
      letterSpacing: spacing(.25),
      textTransform: 'uppercase',
    },
  };
});

export function AccountsBankAccount({
  accountNumber, available, credits, current, debits, icon, title
}) {
  const classes = useStyles();
  const [activateDialog, setActivateDialog] = useState(false);
  const rootIntl = 'accounts.accts';
  const { format } = numberFormatter;

  const handleCancel = () => {
    setActivateDialog(false);
  };

  const handleClick = (event) => {
    const isTraditionalMethod = getCookie('sign_in_method') === 'traditional';

    if (isTraditionalMethod) {
      event.preventDefault();
      setActivateDialog(true);
    }
  };

  if (!accountNumber || !title) {
    return (
      <Grid item xs={12}>
        <Typography className={classes.none} display='block'>
          <Text id={`${rootIntl}.none`} />
        </Typography>
      </Grid>
    );
  }

  const actions = [
    { onClick: handleClick, to: '/bank/send' },
    { to: '#' },
    { to: '#' },
    { to: '#' },
  ].map((action, i) => (
    <Link
      className={classes.action}
      component={RouterLink}
      display='block'
      gutterBottom
      key={i}
      underline={i === 0 ? 'hover' : 'none'}
      {...action}
    >
      <Text id={`${rootIntl}.actions.${i}`} />
    </Link>
  ));

  const column1 = [
    { children: `${format(available)}`, className: classes.availableValue },
    { className: classes.available },
    { children: `${format(current)}`, className: classes.otherValue },
    { className: classes.other },
  ].map(({ children, ...other }, i) => (
    <Typography key={i} {...other}>
      {children || <Text id={`${rootIntl}.balances.${i}`} />}
    </Typography>
  ));

  const column2 = [
    { children: `${format(credits)}`, className: classes.otherValue },
    { className: classes.other },
    { children: `${format(debits)}`, className: classes.otherValue },
    { className: classes.other },
  ].map(({ children, ...other }, i) => (
    <Typography key={i} {...other}>
      {children || <Text id={`${rootIntl}.balances.${i + 4}`} />}
    </Typography>
  ));

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <CardHeader
          title={`${title} ${accountNumber ? `(...${accountNumber.slice(-4)})` : ''}`}
          titleTypographyProps={{ className: classes.title }}
        />
        <CardContent>
          <Grid alignItems='baseline' container>
            <Grid className={classes.iconBox} item>
              <FontAwesomeIcon className={classes.icon} icon={icon} size='3x' />
            </Grid>
            <Grid item md={3} xs>{column1}</Grid>
            <Grid className={classes.column2} item md={3} xs>{column2}</Grid>
            <Grid className={classes.actions} item md={3} xs={12}>{actions}</Grid>
          </Grid>
        </CardContent>
      </Paper>
      <Dialog open={activateDialog}>
        <DialogTitle>
          <Text id={`${rootIntl}.dialog.title`} />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Text id={`${rootIntl}.dialog.desc`} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='contained'>
            <Text id={`${rootIntl}.dialog.cancel`} />
          </Button>
          <Button
            color='primary'
            component={RouterLink}
            to='activate'
            variant='contained'
          >
            <Text id={`${rootIntl}.dialog.accept`} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountsBankAccount;