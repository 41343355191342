import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';

import { Grid, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TransactionStatus } from 'components';
import SendMoneyStepAwait from './SendMoneyStepAwait';
import SendMoneyStepComplete from './SendMoneyStepComplete';
import SendMoneyStepForm from './SendMoneyStepForm';

const useStyles = makeStyles(({ palette }) => {
  return {
    stepper: {
      backgroundColor: palette.background.default,
    },
  };
})

export function SendMoney() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const rootIntl = 'send.steps';
  const { formatMessage } = useIntl();
  const defaultFrequency = formatMessage({id: `${rootIntl}.0.fields.3.options.0`});
  const [form, setForm] = useState({ frequency: defaultFrequency });
  const [transactionResponse, setTransactionResponse] = useState({});

  const handleBack = () => {
    setActiveStep(step => step > 0 ? step - 1 : step);
  };

  const handleNoAuthConfirm = () => {
    setTransactionResponse({});
  };

  const handleCancel = () => {
    handleBack();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleNext = () => {
    setActiveStep(step => step < 2 ? step + 1 : step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setForm({ frequency: defaultFrequency });
  };

  const steps = [0, 1, 2];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SendMoneyStepForm
            form={form}
            onChange={handleChange}
            onNext={handleNext}
          />
        );
      case 1:
        return (
          <SendMoneyStepAwait
            form={form}
            onCancel={handleCancel}
            onNext={handleNext}
            recipient={form.to}
            setTransactionResponse={setTransactionResponse}
          />
        );
      case 2:
        return (
          <SendMoneyStepComplete
            form={form}
            onReset={handleReset}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Grid item md={6} xs={12}>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        orientation='vertical'
      >
        {steps.map((id, index) => (
          <Step key={id}>
            <StepLabel>
              <Text id={`${rootIntl}.${id}.title`} />
            </StepLabel>
            <StepContent>
              <Grid container>
                <Grid item>
                  {getStepContent(index)}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <TransactionStatus
        onClick={handleNoAuthConfirm}
        response={transactionResponse}
        rootIntl='send'
      />
    </Grid>
  );
};

export default SendMoney;
