import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faDollarSign,
  faHeartbeat,
  faShoppingCart,
  faEye,
  faEyeSlash,
  faSignOut,
  faBars,
  faUserHeadset
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faUserCircle,
  faQuestionCircle
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faDollarSign,
  faHeartbeat,
  faShoppingCart,
  faEye,
  faEyeSlash,
  faUserCircle,
  faSignOut,
  faBars,
  faUserHeadset,
  faQuestionCircle
);