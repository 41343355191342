import Activate from './Activate';
import { BankLayout } from 'components';

export const config = {
  entrypoint: Activate,
  id: 'activate',
  layout: BankLayout,
  location: { pathname: '/bank/activate' },
  private: true,
};

export default config;