import React from 'react';
import Helmet from 'react-helmet';

import { CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

import '../icons';
import WsbFavicon from 'assets/images/wsb-favicon.ico';
import SupportLayoutNav from './LayoutNav';
import getTheme from '../theme';

const useStyles = makeStyles(() => {  
  return {
    root: {
      height: '100%',
      minHeight: 'calc(100vh - 92px)',
      margin: '0 auto',
      width: '100vw',
    },
  };
});

export default function SupportLayout({ children, darkState, SubBarProps }) {
  const classes = useStyles();
  const [isDark, setIsDark] = darkState;
  const title = 'WSB Call Center';

  return (
    <ThemeProvider theme={getTheme(isDark)}>
      <CssBaseline />
      <Helmet defaultTitle={title} titleTemplate={`${title} | %s`}>
          <meta name='description' content={`Signicat ${title} Demo Home Page`} />
          <link rel='shortcut icon' href={WsbFavicon} />
        </Helmet>
      <SupportLayoutNav 
        themeState={[isDark, setIsDark]}
        SubBarProps={SubBarProps}
      />
      <main className={classes.root}>{children}</main>
    </ThemeProvider>
  );
};