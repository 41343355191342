import React from 'react';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage as Text } from 'react-intl';

export default function DemoAlert({
  actionText, onClick, onClose, open, severity='info',
  SnackbarProps, ...other
}) {

  const severityActionColor = severity === 'warning' ? 'primary' : 'secondary';

  const alertAction = (
    <Button color={severityActionColor} onClick={onClick} size='small'>
      <Text id={actionText} />
    </Button>
  );

  return (
    <Snackbar
      autoHideDuration={6000}
      onClose={onClose}
      open={open}
      {...SnackbarProps}
    >
      <MuiAlert
        action={alertAction}
        elevation={6}
        onClose={onClose}
        severity={severity}
        variant='filled'
        {...other}
      />
    </Snackbar>
  );
};
