import React from 'react';
import { FormattedMessage as Text } from 'react-intl';

import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Container, Section } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    blurbContainer: {
      margin: '0 auto',
      width: '100%',
    },
    card: {
      borderLeft: `3px solid ${palette.primary.main}`,
    },
    content: {
      fontSize: 14,
      marginBottom: spacing(6),
    },
    container: {
      padding: spacing(0, 2),
    },
    section: {
      marginBottom: spacing(4),
    },
  };
});

export function HomeBlurbs() {
  const classes = useStyles();
  const rootIntl = 'home.blurbs.items';
  const numBlurbs = Array.apply(null, Array(2));

  const renderedBlurbs = numBlurbs.map((_, i) => (
    <Grid item key={i} md={6}>
      <Card className={classes.card}>
        <CardHeader title={<Text id={`${rootIntl}.${i}.title`} />} />
        <CardContent>
          <Typography className={classes.content}>
            <Text id={`${rootIntl}.${i}.content`} />
          </Typography>
          <Button variant='outlined'>
            <Text id={`${rootIntl}.${i}.action`} />
          </Button>
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <Section className={classes.section}>
      <Container className={classes.container}>
        <Grid 
          className={classes.blurbContainer}
          container
          justify='space-between'
          spacing={4}
        >
          {renderedBlurbs}
        </Grid>
      </Container>
    </Section>
  )
};

export default HomeBlurbs;