import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { FormattedMessage as Text } from 'react-intl';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as MobileActSvg } from '../../../assets/images/MobileGreen.svg';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    button: {
      '&:hover': {
        backgroundColor: palette.grey[500],
      },
      backgroundColor: palette.signicat.green,
      color: palette.signicat.purple,
      transition: 'all .3s ease-in-out',
    },
    container: {
      '& > div:first-child': {
        margin: '0 auto',
      },
      '& > div:last-child': {
        textAlign: 'center',
      },
    },
    desc: {
      fontSize: 14,
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    root: {
      backgroundColor: palette.primary.main,
      borderTopLeftRadius: 10,  
      borderBottomRightRadius: 10,  
      color: palette.getContrastText(palette.primary.main),
      marginBottom: spacing(3),
      padding: spacing(2,3),
    }
  };
});

export default function ActivateDeviceBanner({ user }) {
  const classes = useStyles();
  const rootIntl = 'accounts.activate';
 
  if (user && user.devices && user.devices.length > 0) return null;

  return (
    <Paper className={classes.root}>
      <Grid
        alignContent='center'
        alignItems='center'
        className={classes.container}
        container
        justify='space-around'
        spacing={4}
      >
        <Grid item xs={5}>
          <MobileActSvg height={125} width='100%' />
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.desc}>
            <Text id={`${rootIntl}.download`} />
          </Typography>
          <Button
            className={classes.button}
            color='primary'
            component={RRLink}
            size='small'
            to='/bank/activate'
            variant='contained'
          >     
            <Text id={`${rootIntl}.action`} />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};