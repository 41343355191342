import React, { useState } from 'react';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {deleteAuthSession, getCookie} from 'components';
import { deleteAllUsers, deleteUserByName } from 'api';
import validate from 'validate.js';
import './validators';

const useStyles = makeStyles(({ spacing }) => {
  return {
    desc: {
      marginBottom: spacing(2),
    },
    field: {
      minHeight: 72,
    },
  };
});

export function UserDeleteDialog({ setSnackbar, openState }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { location, push } = useHistory();
  const rootIntl = 'deleteDialog';

  const [deleteAll, setDeleteAll] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [error, setError] = useState('');
  const [form, setForm] = useState({});
  const [open, setOpen] = openState;

  const handleAccept = async (event) => {
    event.preventDefault();

    const { userId } = form;
    const peopleListPath = '/bank/call-center/people';

    try {
      showSnackbarMessage(
          !deleteAll ?
          formatMessage({ id: `${rootIntl}.deleting` }, { userId }) :
          formatMessage({ id: `${rootIntl}.deletingAll` }),
          'info'
      );

      if (deleteAll) {
        await deleteAllUsers();
      } else {
        await deleteUserByName({ username: userId });
      }

      setOpen(false);
      showSnackbarMessage(
          !deleteAll ?
          formatMessage({ id: `${rootIntl}.success` }, { userId }) :
          formatMessage({ id: `${rootIntl}.successAll` }),
        'success',
      );
      setForm({});
      setError('');

      if (location.pathname === peopleListPath) {
        window.location.reload();
      }

      // If deleted user is currently logged in, delete session.
      if (deleteAll || getCookie('username') === userId) {
        deleteAuthSession();
      }

      // If user is on the deleted user's call center view, navigate away.
      const splitPath = location.pathname.split('/');
      if (splitPath[splitPath.length - 1] === userId) {
        setTimeout(() => push(peopleListPath), 1500);
      }
    } catch(exception) {
      showSnackbarMessage(
          !deleteAll ?
          formatMessage({ id: `${rootIntl}.error` }, { userId }) :
          formatMessage({ id: `${rootIntl}.errorAll` }),
        'error');
    }
  };

  const handleBlur = async (event) => {
    setError(await validateForm(event));
  };

  const handleDeleteAll = () => {
    setDeleteAll(!deleteAll);
    setDeleteDisabled(deleteAll);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Not awaiting since I don't want to interrupt the onChange listener.
    // This allows for the button disable state to update when promise resolves.
    validateForm(event);
    setForm({ [name]: value });
  };

  const handleViewClose = () => {
    setOpen(false);
    setDeleteAll(false);
    setDeleteDisabled(true);
    setError('');
    setForm({});
  };

  const validateForm = async ({ target: { value: userId }}) => {
    try {
      const result = await validate.async({ userId }, { userId: {
        checkUserIdExists: true,
        presence: true
      }});

      if (result.userId === userId) {
        setDeleteDisabled(false);
        setError('');
      }

      return '';
    } catch (error) {
      setDeleteDisabled(true);
      return formatMessage({ id: error.message }, { userId });
    }
  };

  const showSnackbarMessage = (message, severity) => {
    setSnackbar({
      message,
      open: true,
      severity: severity,
    });
  };

  return (
    <Dialog maxWidth='sm' open={!!open}>
      <form onSubmit={handleAccept}>
        <DialogTitle>
          <Text id={`${rootIntl}.title`} />
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.desc}>
            <Text id={`${rootIntl}.desc`} />
          </Typography>
          <TextField
            autoFocus
            className={classes.field}
            error={error !== ''}
            fullWidth
            helperText={error}
            label='Username'
            name='userId'
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={deleteAll}
            size='small'
            value={form['userId'] || ''}
            variant='outlined'
          />
          <div>
            <Text id={`${rootIntl}.deleteAll`}/>
            <input
                type="checkbox"
                checked={deleteAll}
                onChange={handleDeleteAll}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>
            <Text id={`${rootIntl}.cancel`} />
          </Button>
          <Button color='primary' disabled={deleteDisabled} type='submit'>
            <Text id={`${rootIntl}.remove`} />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserDeleteDialog;
