import React, { useState } from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppBar, Grid, Hidden, IconButton, Link, Menu, MenuItem, Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DemoMenu } from 'components/shared';
import Logo from '../Logo';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    button: {
      color: palette.common.white,
    },
    container: {
      margin: '0 auto',
      maxWidth: ({ width }) => width || 'none',
      padding: ({ padding }) => spacing(0, padding || 0),
    },
    link: {
      marginRight: spacing(4),
      color: palette.common.white,
      cursor: 'pointer',
    },
    logoWrapper: {
      flex: 1,
    },
    toolbar: {
      padding: 0,
    },
  };
});

export function NavBar({ padding, themeState, width }) {
  const classes = useStyles({ padding, width });
  const rootIntl = 'navigation.navbar';
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const links = [
    { children: <Text id={`${rootIntl}.menu.0`} /> },
    { children: <Text id={`${rootIntl}.menu.1`} />},
  ];

  const renderedLinks = links.map((link, i) => (
    <Link className={classes.link} key={i} {...link} />
  ));

  const renderedMenuItems = links.map((link, i) => (
    <MenuItem
      className={classes.menuItem}
      key={i}
      onClick={handleClose}
      {...link}
    />
  ));

  return (
    <>
      <AppBar elevation={0}>
        <Toolbar className={classes.toolbar} variant='dense'>
          <Grid alignItems='center' className={classes.container} container>
            <Grid className={classes.logoWrapper} item>
              <Logo />
            </Grid>
            <Hidden smDown>
              <Grid item>{renderedLinks}</Grid>
            </Hidden>
            <Hidden mdUp>
              <>
                <IconButton className={classes.button} onClick={handleClick}>
                  <FontAwesomeIcon icon={['fal', 'bars']} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                >
                  {renderedMenuItems}
                </Menu>
              </>
            </Hidden>
            <Grid item>
              <DemoMenu
                themeState={themeState}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar variant='dense' />
    </>
  );
};

export default NavBar;