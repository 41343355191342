import React from 'react';
import Async from 'react-async';
import { FormattedMessage as Text } from 'react-intl';

import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import { getUserBankAccounts, getUserBankActivity, getUserByName } from 'api';
import { AsyncContent, Container, Error, getCookie, Section } from 'components';

import AccountsBankAccount from './AccountsBankAccount';
import AccountsBankActivity from './AccountsBankActivity';
import AccountsPanel from './AccountsPanel';
import AccountsUserCard from './AccountsUserCard';
import ActivateDeviceBanner from './ActivateDeviceBanner';

const useStyles = makeStyles(({ spacing }) => {
  return {
    section: {
      margin: spacing(4, 0),
      minHeight: 'calc(100vh - 224px)',
      width: '100%',
    },
  };
});

export function AccountsPanels() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const BankAccountsPanel = () => {
    return (
      <AccountsPanel
        defaultExpanded={true}
        title={<Text id='accounts.accts.title' />}
      >
        <Async promiseFn={getUserBankAccounts}>
          <Async.Rejected>
            {(error) => <Error error={error} />}
          </Async.Rejected>
          <Async.Fulfilled>
            {(data) => data.map((props) => (
              <AccountsBankAccount key={props.title} {...props} />
            ))}
          </Async.Fulfilled>
        </Async>
      </AccountsPanel>
    );
  }

  const BankActivityPanel = () => {
    return (
      <AccountsPanel
        defaultExpanded={true}
        title={<Text id='accounts.activity.title' />}
      >
        <Async promiseFn={getUserBankActivity}>
          <Async.Pending>Loading</Async.Pending>
          <Async.Rejected>
            {(error) => <Error error={error} />}
          </Async.Rejected>
          <Async.Fulfilled>
            {(data) => <AccountsBankActivity activity={data} />}
          </Async.Fulfilled>
        </Async>
      </AccountsPanel>
    );
  };

  const UserAside = ({ data }) => (
    <>
      <ActivateDeviceBanner user={data} />
      <AccountsUserCard user={data} />
    </>
  );

  const content = [
    <Grid item key={0} lg={8} xs={12}>
      <BankAccountsPanel />
      <BankActivityPanel />
    </Grid>,
    <Grid item key={1} lg={4} xs={12}>
      <AsyncContent promiseFn={getUserByName} username={getCookie('username')}>
        <UserAside />
      </AsyncContent>
    </Grid>,
  ];

  return (
    <Section className={classes.section}>
      <Container spacing={matches ? 0 : 4}>
        {matches ? content.reverse() : content}
      </Container>
    </Section>
  )
};

export default AccountsPanels;