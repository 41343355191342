import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseButton from './BaseButton';

export function DownloadButton({
  IconProps,
  title='Download',
  TooltipProps,
  ...rest
}) {
  return (
    <BaseButton
      Icon={FontAwesomeIcon}
      IconProps={{
        icon: ['fas', 'download'],
        ...IconProps,
      }}
      TooltipProps={{ title, ...TooltipProps }}
      type='fa'
      {...rest}
    />
  );
};

export default DownloadButton;