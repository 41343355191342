import React from 'react';
import Async from 'react-async';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import validate from 'validate.js';
import find from 'lodash.find';

import {
  Button, InputAdornment, ListItemText, MenuItem, TextField as Field,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getSendDetails } from 'api';
import { Fields, numberFormatter, sendConstraints } from 'components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    acct: {
      color: palette.grey[500],
    },
    field: {
      '& input': {
        backgroundColor: palette.background.paper,
      },
      marginBottom: spacing(2),
    },
    select: {
      backgroundColor: palette.background.paper,
    },
  };
});

export function SendMoneyStepForm({ form, onChange, onNext }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const rootIntl = 'send.steps.0';
  const { format } = numberFormatter;
  const validations = validate(form, sendConstraints);
  const isValidated = typeof validations === 'undefined';

  const getFulfilledContent = (data) => {
    const fieldProps = {
      className: classes.field,
      edit: true,
      fullWidth: true,
      onChange,
      required: true,
      size: 'small',
      variant: 'outlined',
    };

    const validate = (name) => validations
      && validations[name]
      && validations[name][0];

    const relIntl = `${rootIntl}.fields`;

    return (
      <>
        <Fields {...fieldProps}>
          <Field
            helperText={validate('from') || ' '}
            label={formatMessage({ id: `${relIntl}.0.label` })}
            name='from'
            select
            SelectProps={{ className: classes.select }}
            value={form.from ? find(data.userAccounts, { accountNumber: form.from.accountNumber }) : ''}
          >
            {data.userAccounts.map((acct) => (
              <MenuItem key={acct.title} value={acct}>
                <ListItemText
                  primary={acct.title}
                  secondary={`
                    ${formatMessage({ id: `${relIntl}.0.secondary` })}
                    ${format(acct.available)}
                  `}
                />
              </MenuItem>
            ))}
          </Field>
          <Field
            helperText={validate('to') || ' '}
            label={formatMessage({ id: `${relIntl}.1.label` })}
            name='to'
            select
            SelectProps={{ className: classes.select }}
            value={form.to ? find(data.recipients, { accountNumber: form.to.accountNumber }) : ''}
          >
            {data.recipients.map((acct) => (
              <MenuItem key={acct.name} value={acct}>
                <ListItemText
                  primary={acct.name}
                  secondary={`
                    ${formatMessage({ id: `${relIntl}.1.secondary` })}
                    ${acct.accountNumber}
                  `}
                />
              </MenuItem>
            ))}
          </Field>
          <Field
            helperText={validate('amount') || ' '}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            }}
            isCurrency
            label={formatMessage({ id: `${relIntl}.2.label` })}
            name='amount'
            placeholder={formatMessage({ id: `${relIntl}.2.placeholder`})}
            value={form.amount || ''}
          />
          <Field
            helperText={validate('frequency') || ' '}
            label={formatMessage({ id: `${relIntl}.3.label` })}
            name='frequency'
            select
            SelectProps={{ className: classes.select }}
            value={form.frequency || ''}
          >
            {[
              formatMessage({id: `${relIntl}.3.options.0`}),
              formatMessage({id: `${relIntl}.3.options.1`}),
              formatMessage({id: `${relIntl}.3.options.2`}),
            ].map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)}
          </Field>
        </Fields>
        <Button
          color='primary'
          disabled={!isValidated}
          onClick={onNext}
          variant='contained'
        >
          <Text id={`${rootIntl}.nextText`} />
        </Button>
      </>
    );
  };

  return (
    <Async promiseFn={getSendDetails}>
      <Async.Fulfilled>
        {(data) => getFulfilledContent(data)}
      </Async.Fulfilled>
    </Async>
  );
};

export default SendMoneyStepForm;