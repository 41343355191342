/** @module StorageHelpers */

/**
 * Deletes a storage key within localStorage
 * @param {string} key - the storage key to delete
 * @returns {Promise} - resolves or rejects removal
 */
export async function deleteAsyncLocalStorageItem(key) {
  return Promise.resolve().then(function () {
    return window.localStorage.removeItem(key);
  });
};

/**
 * Fetches a value within localStorage by storage key
 * @param {string} key - the storage key to fetch
 * @param {Object} options 
 * @param {String} options.set - a value to set if "get" finds value empty
 * @returns {Promise} - resolves or rejects fetch
 */
export async function getAsyncLocalStorageItem(key, options) {
  return Promise.resolve().then(function () {
    const value = window.localStorage.getItem(key);
    if (value) return value;

    if (!!options.set && !value) {
      window.localStorage.setItem(key, options.set);
      return options.set;
    }
  });
};

/**
 * Creates or updates a storage key within localStorage with value
 * @param {string} key - the storage key to delete
 * @param {string} value - the value to store
 * @returns {Promise} - resolves or rejects creation/update
 */
export async function setAsyncLocalStorageItem(key, value) {
  return Promise.resolve().then(function () {
    window.localStorage.setItem(key, value);
  });
};



