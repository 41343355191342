import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Container, Section } from 'components';

const useStyles = makeStyles(({ breakpoints, spacing }) => {
  return {
    section: {
      paddingTop: spacing(6),
      [breakpoints.down('sm')]: {
        paddingTop: spacing(2),
      },
    },
  };
});

export function SendTitle() {
  const classes = useStyles();

  return (
    <Section className={classes.section}>
      <Container>
        <Typography variant='h3'>
          Send Money
        </Typography>
      </Container>
    </Section>
  );
};

export default SendTitle;