import People from './People';
import { SupportLayout } from 'components';

export const config = {
  entrypoint: People,
  id: 'people',
  layout: SupportLayout,
  location: { pathname: '/bank/call-center/people' },
};

export default config;