import React from 'react';

import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Logo from '../../../assets/images/Signicat.png';

const useStyles = makeStyles(({ palette, spacing }) => {
  const isDark = palette.type === 'dark';

  return {
    footer: {
      '& *': {
        color: palette.grey[500],
      },
      backgroundColor: palette.background.paper,
      borderTop: `1px solid ${palette.grey[isDark ? 900 : 300]}`,
      display: 'flex',
      justifyContent: 'center',
    },
    toolbar: {
      maxWidth: ({ width }) => width || 'none',
      padding: ({ padding }) => spacing(0, padding || 0),
    },
    text: {
      marginLeft: spacing(1)
    },
  };
});

export function Footer({ padding, width }) {
  const classes = useStyles({ padding, width });

  return (
    <footer className={classes.footer}>
      <Toolbar className={classes.toolbar}>
        <img alt='Signicat' src={Logo} height={20}/>
        <div className={classes.text}>Powered by Signicat</div>
      </Toolbar>
    </footer>
  );
};

export default Footer;