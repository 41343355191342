import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCheckCircle,
  faExpand,
  faFilter,
  faKey,
  faLock,
  faLockOpen,
} from '@fortawesome/pro-light-svg-icons';

import {
  faShieldCheck,
  faStream,
  faUser,
  faUserHeadset,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faCheckCircle,
  faExpand,
  faFilter,
  faKey,
  faLock,
  faLockOpen,
  faShieldCheck,
  faStream,
  faUser,
  faUserHeadset,
  faUsers,
);