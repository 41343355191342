import React, { useEffect, useState } from 'react';

export function Timer({ time }) {
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutesLeft = Math.floor(timeLeft / 60);
  const minutes = minutesLeft.toString().length === 1
    ? '0' + minutesLeft
    : minutesLeft;

  const secondsLeft = timeLeft - minutes * 60;
  const seconds = secondsLeft.toString().length === 1
    ? '0' + secondsLeft
    : secondsLeft;

  return (
    <>{`${minutes}:${seconds}`}</>
  );
};

export default Timer;