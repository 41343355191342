import React, {useState} from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppBar, Grid, IconButton, Link, Menu, MenuItem, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DemoAlert, logout, Page, getName } from 'components';
import Logo from 'assets/images/Signicat_negative.png';
import { FormattedMessage as Text } from "react-intl";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ChangeNameDialog from "./ChangeNameDialog";

const useStyles = makeStyles(({ breakpoints, palette, shadows, spacing }) => {
  return {
    demo: {
      '&:hover': {
        boxShadow: shadows[10],
        top: -10,
        '& div:first-child': {
          opacity: 1,
        },
      },
      position: 'relative',
      padding: spacing(2),
      top: 0,
      transition: 'all .3s ease-in-out',
      width: '100%',
    },
    demoSubtitle: {
      color: palette.common.white,
    },
    demoText: {
      flex: 1,
      [breakpoints.down('md')]: {
        flex: 'initial',
      },
    },
    demoTitle: {
      color: palette.common.white,
      fontSize: 36,
      fontWeight: 700,
    },
    container: {
      margin: '0 auto',
      maxWidth: 1920,
      minHeight: '100vh',
      padding: spacing(8),
    },
    icon: {
      '--fa-primary-color': palette.secondary.light,
      '--fa-secondary-color': palette.signicat.white,
      '--fa-secondary-opacity': 1,
      margin: '0 auto',
      textAlign: 'center',
    },
    oneColorIcon: {
      '--fa-primary-color': palette.secondary.light,
      '--fa-secondary-color': palette.secondary.light,
      '--fa-secondary-opacity': 1,
      margin: '0 auto',
      textAlign: 'center',
    },
    regularIcon: {
      color: palette.secondary.light,
      margin: '0 auto',
      textAlign: 'center',
    },
    iconWrapper: {
      minWidth: 120,
      textAlign: 'center',
    },
    imgContainer: {
      textAlign: 'center',
    },
    imgLogo: {
      maxWidth: '90%',
    },
    title: {
      color: palette.common.white,
      fontWeight: 300,
      marginLeft: spacing(3),
      [breakpoints.only('md')]: {
        marginBottom: spacing(10),
      },
      [breakpoints.down('lg')]: {
        marginLeft: 0,
        textAlign: 'center',
      },
    },
    linkButton: {
      backgroundColor: 'transparent',
      color: '#41FFD1',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline',
      margin: 0,
      padding: 0,
      fontSize: 14,
    },
  };
});

export function Landing() {
  const rootIntl = 'navigation.navbar.demoMenu';
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [showChangeDialog, setShowChangeDialog] = useState(false);
  const [showChangeNameDialog, setShowChangeNameDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({});
  const [currentName, setCurrentName] = useState({});
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    history.push('/login');

  }

  const handleChangePassword = () => {
    setShowChangeDialog(true);
  }

  const handleChangeName = () => {
    getName().then((response) => setCurrentName(response.data));
    setShowChangeNameDialog(true);
  }

  const handleCloseSnackbar = () => {
    setSnackbar({});
  }

  const handleRunbook = () => {
    window.open(
        'https://developer.signicat.com/docs/mobile-identity/demo/',
        '_blank'
    );
  }

  const demo = <Grid item md={9} xs={12}>
    <Link component={RouterLink} underline='none' to='/bank'>
      <Grid alignItems='center' className={classes.demo} container justify='center'>
        <Grid className={classes.iconWrapper} item>
          <FontAwesomeIcon
            className={classes.icon}
            icon={['fad', 'dollar-sign']}
            size='4x'
          />
        </Grid>
        <Grid className={classes.demoText} item>
          <Typography className={classes.demoTitle} display='inline'>
            Bank Demo
          </Typography>
          <Typography className={classes.demoSubtitle} variant='subtitle1'>
            Speed up transactions while improving your bank's security
          </Typography>
        </Grid>
      </Grid>
    </Link>
  </Grid>;

  const callCenter = <Grid item md={9} xs={12}>
    <Link component={RouterLink} underline='none' to='/bank/call-center'>
      <Grid alignItems='center' className={classes.demo} container justify='center'>
        <Grid className={classes.iconWrapper} item>
          <FontAwesomeIcon
              className={classes.icon}
              icon={['fad', 'user-headset']}
              size='4x'
          />
        </Grid>
        <Grid className={classes.demoText} item>
          <Typography className={classes.demoTitle} display='inline'>
            Call Center Demo
          </Typography>
          <Typography className={classes.demoSubtitle} variant='subtitle1'>
            Authenticate your customers that are calling in for support
          </Typography>
        </Grid>
      </Grid>
    </Link>
  </Grid>;

  const menu = <AppBar elevation={0} style={{ background: 'transparent', boxShadow: 'none' }}>
    <IconButton onClick={handleClick} style={{ marginLeft: "auto" }}>
      <SvgIcon>
        <FontAwesomeIcon
          className={classes.oneColorIcon}
          icon={['fad', 'bars']}
        />
      </SvgIcon>
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      getContentAnchorEl={null}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <MenuItem dense onClick={handleChangePassword}>
        <SvgIcon style={{ marginRight: "5%" }}>
          <FontAwesomeIcon
            className={classes.regularIcon}
            icon={['far', 'user-circle']}
          />
        </SvgIcon>
        <Text id={`${rootIntl}.changePassPortalUser`}/>
      </MenuItem>
      <MenuItem dense onClick={handleChangeName}>
        <SvgIcon style={{ marginRight: "5%" }}>
          <FontAwesomeIcon
              className={classes.regularIcon}
              icon={['far', 'user-circle']}
          />
        </SvgIcon>
        <Text id={`${rootIntl}.addNamePortalUser`}/>
      </MenuItem>
      <MenuItem dense onClick={handleRunbook}>
        <SvgIcon style={{ marginRight: "5%" }}>
          <FontAwesomeIcon
              className={classes.regularIcon}
              icon={['far', 'question-circle']}
          />
        </SvgIcon>
        <Text id={`${rootIntl}.runbook`}/>
      </MenuItem>
      <MenuItem dense onClick={handleLogout}>
        <SvgIcon style={{ marginRight: "5%" }}>
          <FontAwesomeIcon
              className={classes.oneColorIcon}
              icon={['fad', 'sign-out']}
          />
        </SvgIcon>
        <Text id={`${rootIntl}.logoutPortalUser`}/>
      </MenuItem>
    </Menu>

  </AppBar>;

  const helpBar = <AppBar style={{ background: 'transparent', boxShadow: 'none', top: 'auto', bottom: 0  }}>
    <IconButton onClick={handleRunbook} style={{ marginLeft: 'auto' }} >
      <SvgIcon style={{ minWidth: '50', minHeight: '50' }}>
        <FontAwesomeIcon
            className={classes.regularIcon}
            icon={['far', 'question-circle']}
        />
      </SvgIcon>
    </IconButton>
  </AppBar>

  return (
    <Page timeout={1500}>
      {menu}

      <ChangePasswordDialog
        setSnackbar={setSnackbar}
        openState={[showChangeDialog, setShowChangeDialog]}
      />
      <ChangeNameDialog
        setSnackbar={setSnackbar}
        openState={[showChangeNameDialog, setShowChangeNameDialog]}
        currentName={currentName}
      />

      <Grid alignItems='center' alignContent='center' className={classes.container} container justify='center'>
        <Grid item lg={6} xs={12}>
          <Grid alignItems='center' container justify='center' spacing={3}>
            <Grid className={classes.imgContainer} item md={9} xs={12}>
              <img className={classes.imgLogo} alt='Signicat' src={Logo}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container justify='center' spacing={10}>
            {demo}
          </Grid>
          <Grid container justify='center' spacing={10}>
            {callCenter}
          </Grid>
        </Grid>
      </Grid>

      <DemoAlert
        actionText={`${rootIntl}.statusConfirmed`}
        onClick={handleCloseSnackbar}
        onClose={handleCloseSnackbar}
        severity={snackbar.severity}
        SnackbarProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
        }}
        {...snackbar}
      >
        {snackbar.message}
      </DemoAlert>

      {helpBar}
    </Page>
  )
}

export default Landing;