import React, { Fragment, useRef, useState } from 'react';
import Async from 'react-async';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, CircularProgress, Divider, Grid, List, ListItem, ListItemText,
  MenuItem, Step, StepContent, StepLabel, Stepper, TextField, Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getAuthenticationStatus, postAuthentication } from 'api';
import { poll } from 'components';

const useStyles = makeStyles(({ spacing }) => {
  return {
    button: {
      marginRight: spacing(2),
      marginTop: spacing(2.5),
    },
    customBtn: {
      marginLeft: spacing(2),
      minHeight: 36,
    },
    input: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '85%',
    },
  };
});

export default function PersonCallFlow({ activeStep, handleActiveStep, handleDialog, handleResponse, name }) {
  const classes = useStyles();
  const { username } = useParams();
  const poller = useRef(null);
  const rootIntl = 'support.person.stepper';
  const { formatMessage } = useIntl();
  const defaultMessage = formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentOptions.0` });
  const [form, setForm] = useState({ message: defaultMessage});
  const [customContext, setCustomContext] = useState(false);

  let throttleOn = false;

  const handleBackStep = () => {
    handleActiveStep(step => step > 0 ? step - 1 : step);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ [name] : value });
  };

  const handleNextStep = () => {
    handleActiveStep(step => step + 1);
  };

  const handleCancel = () => {
    handleNoAuth();
  };

  const handleDone = (event) => {
    setForm({ message: defaultMessage });
    setCustomContext(false);
    handleDialog(!!event);
  };

  const handleNoAuth = (response={}) => {
    if (throttleOn) return;

    throttleOn = true;
    poller && poller.current && poller.current.cancel();
    handleBackStep();
    handleResponse(response);
  };

  const handlePollSuccess = (event, fn) => {
    if (throttleOn) return;

    throttleOn = true;
    poller && poller.current && poller.current.cancel();
    handleNextStep(event, fn);
  };

  const handleReject = (error={}) => {
    handleBackStep();
    handleResponse(error);
  };

  const handleResolve = (result) => {
    handlePolling(result);
  };

  const handlePolling = async (result) => {
    throttleOn = false;
    poller.current = await poll({
      promiseFn: getAuthenticationStatus,
      ...result.data,
      onResponse: {
        notAuthenticated: handleNoAuth,
        success: handlePollSuccess,
      },
    });
  };

  const handleToggleCustomContext = () => {
    setCustomContext(!customContext);
    setForm({ ...form, message: customContext ? defaultMessage : '' });
  };

  const BackButton = () => {
    return (
      <Button
        className={classes.button}
        color='default'
        onClick={handleBackStep}
        variant='outlined'
      >
        <Text id={`${rootIntl}.actions.prev`} />
      </Button>
    );
  };

  const CancelButton = () => {
    return (
      <Button
        className={classes.button}
        color='default'
        onClick={handleCancel}
        variant='outlined'
      >
        <Text id={`${rootIntl}.actions.cancel`} />
      </Button>
    );
  };

  const DoneButton = () => {
    return (
      <Button
        className={classes.button}
        color='primary'
        onClick={handleDone}
        variant='contained'
      >
        <Text id={`${rootIntl}.actions.done`} />
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <Button
        className={classes.button}
        color='primary'
        onClick={handleNextStep}
        variant='outlined'
      >
        <Text id={`${rootIntl}.actions.next`} />
      </Button>
    );
  };

  const contextStep = (
    <Grid alignItems='center' container>
      <Grid item xs={9}>
        <TextField
          fullWidth
          InputLabelProps={{ className: classes.input }}
          label={<Text id={`${rootIntl}.steps.1.fields.0.label`} />}
          margin='dense'
          name='message'
          onChange={handleChange}
          placeholder={formatMessage({
            id: `${rootIntl}.steps.1.fields.0.placeholder`,
          })}
          required
          select={!customContext}
          variant='outlined'
          value={form.message}
        >
          {!customContext ? [
            defaultMessage,
            formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentOptions.1` }),
            formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentOptions.2` }),
            formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentOptions.3` }),

          ].map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>) : null}
        </TextField>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title={customContext
          ? formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentTooltip.0` })
          : formatMessage({ id: `${rootIntl}.steps.2.preOpContextContentTooltip.1` })}
        >
          <Button
            className={classes.customBtn}
            color='primary'
            fullWidth
            onClick={handleToggleCustomContext}
            size='large'
            variant='contained'
          >
            <FontAwesomeIcon icon={['fal', customContext ? 'list' : 'i-cursor']} />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={2} />
      <Grid item sm='auto' xs={4}>
        <BackButton />
      </Grid>
      <Grid item sm='auto' xs={4}>
        <NextButton />
      </Grid>
    </Grid>
  );

  return (
    <Stepper activeStep={activeStep} orientation='vertical'>

      {/* Welcome step */}
      <Step>
        <StepLabel>
          <Text id={`${rootIntl}.steps.0.name`} />
        </StepLabel>
        <StepContent>
          <NextButton />
        </StepContent>
      </Step>

      {/* Context step */}
      <Step>
        <StepLabel>
          <Text id={`${rootIntl}.steps.1.name`} />
        </StepLabel>
        <StepContent>
          {contextStep}
        </StepContent>
      </Step>

      {/* Awaiting auth step */}
      <Step>
        <StepLabel>
          <Text id={`${rootIntl}.steps.2.name`} />
        </StepLabel>
        <StepContent TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
          <Grid alignItems='center' container spacing={4}>
            <Grid item xs={12}>
              <Typography gutterBottom variant='subtitle1'>
                <Text id={`${rootIntl}.steps.2.title`} values={{ name }} />
              </Typography>
              <Typography gutterBottom variant='body2'>
                <Text id={`${rootIntl}.steps.2.desc`} />
              </Typography>
            </Grid>
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography>
                <Async
                  onReject={handleReject}
                  onResolve={handleResolve}
                  postOperationContext={{
                    title: formatMessage({id: `${rootIntl}.steps.2.postOpContextTitle` }),
                    content: `${formatMessage({id: `${rootIntl}.steps.2.postOpContextContent` })} "${form.message}"`,
                  }}
                  preOperationContext={{
                    title: formatMessage({id: `${rootIntl}.steps.2.preOpContextTitle` }),
                    content: form.message,
                  }}
                  promiseFn={postAuthentication}
                  username={username}
                >
                  <Async.Initial persist>
                    <Text id={`${rootIntl}.steps.2.sending`} />
                  </Async.Initial>
                  <Async.Fulfilled>
                    <Text id={`${rootIntl}.steps.2.awaiting`} />
                  </Async.Fulfilled>
                </Async>
              </Typography>
            </Grid>
          </Grid>
          <CancelButton />
        </StepContent>
      </Step>

      {/* Auth completion step */}
      <Step>
        <StepLabel>
          <Text id={`${rootIntl}.steps.3.name`} />
        </StepLabel>
        <StepContent>
          <Typography>
            <Text id={`${rootIntl}.steps.3.title`} />
          </Typography>
          <NextButton />
        </StepContent>
      </Step>

      {/* Service Provider step */}
      <Step>
        <StepLabel>
          <Text id={`${rootIntl}.steps.4.name`} />
        </StepLabel>
        <StepContent>
          <List dense style={{ marginBottom: 8 }}>
            {[...Array(5)].map((_, i) => (
              <Fragment key={i}>
                <ListItem button>
                  <ListItemText>
                    <Text id={`${rootIntl}.steps.4.options.${i}`} />
                  </ListItemText>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
          <DoneButton />
        </StepContent>
      </Step>
    </Stepper>
  );
};