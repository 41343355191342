import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) => {
  return {
    heading: {
      marginBottom: spacing(3),
    },
  };
});

export default function Heading({ title }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography
        className={classes.heading}
        gutterBottom
        variant='h5'
      >
        {title}
      </Typography>
    </Grid>
  );
};