import React from 'react';

import { Dialog as Dlog, Slide, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';
import DialogMessage from './DialogMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function Dialog({
  accept,
  children,
  decline,
  open=false,
  onOpen,
  title,
  ...rest
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dlog
      fullScreen={isMobile}
      fullWidth
      maxWidth='md'
      open={open}
      TransitionComponent={Transition}
      {...rest}
    >
      <DialogHeader title={title} />
      <DialogMessage message={children} />
      <DialogFooter
        accept={accept}
        decline={decline}
        setOpen={onOpen}
      />
    </Dlog>
  );
}

export default Dialog;