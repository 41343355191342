import React from 'react';
import {Link, Redirect, Route} from 'react-router-dom';
import Async from "react-async";
import {Button, CircularProgress, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles(() => {
    return {
        container: {
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
        },
        errorMsg: {
            marginBottom: 16,
        },
    };
});

export function PublicRoute({ portalLoggedIn, location, render, ...rest}) {
    const classes = useStyles();

    return (
        <Async promiseFn={portalLoggedIn}>
        <Async.Pending>
            <div className={classes.container}>
                <CircularProgress color='primary' size={64} thickness={4}/>
            </div>
        </Async.Pending>
        <Async.Rejected>
            {(error) => (
                <div className={classes.container}>
                    <div>
                        <Typography variant='h1'>Uh oh.</Typography>
                        <Typography className={classes.errorMsg} display='block'>
                            Something went terribly wrong. Please sign in again.
                        </Typography>
                        <Button
                            color='primary'
                            component={Link}
                            to='/login'
                            variant='contained'
                        >
                            Sign in
                        </Button>
                    </div>
                </div>
            )}
        </Async.Rejected>
        <Async.Resolved>
            {(data) => (
                <Route
                    {...rest}
                    render={(props) => {
                        if (!!data) {
                            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                        }
                        return render(props);
                    }}
                />
            )}
        </Async.Resolved>
    </Async>
    )
}

export default PublicRoute;