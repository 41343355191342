import React from 'react';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AsyncContent from '../AsyncContent';

const useStyles = makeStyles(({ breakpoints, spacing }) => {
  return {
    centered: {
      margin: '0 auto',
      justifyContent: 'center',
    },
    container: {
      height: '100%',
      maxWidth: ({ maxWidth }) => maxWidth ? maxWidth : null,
      padding: spacing(0, 4),
      width: '100%',
      [breakpoints.down('sm')]: {
        padding: spacing(2),
      },
    },
  };
});

export function Container({
  AsyncProps, centered, children, className, maxWidth, ...rest
}) {
  const classes = useStyles({ maxWidth });

  const content = AsyncProps
    ? <AsyncContent {...AsyncProps}>{children}</AsyncContent>
    : children;

  return (
    <Grid
      className={clsx(
        classes.container,
        { [classes.centered]: centered },
        className
      )}
      container
      {...rest}
    >
      {content}
    </Grid>
  );
};

export default Container;