import React, { useState } from 'react';
import Async from 'react-async';
import { Helmet } from 'react-helmet';
import { FormattedMessage as Text, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CircularProgress, Grid, InputAdornment, Link, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getUsers } from 'api/users';
import { AsyncRecord } from 'components';

const useStyles = makeStyles(({ spacing }) => {
  return {
    container: {
      padding: spacing(3),
    },
    filter: {
      marginBottom: spacing(3),
    },
  };
});

export default function People() {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const { formatMessage } = useIntl();
  const rootIntl = 'support.people';

  const filterData = (data) => {
    const regex = new RegExp(filter, 'i');
    return data.filter(x => regex.test(JSON.stringify(x)));
  };

  const modifyData = (data) => data
    .sort((a, b) => {
      const conditionA = a.first_name && a.first_name.toUpperCase();
      const conditionB = b.first_name && b.first_name.toUpperCase();
      return conditionA < conditionB ? -1 : 1
    })
    .map(({ first_name, last_name, username, ...other }) => {
      return {
        ...other,
        name: (
          <Link component={RouterLink} to={`/bank/call-center/people/${username}`}>
            {`${first_name} ${last_name}`}
          </Link>
        ),
        username,
      };
    }
  );

  const onFilter = (event) => {
    const { value } = event.target;
    setFilter(value);
  };

  return (
    <>
      <Helmet title='Users' />
      <Grid className={classes.container} container justify='flex-start'>
        <Grid item md={5} lg={4} sm={6} xl={3} xs={12}>
          <TextField
            autoFocus
            className={classes.filter}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FontAwesomeIcon size='sm' icon={['fal', 'filter']} />
                </InputAdornment>
              ),
            }}
            label={<Text id={`${rootIntl}.filter.label`} />}
            margin='dense'
            onChange={onFilter}
            placeholder={formatMessage({ id: `${rootIntl}.filter.placeholder`})}
            type='search'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <Async promiseFn={getUsers}>
            <Async.Pending>
              <CircularProgress />
            </Async.Pending>
            <Async.Rejected>
              {(error) => <Typography>{error.message}</Typography>}
            </Async.Rejected>
            <Async.Fulfilled>
              {(data) => {
                return (
                  <AsyncRecord
                    data={modifyData(filterData(data))}
                    mapDataToHeaders={{
                      name: <Text id={`${rootIntl}.headers.0`} />,
                      username: <Text id={`${rootIntl}.headers.1`} />,
                      phone: <Text id={`${rootIntl}.headers.2`} />,
                      street_address: <Text id={`${rootIntl}.headers.3`} />,
                      city: <Text id={`${rootIntl}.headers.4`} />,
                      state: <Text id={`${rootIntl}.headers.5`} />,
                      postalCode: <Text id={`${rootIntl}.headers.6`} />,
                    }}
                  />
                )
              }}
            </Async.Fulfilled>
          </Async>
        </Grid>
      </Grid>
    </>
  );
};
