import React, { useState } from 'react';
import { FormattedMessage as Text } from 'react-intl';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppBar, Button, Grid, Hidden, Link, Tab, Tabs, Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {deleteAuthSession} from 'components';


const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    appBar: {
      '& *': {
        color: palette.getContrastText(palette.background.paper),
      },
      backgroundColor: palette.background.paper,
      position: 'sticky',
      top: 48,
    },
    icon: {
      marginRight: spacing(1),
    },
    link: {
      '&:not(:last-child)': {
        marginRight: spacing(4),
      },
      cursor: 'pointer',
    },
    tab: {
      fontWeight: 400,
      textTransform: 'none',
    },
    container: {
      margin: '0 auto',
      maxWidth: ({ width }) => width || 'none',
      padding: ({ padding }) => spacing(0, padding || 0),
      width: '100%',
    },
    tabWrapper: {
      width: 120,
    },
    tabsWrapper: {
      flex: 1,
    },
    toolbar: {
      padding: 0,
    },
  };
});

export function SubBar({ isPrivate, padding, width }) {
  const classes = useStyles({ padding, width });
  const [tab] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const rootIntl = 'navigation.subbar';

  const handleLogout = (event) => {
    event.preventDefault();
    deleteAuthSession();
  };

  const publicTabs = [
    { label: `${rootIntl}.public.primary.0` },
    { label: `${rootIntl}.public.primary.1` },
    { label: `${rootIntl}.public.primary.2` },
  ];

  const privateTabs = [
    { label: `${rootIntl}.private.primary.0` },
    { label: `${rootIntl}.private.primary.1` },
    { label: `${rootIntl}.private.primary.2` },
  ];

  const publicLinks = [
    { children: `${rootIntl}.public.secondary.0`, to: '#' },
  ];

  const privateLinks = [
    { children: `${rootIntl}.private.secondary.0`, onClick: handleLogout, to: '#' },
    { children: `${rootIntl}.private.secondary.1`, to: '#' },
  ];

  const renderedTabs = (isPrivate
      ? privateTabs
      : publicTabs).map(({ label, ...other }, i) => (
    <Tab 
      classes={{ wrapper: classes.tabWrapper}}
      className={classes.tab}
      key={label}
      label={<Text id={label} />}
      value={i}
      {...other}
    />
  ));

  const renderedLinks = (isPrivate
      ? privateLinks
      : publicLinks).map(({ children, ...other }, i) => (
    <Link
      className={classes.link}
      component={RouterLink}
      key={children}
      {...other}
    >
      <Text id={children} />
    </Link>
  ));

  const isAction = location.pathname === '/bank/enroll'
    || location.pathname === '/bank/send';

  const left = !isAction
    ? (
      <Tabs indicatorColor='primary' value={tab}>
        {renderedTabs}
      </Tabs>
    ) : (
      <Button onClick={history.goBack}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={['fal', 'arrow-left']}
        />
        Go back
      </Button>
    );

  const right = !isAction
    ? renderedLinks
    : null;

  return (
    <AppBar className={classes.appBar} elevation={0} position='static'>
      <Toolbar className={classes.toolbar} variant='dense'>
        <Grid alignItems='center' className={classes.container} container>
          <Grid className={classes.tabsWrapper} item>
            {left}
          </Grid>
          <Hidden smDown>
            <Grid item>
              {right}
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SubBar;